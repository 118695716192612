import React from 'react';
import { makeUseStyles } from './common';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeUseStyles(() => (
    {
      root: {
        top: "50%", 
        left: "50%",
        msTransform: "translate(-50%, -50%)",
        transform: "translate(-50%, -50%)",
        position: "fixed",
      },
    }
  )
)


export default function CenteredCircularProgress() {
  const classes = useStyles()

  return (
    <div className={classes.root}><CircularProgress color="inherit"/></div>
  )
}
