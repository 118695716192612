import 'react-phone-number-input/style.css';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { AppConfig } from '../store/types';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../store';


const useAboutStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 30,
    paddingBottom: 30,
    maxWidth: 600, 
    margin: "0 auto"
  },
  par: {
    marginTop: 10,
    wordWrap: "break-word",
    overflowWrap: "break-word",
    WebkitHyphens: "manual",
    mozHyphens: "manual",
    hyphens: "manual",
    paddingLeft: 20, 
    paddingRight: 20
  }
}))


export const About = () => {
  const classes = useAboutStyles()

  return (
    <div className={classes.root}>
      <Typography component="p" align="justify" className={classes.par}>
        Kas mes? Mes e&shy;sa&shy;me pro&shy;fe&shy;sio&shy;na&shy;lų ko&shy;man&shy;da, de&shy;dan&shy;ti vi&shy;sas pas&shy;tan&shy;gas, kad klien&shy;tai ga&shy;lė&shy;tų
        mė&shy;gau&shy;tis ko&shy;ky&shy;biš&shy;ku, es&shy;te&shy;tiš&shy;ku, iš&shy;skir&shy;ti&shy;niu mais&shy;tu ir lik&shy;tų nu&shy;ste&shy;bin&shy;ti ne tik iš&shy;vaiz&shy;da, 
        bet ir ki&shy;to&shy;kiais, ne&shy;į&shy;pras&shy;tais, bet to&shy;bu&shy;lai su&shy;de&shy;rin&shy;tais sko&shy;niais.
      </Typography>
      <Typography component="p" align="justify" className={classes.par}>
        Ko&shy;kie mes? Mes &ndash; ki&shy;to&shy;kie. Mes ti&shy;ki&shy;me, kad gur&shy;ma&shy;ni&shy;šku mais&shy;tu ga&shy;li mė&shy;gau&shy;tis ne tik
        res&shy;to&shy;ra&shy;ne. Mes sie&shy;kia&shy;me pa&shy;ro&shy;dy&shy;ti, kad vie&shy;no kąs&shy;nio už&shy;kan&shy;dis taip pat ga&shy;li bū&shy;ti 
        aukš&shy;tos kla&shy;sės pa&shy;tie&shy;ka&shy;las, sa&shy;vy&shy;je tal&shy;pi&shy;nan&shy;tis es&shy;te&shy;ti&shy;kos, ko&shy;ky&shy;bės ir 
        gur&shy;ma&shy;niš&shy;kų sko&shy;nių de&shy;ri&shy;nį.
      </Typography>
      <Typography component="p" align="justify" className={classes.par}>
        Ko&shy;dėl mes? Nes mes o&shy;ri&shy;gi&shy;na&shy;lūs, lanks&shy;tūs ir ki&shy;to&shy;kie. Nes mes mėgs&shy;ta&shy;me nu&shy;ste&shy;bin&shy;ti 
        ne&shy;ti&shy;kė&shy;tais sko&shy;nių de&shy;ri&shy;niais ir pa&shy;trauk&shy;lia iš&shy;vaiz&shy;da. Nes mes pri&shy;pa&shy;žįs&shy;ta&shy;me 
        tik aukš&shy;tos ko&shy;ky&shy;bės pro&shy;duk&shy;ci&shy;ją. Nes mes mėgs&shy;ta&shy;me ga&shy;min&shy;ti ir sie&shy;kia&shy;me, kad Jums 
        ne&shy;rei&shy;kė&shy;tų gal&shy;vo&shy;ti a&shy;pie lai&shy;ką, pra&shy;leis&shy;tą vir&shy;tu&shy;vė&shy;je.
      </Typography>
      <Typography component="p" align="justify" className={classes.par}>
        Ka&shy;da mes? Vi&shy;sa&shy;da! Mes ga&shy;li&shy;me pa&shy;dė&shy;ti su&shy;ruoš&shy;ti as&shy;me&shy;ni&shy;nes šven&shy;tes tiek pa&shy;čių 
        ar&shy;ti&shy;miau&shy;sių&shy;jų ra&shy;tui, tiek pla&shy;čiai gi&shy;mi&shy;nei. Mes ga&shy;li&shy;me su&shy;reng&shy;ti ka&shy;vos per&shy;trau&shy;ką 
        ar fur&shy;še&shy;tą ren&shy;gi&shy;nio ar kon&shy;fe&shy;ren&shy;ci&shy;jos me&shy;tu. Vyks dar&shy;bo su&shy;si&shy;ti&shy;ki&shy;mas, de&shy;gus&shy;ta&shy;ci&shy;ja 
        ar tie&shy;siog per&shy;trau&shy;kė&shy;lė? Ži&shy;no&shy;ma! Tu&shy;ri&shy;me pa&shy;siū&shy;ly&shy;mų ir jums. O gal tie&shy;siog no&shy;ri&shy;si to&shy;bu&shy;los 
        res&shy;to&shy;ra&shy;no va&shy;ka&shy;rie&shy;nės, pa&shy;baig&shy;tos ruoš&shy;ti na&shy;muo&shy;se? Tik&shy;rai taip!
      </Typography>
    </div>
  )
}

const useContactsStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 5
  },
  root: {
    marginTop: 30
  },
  block: {
    paddingLeft: 20, 
    paddingRight: 20,
    marginBottom: 30
  },
}))

export const Contacts = () => {
  const classes = useContactsStyles()
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)

  return (
    <div className={classes.root}>
      {/* <Typography variant="h6" align="center" style={{color: "red", fontWeight: "bold", marginBottom: 40}}>
        Balandžio 10&ndash;25 dienomis Canapés.lt komanda atostogauja. Susisiekti galite tik el. paštu gamyba@canapes.lt
      </Typography> */}
      <Grid container spacing={3} direction="column" alignItems="center">
        <Grid item>
          <div className={classes.block}>
            {/* <Typography>
              {config.production.address}, {config.production.city}
            </Typography> */}
            <Typography>
              Tel. užsakymams: {config.production.tel}
            </Typography>
            <Typography>
              El. paštas: {config.production.email}
            </Typography>
          </div>
          <div className={classes.block}>
            <Typography className={classes.heading}>
              Rekvizitai
            </Typography>
            <Typography>
              {config.seller.name}
            </Typography>
            <Typography>
              Įm. kodas: {config.seller.code}
            </Typography>
            <Typography>
              PVM mok. kodas: {config.seller.vat_code}
            </Typography>
            <Typography>
              {config.seller.address}, {config.seller.zip}, {config.seller.city}
            </Typography>
            <Typography>
              {config.seller.bank} bankas
            </Typography>
            <Typography>
              {config.seller.account}
            </Typography>
            <Typography>
              El. paštas: {config.seller.email}
            </Typography>
            <Typography>
              Tel.: {config.seller.tel}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
