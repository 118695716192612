import { 
  UserAction, 
  SET_USER_PROFILE,
  SET_SOC_APP_CONFIG,
  SET_REMEMBER_ME,
  SET_SIGNING_IN,
  SET_APP_CONFIG,
  ADD_ORDER,
  RM_ORDER,
  ADD_CART_DATA,
  CLEAN_CART
} from './user-actions'
import { UserState, Order } from './types'


const initialUserState: UserState = {
  cart: {
    orders: [],
    data: {}
  },
  socapps: [],
  remember_me: false,
  signingIn: false
}


export function userReducer(
  state = initialUserState,
  action: UserAction
): UserState {
  switch (action.type) {
    case ADD_CART_DATA: {
      return {
        ...state,
        cart: {
          ...state.cart,
          data: action.replace ? {...action.payload} : {...state.cart.data, ...action.payload},
        }
      }
    }
    case CLEAN_CART: {
      localStorage.removeItem("cart")
      return {
        ...state,
        cart: {
          ...state.cart,
          orders: [],
          data: {
            firstname: state.profile?.first_name,
            lastname: state.profile?.last_name,
            email: state.profile?.email
          }
        }
      }
    }
    case ADD_ORDER: {
      let pushed = false
      let newOrders:Order[] = []
      state.cart.orders.forEach(o => {
        let no = {...o}
        if (o.item.id === action.payload.item.id && o.choice === action.payload.choice) {
          no.quantity += action.payload.quantity
          pushed = true
        }
        newOrders.push(no)
      })
      if (!pushed) newOrders.push(action.payload)
      let newCart = {...state.cart, orders: newOrders, timestamp: new Date().getTime()}
      if (action.store)
        localStorage.setItem("cart", JSON.stringify(newCart))
      return {
        ...state,
        cart: newCart
      }
    }
    case RM_ORDER: {
      let newCart = {...state.cart, orders: state.cart.orders.filter((_, idx) => idx !== action.index)}
      localStorage.setItem("cart", JSON.stringify(newCart))
      return {
        ...state,
        cart: {...state.cart, orders: state.cart.orders.filter((_, idx) => idx !== action.index)}
      }
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        profile: action.payload
      }
    }
    case SET_SOC_APP_CONFIG: {
      return {
        ...state,
        socapps: action.payload
      }
    }
    case SET_REMEMBER_ME: {
      return {
        ...state,
        remember_me: action.payload
      }
    }
    case SET_SIGNING_IN: {
      return {
        ...state,
        signingIn: action.payload
      }
    }
    case SET_APP_CONFIG: {
      return {
        ...state,
        config: {...state.config, ...action.payload}
      }
    }
    default:
      return state
  }
}
