import React, { useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import {v4 as uuidv4} from 'uuid';
import {Buffer} from 'buffer';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGetAuthState, makeUseStyles } from './common';
import { Redirect } from 'react-router-dom';
import { CLEAN_ORDERS, useManagementActions } from '../store/management-actions';
import { useSelector, shallowEqual } from 'react-redux';
import { IUser, ISocialApp, voidFn, OrderData, AppConfig, ITimedConfig, IDiscountCode } from '../store/types';
import { AppState } from '../store';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import { ResponseError, addError } from '../store/common';
import { EditDialog, ConfirmDialog, UserControlForm } from './dialogs';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Collapse from '@material-ui/core/Collapse';
import LoopIcon from '@material-ui/icons/Loop';
import { InView } from 'react-intersection-observer';
import { useLoginActions } from '../store/user-actions';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { OrderDialog } from './order';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PaymentIcon from '@material-ui/icons/Payment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useListStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: '100%',
      // backgroundColor: theme.palette.background.paper,
    },
    list: {
      maxWidth: 300,
      margin: "0 auto"
    },
    edit: {
      color: 'green',
      fontSize: 20
    },
    delete: {
      color: 'red',
      fontSize: 20
    },
    payment: {
      color: 'orange',
      fontSize: 20
    },
    cancelPayment: {
      color: 'red',
      fontSize: 20
    },
    formContainer: {
      margin: theme.spacing(4),
      maxWidth: 500,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    subhead: {
      position: "relative",
      fontWeight: "bold",
      color: "#000000",
      width: "100%"
    },
    link: {
      padding: 5,
      cursor: "pointer"
    },
    rotation: {
      animation: "$rotation 2s infinite linear"
    },
    "@keyframes rotation": {
      "from": {
        transform: "rotate(0deg)"
      },
      "to": {
        transform: "rotate(359deg)"
      }
    }
  })
)

const AppConfigView = () => {
  const classes = useListStyles()
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const timedConfig = useSelector<AppState, ITimedConfig[] | undefined>(state => state.management.timedconf, shallowEqual)
  const [active, setActive] = React.useState(false)
  const [confirm, setConfirm] = React.useState<string>()
  const [selectedDate, handleDateChange] = React.useState(config.available_from)
  const [minPeriod, setMinPeriod] = React.useState(config.min_produce_period)
  const [minPrice, setMinPrice] = React.useState(config.min_order_price)
  const [dayStart, setDayStart] = React.useState(config.day_start)
  const [dayEnd, setDayEnd] = React.useState(config.day_end)
  const [interval, setHourInterval] = React.useState(config.interval)
  const [ordersPM, setOrdersPM] = React.useState(config.orders_per_moment)
  const [open, setOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [title, setTitle] = React.useState("")
  const [name, setName] = React.useState("")
  const [activeId, setActiveId] = React.useState<number>()
  const [dateFrom, setDateFrom] = React.useState<Date>(null)
  const [dateTo, setDateTo] = React.useState<Date>(null)
  const [confData, setConfData] = React.useState<string>()
  const [parseError, setParseError] = React.useState<string>()
  const { dispatch, thunkUpdateConfig } = useLoginActions()
  const { thunkGetTimedConfig, thunkUpdateTimedConfig, thunkRemoveTimedConfig } = useManagementActions()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.configError, shallowEqual)

  const cleanError = () => {
    setConfirm(undefined)
    dispatch(addError("configError", undefined))
  }

  useEffect(() => {
    if (typeof timedConfig === "undefined") thunkGetTimedConfig()
    return cleanError
  // eslint-disable-next-line
  }, [])

  const deactivate = () => {
    setActive(false)
  }

  const handleMinPeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setMinPeriod(num)
  }

  const handleDayStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setDayStart(num)
  }

  const handleDayEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setDayEnd(num)
  }

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setHourInterval(num)
  }

  const handleOrdersPMChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setOrdersPM(num)
  }

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setMinPrice(num)
  }

  const onClick = () => {
    cleanError()
    setActive(true)
    thunkUpdateConfig({
      available_from: selectedDate,
      min_produce_period: minPeriod,
      min_order_price: minPrice,
      day_start: dayStart,
      day_end: dayEnd,
      interval,
      orders_per_moment: ordersPM
    }, () => {
      setConfirm("Informacija sėkmingai atnaujinta")
      deactivate()
    }, deactivate)
  }

  const openEditDialog = (title: string, data?: ITimedConfig) => {
    cleanError()
    setParseError(undefined)
    setTitle(title)
    setActiveId(data?.id)
    setName(data?.name || "")
    setDateFrom(data?.date_from || null)
    setDateTo(data?.date_to || null)
    setConfData(data?.config ? JSON.stringify(data.config) : "{}")
    setOpen(true)
  }

  const openDeleteDialog = (id: number, name: string) => {
    setActiveId(id)
    setName(name)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    cleanError()
    setDeleteOpen(false)
    setActiveId(undefined)
  }

  const handleClose = () => {
    cleanError()
    setOpen(false)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setName(event.target.value)
  }

  const handleConfDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setConfData(event.target.value);
    try {
      JSON.parse(event.target.value);
      setParseError(undefined)
    } catch (e) {
      setParseError((e as ResponseError).message)
    }
  }

  const handleDateFromChange = (value: Date) => {
    cleanError()
    setDateFrom(value)
  }

  const handleDateToChange = (value: Date) => {
    cleanError()
    setDateTo(value)
  }

  const deleteConf = (cb: voidFn) => {
    if (activeId) {
      thunkRemoveTimedConfig(activeId, () => {
        cb()
        handleDeleteClose()
      }, cb)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    thunkUpdateTimedConfig({
      name,
      date_from: dateFrom,
      date_to: dateTo,
      config: JSON.parse(confData),
    }, activeId, (_) => {
      setOpen(false)
    })
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {Boolean(error) ?
        <Alert severity="error">
          {error.response?.detail}
        </Alert>
      : null}
      {Boolean(confirm) ?
        <Alert severity="success">
          {confirm}
        </Alert>
      : null}
      <div style={{maxWidth: 250}}>
        <KeyboardDateTimePicker
          cancelLabel="Atšaukti"
          value={selectedDate}
          ampm={false}
          minutesStep={5}
          invalidDateMessage="Neteisingas datos formatas"
          onChange={handleDateChange}
          format="dd/MM/yyyy HH:mm"
          label="Artimiausias darbo laikas"
        />
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.day_start)}>
          <InputLabel htmlFor="day-start">Pristatymo valandos nuo (minutėmis nuo 00:00)</InputLabel>
          <Input
            required
            onChange={handleDayStartChange}
            name="day-start"
            type="number"
            id="day-start"
            value={dayStart}
            inputProps={{
              min: "0"
            }}
          />
          <FormHelperText>{error?.response?.day_start?.join(' ')}</FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.day_end)}>
          <InputLabel htmlFor="day-end">Pristatymo valandos iki (minutėmis nuo 00:00)</InputLabel>
          <Input
            required
            onChange={handleDayEndChange}
            name="day-end"
            type="number"
            id="day-end"
            value={dayEnd}
            inputProps={{
              min: "0"
            }}
          />
          <FormHelperText>{error?.response?.day_end?.join(' ')}</FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.interval)}>
          <InputLabel htmlFor="config-interval">Intervalas (minutėmis)</InputLabel>
          <Input
            required
            onChange={handleIntervalChange}
            name="interval"
            type="number"
            id="config-interval"
            value={interval}
            inputProps={{
              min: "1"
            }}
          />
          <FormHelperText>{error?.response?.interval?.join(' ')}</FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.orders_per_moment)}>
          <InputLabel htmlFor="orders-per-moment">Užsakymų kiekis laikui</InputLabel>
          <Input
            required
            onChange={handleOrdersPMChange}
            name="orders-per-moment"
            type="number"
            id="orders-per-moment"
            value={ordersPM}
            inputProps={{
              min: "0"
            }}
          />
          <FormHelperText>{error?.response?.orders_per_moment?.join(' ')}</FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.min_produce_period)}>
          <InputLabel htmlFor="min-produce-period">Minimalus paruošimo periodas (minutės)</InputLabel>
          <Input
            required
            onChange={handleMinPeriodChange}
            name="minimal-produce-period"
            type="number"
            id="min-produce-period"
            value={minPeriod}
            inputProps={{
              min: "1"
            }}
          />
          <FormHelperText>{error?.response?.min_produce_period?.join(' ')}</FormHelperText>
        </FormControl>
        <FormControl fullWidth margin="normal" error={Boolean(error?.response?.min_order_price)}>
          <InputLabel htmlFor="min-order-price">Minimali užsakymo suma (centais)</InputLabel>
          <Input
            required
            onChange={handleMinPriceChange}
            name="min-order-price"
            type="number"
            id="min-order-price"
            value={minPrice}
            inputProps={{
              min: "1"
            }}
          />
          <FormHelperText>{error?.response?.min_order_price?.join(' ')}</FormHelperText>
        </FormControl>
      </div>
      <Button
        style={{
          marginTop: 10,
          marginBottom: 10
        }}
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={Boolean(active)}
      >
        Išsaugoti
      </Button>
    
      <List 
        component="div"
        aria-labelledby="timed-config-list-subheader"
        subheader={
          <ListSubheader component="div" className={classes.subhead} id="timed-config-list-subheader">
            Laikina konfigūracija
            <IconButton edge="end" aria-label="add" className={classes.edit} onClick={() => {openEditDialog('Naujas konfigūracinis vienetas')}}>
              <AddCircleOutlineIcon fontSize="inherit"/>
            </IconButton> 
          </ListSubheader>
        }
      >
        {timedConfig?.map((value: ITimedConfig, idx: number) => {
          return (
            <ListItem key={idx}>
              <ListItemText primary={value.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" className={classes.edit} onClick={() => {openEditDialog('Taisyti konfigūracinį vienetą', {...value})}}>
                  <EditIcon fontSize="inherit"/>
                </IconButton>
                <IconButton edge="end" aria-label="delete" className={classes.delete} onClick={() => {openDeleteDialog(value.id, value.name)}}>
                  <ClearIcon fontSize="inherit"/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>

      <EditDialog 
        title={title} 
        open={open}
        containerClass={classes.formContainer}
        handleClose={handleClose}
        error={error}
      >
        <form className={classes.form} id='timed-config-edit-form' onSubmit={onSubmit} noValidate>  
          <FormControl fullWidth margin='normal'>
            <FormGroup>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleNameChange}
                id="timed-config-name"
                label="Pavadinimas"
                name="name"
                value={name}
                autoFocus
                error={Boolean(error?.response?.name)}
                helperText={error?.response?.name?.join(' ')}
              />
              <FormControl margin='normal' error={Boolean(error?.response?.date_from)}>
                <KeyboardDateTimePicker
                  cancelLabel="Atšaukti"
                  clearable
                  value={dateFrom}
                  ampm={false}
                  minutesStep={5}
                  invalidDateMessage="Neteisingas datos formatas"
                  onChange={handleDateFromChange}
                  format="dd/MM/yyyy HH:mm"
                  label="Laikas nuo"
                />
                <FormHelperText style={{marginLeft: 10}}>{error?.response?.date_from?.join(' ')}</FormHelperText>
              </FormControl>
              <FormControl margin='normal' error={Boolean(error?.response?.date_to)}>
                <KeyboardDateTimePicker
                  cancelLabel="Atšaukti"
                  clearable
                  value={dateTo}
                  ampm={false}
                  minutesStep={5}
                  invalidDateMessage="Neteisingas datos formatas"
                  onChange={handleDateToChange}
                  format="dd/MM/yyyy HH:mm"
                  label="Laikas iki"
                />
                <FormHelperText style={{marginLeft: 10}}>{error?.response?.date_to?.join(' ')}</FormHelperText>
              </FormControl>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline={true}
                rows={4}
                onChange={handleConfDataChange}
                id="timed-config-data"
                label="Konfigūracija (JSON)"
                name="timed-config-data"
                value={confData}
                error={Boolean(parseError) || Boolean(error?.response?.config)}
                helperText={parseError || error?.response?.config?.join(' ')}
              />
            </FormGroup>
          </FormControl>
          <Button
            type="submit"
            form='timed-config-edit-form'
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={Boolean(parseError) || Boolean(error?.response)}
          >
            Save
          </Button>
        </form>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleDeleteClose}
        onConfirm={deleteConf}
        error={error}
      >
        Konfigūracija <strong>{name}</strong> bus ištrinta. Įvesti <strong>delete me</strong> ir patvirtinti
      </ConfirmDialog>
    
    </div>
  )
}


const SocAppSettings = () => {
  const classes = useListStyles()
  const socapps = useSelector<AppState, ISocialApp[] | undefined>(state => state.management.socapps, shallowEqual)
  const [open, setOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [title, setTitle] = React.useState("")
  const [activeId, setActiveId] = React.useState<number>()
  const [provider, setProvider] = React.useState<string>("")
  const [clientId, setClientId] = React.useState<string>("")
  const [key, setKey] = React.useState<string>("")
  const [secretKey, setSecretKey] = React.useState<string>("")
  const { dispatch, thunkGetSocialApps, thunkUpdateSocialApp, thunkRemoveSocialApp } = useManagementActions()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.socappError, shallowEqual)

  const cleanError = () => {
    dispatch(addError("socappError", undefined))
  }

  useEffect(() => {
    if (typeof socapps === "undefined") thunkGetSocialApps()
    return cleanError
  // eslint-disable-next-line
  }, [])

  const openEditDialog = (title: string, data?: ISocialApp) => {
    setTitle(title)
    setActiveId(data?.id)
    setProvider(data?.provider || "")
    setClientId(data?.client_id || "")
    setKey(data?.key || "")
    setSecretKey(data?.secret_key || "")
    setOpen(true)
  }

  const openDeleteDialog = (id: number, provider: string) => {
    setActiveId(id)
    setProvider(provider)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    cleanError()
    setDeleteOpen(false)
    setActiveId(undefined)
  }

  const deleteSettings = (cb: voidFn) => {
    if (activeId) {
      thunkRemoveSocialApp(activeId, () => {
        cb()
        handleDeleteClose()
      }, cb)
    }
  }

  const handleClose = () => {
    cleanError()
    setOpen(false)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    thunkUpdateSocialApp({
      provider: provider,
      client_id: clientId,
      key: key,
      secret_key: secretKey,
    }, activeId, (_) => {
      setOpen(false)
    })
  }

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setProvider(event.target.value)
  }

  const handleClientIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setClientId(event.target.value)
  }

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setKey(event.target.value)
  }

  const handleSecretKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setSecretKey(event.target.value)
  }

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(error?.response?.detail)}>
        <Alert severity={"error"}>
          {error?.response?.detail}
        </Alert>
      </Collapse>
      <List 
        component="div"
        aria-labelledby="socapp-list-subheader"
        subheader={
          <ListSubheader component="div" className={classes.subhead} id="socapp-list-subheader">
            Social Applications
            <IconButton edge="end" aria-label="add" className={classes.edit} onClick={() => {openEditDialog('New Social Application')}}>
              <AddCircleOutlineIcon fontSize="inherit"/>
            </IconButton> 
          </ListSubheader>
        }
      >
        {socapps?.map((value: ISocialApp, idx: number) => {
          return (
            <ListItem key={idx}>
              <ListItemText primary={value.provider} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="edit" className={classes.edit} onClick={() => {openEditDialog('Edit Social Application', {...value})}}>
                  <EditIcon fontSize="inherit"/>
                </IconButton>
                <IconButton edge="end" aria-label="delete" className={classes.delete} onClick={() => {openDeleteDialog(value.id, value.provider)}}>
                  <ClearIcon fontSize="inherit"/>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
      <EditDialog 
        title={title} 
        open={open}
        containerClass={classes.formContainer}
        handleClose={handleClose}
        error={error}
      >
        <form className={classes.form} id='socapp-edit-form' onSubmit={onSubmit} noValidate>  
          <FormControl fullWidth margin='normal'>
            <FormGroup>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleProviderChange}
                id="soc-app-provider"
                label="Provider"
                name="provider"
                value={provider}
                autoFocus
                error={Boolean(error?.response?.provider)}
                helperText={error?.response?.provider?.join(' ')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleClientIdChange}
                id="soc-app-client-id"
                label="Client ID"
                name="client_id"
                value={clientId}
                error={Boolean(error?.response?.client_id)}
                helperText={error?.response?.client_id?.join(' ')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onChange={handleSecretKeyChange}
                id="soc-app-secret-key"
                label="Secret Key"
                name="secret_key"
                value={secretKey}
                error={Boolean(error?.response?.secret_key)}
                helperText={error?.response?.secret_key?.join(' ')}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={handleKeyChange}
                id="soc-app-key"
                label="Key"
                name="key"
                value={key}
                error={Boolean(error?.response?.key)}
                helperText={error?.response?.key?.join(' ')}
              />
            </FormGroup>
          </FormControl>
          <Button
            type="submit"
            form='socapp-edit-form'
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            // disabled={!name}
          >
            Save
          </Button>
        </form>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleDeleteClose}
        onConfirm={deleteSettings}
        error={error}
      >
        Social application {provider} is about to be permanently deleted. Type <strong>delete me</strong> and confirm to delete
      </ConfirmDialog>
    </div>
  )
}


const DiscountCodeView = () => {
  const classes = useListStyles()
  const [actionActive, setActionActive] = React.useState(false)
  const [code, setCode] = React.useState<string>("")
  const [percent, setPercent] = React.useState<number | string>("")
  const [expirationDate, setExpirationDate] = React.useState<Date>(null)
  const [reusable, setReusable] = React.useState(false)
  const [activeDiscountCode, setActiveDiscountCode] = React.useState<IDiscountCode>()
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const { dispatch, thunkRemoveDiscountCodes, thunkGetDiscountCodes, thunkCreateDiscountCodes } = useManagementActions()
  const discountCodes = useSelector<AppState, IDiscountCode[] | undefined>(state => state.management.discount_codes, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.discountCodeError, shallowEqual)

  useEffect(() => {
    if (typeof discountCodes === "undefined") thunkGetDiscountCodes()
    return cleanError
  // eslint-disable-next-line
  }, [])

  const cleanError = () => {
    dispatch(addError("discountCodeError", undefined))
  }

  const openEditDialog = () => {
    setPercent("")
    setEditOpen(true)
  }

  const handleEditClose = () => {
    cleanError()
    setEditOpen(false)
    setActiveDiscountCode(undefined)
  }

  const openDeleteDialog = (discount_code: IDiscountCode) => {
    setActiveDiscountCode(discount_code)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    cleanError()
    setDeleteOpen(false)
    setActiveDiscountCode(undefined)
  }

  const deleteDiscountCode = (cb: voidFn) => {
    if (activeDiscountCode) {
      thunkRemoveDiscountCodes(activeDiscountCode.id, () => {
        cb()
        handleDeleteClose()
      }, cb)
    }
  }

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setCode(event.target.value)
  }

  const handleExpirationDateChange = (value: Date) => {
    cleanError()
    setExpirationDate(value)
  }

  const handlePercentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    let num = Number(event.target.value)
    setPercent(num)
  }

  const handleReusableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setReusable(event.target.checked)
  }

  const generateCode = () => {
    const buffer = Buffer.alloc(16)
    uuidv4({}, buffer)
    setCode(buffer.toString('hex').slice(0, 8).toUpperCase())
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!actionActive) {
      setActionActive(true)
      thunkCreateDiscountCodes({code, reusable, percent, expiration_date: expirationDate}, () => {
        setEditOpen(false)
        setCode("")
        setPercent("")
        setReusable(false)
        setExpirationDate(null)
        setActionActive(false)
      }, () => {
        setCode("")
        setPercent("")
        setReusable(false)
        setExpirationDate(null)
        setActionActive(false)
      })
    }
  }

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(error?.response?.detail)}>
        <Alert severity={"error"}>
          {error?.response?.detail}
        </Alert>
      </Collapse>
      <List
        component="div"
        aria-labelledby="discount-code-list-subheader"
        subheader={
          <ListSubheader component="div" className={classes.subhead} id="discount-code-list-subheader">
            <IconButton edge="end" aria-label="add" className={classes.edit} onClick={openEditDialog}>
              <AddCircleOutlineIcon fontSize="inherit"/>
            </IconButton> 
          </ListSubheader>
        }
      >
        {discountCodes?.map((value: IDiscountCode, idx: number) => {
          return (
            <ListItem key={idx}>
              
              <div style={{display: "flex", flexWrap: "wrap"}}>
                <ListItemText primary={
                  <div style={{display: "flex"}}>
                    <Typography>
                      {value.code}
                    </Typography>
                  </div>} 
                  secondary={
                    <React.Fragment>
                      <Typography variant="body1" style={{color: "grey"}}>
                        {value.percent}% {value.reusable ? 'Daugkartinis' : 'Vienkartinis'}
                      </Typography>
                      <Typography variant="body1" style={{color: "grey"}}>
                        <b>{value.expiration_date.toLocaleString('lt-LT')}</b>
                      </Typography>
                    </React.Fragment>
                  } />  
                <IconButton edge="end" aria-label="delete" className={classes.delete} onClick={() => {openDeleteDialog(value)}}>
                  <ClearIcon fontSize="inherit"/>
                </IconButton>
              </div>
            </ListItem>
          )
        })}
      </List>
      <EditDialog 
        title="Sukurti naują kodą" 
        open={editOpen}
        containerClass={classes.formContainer}
        handleClose={handleEditClose}
        error={error}
      >
        <form id="discount-code-form" onSubmit={submit}>
          <TextField
            margin="normal"
            fullWidth
            value={code}
            inputProps={{
              minLength: 8,
              maxLength: 8
            }}
            onChange={handleCodeChange}
            label="Kodas"
            name="code"
            error={Boolean(error?.response?.code)}
            helperText={error?.response?.code?.join(' ')}
          />
          <Button variant="outlined" onClick={generateCode}>Generuoti</Button>
          <FormControl margin="normal" fullWidth error={Boolean(error?.response?.percent)}>
            <InputLabel htmlFor="discount-code-percent">Procentas</InputLabel>
            <Input
              required
              onChange={handlePercentChange}
              name="percent"
              type="number"
              id="discount-code-percent"
              value={percent}
              inputProps={{
                min: "0",
                max: "100"
              }}
            />
            <FormHelperText>{error?.response?.interval?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl margin='normal' fullWidth error={Boolean(error?.response?.expiration_date)}>
            <KeyboardDateTimePicker
              required
              disablePast
              cancelLabel="Atšaukti"
              clearable
              value={expirationDate}
              ampm={false}
              minutesStep={30}
              invalidDateMessage="Neteisingas datos formatas"
              onChange={handleExpirationDateChange}
              format="dd/MM/yyyy HH:mm"
              label="Galioja iki"
            />
            <FormHelperText style={{marginLeft: 10}}>{error?.response?.expiration_date?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl margin='normal' fullWidth error={Boolean(error?.response?.reusable)}>
            <FormControlLabel
              control={<Checkbox checked={reusable} color="primary" onChange={handleReusableChange}/>}
              label="Daugkartinis"
            />
            <FormHelperText style={{marginLeft: 10}}>{error?.response?.reusable?.join(' ')}</FormHelperText>
          </FormControl>
          <Button
            fullWidth
            type="submit"
            form="discount-code-form"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={actionActive}
          >
            {actionActive ? <CircularProgress size={25} /> : 'Išsaugoti'}
          </Button>
        </form>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleDeleteClose}
        onConfirm={deleteDiscountCode}
        error={error}
      >
        Nuolaidos kodas {activeDiscountCode?.code} bus ištrintas. Įveskite <strong>delete me</strong> ir patvirtinkite.
      </ConfirmDialog>
    </div>
  )
}


const UserList = () => {
  const classes = useListStyles()
  const authState = useGetAuthState()
  const [title, setTitle] = React.useState("")
  const [activeUser, setActiveUser] = React.useState<IUser>()
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const { dispatch, thunkRemoveUser, thunkGetUsers } = useManagementActions()
  const users = useSelector<AppState, IUser[] | undefined>(state => state.management.users, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.usersError, shallowEqual)

  useEffect(() => {
    if (typeof users === "undefined") thunkGetUsers()
    return cleanError
  // eslint-disable-next-line
  }, [])

  const cleanError = () => {
    dispatch(addError("usersError", undefined))
  }

  const openEditDialog = (title: string, user?: IUser) => {
    setTitle(title)
    setActiveUser(user)
    setEditOpen(true)
  }

  const handleEditClose = () => {
    cleanError()
    setEditOpen(false)
    setActiveUser(undefined)
  }

  const openDeleteDialog = (user: IUser) => {
    setActiveUser(user)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    cleanError()
    setDeleteOpen(false)
    setActiveUser(undefined)
  }

  const deleteUser = (cb: voidFn) => {
    if (activeUser) {
      thunkRemoveUser(activeUser.id, () => {
        cb()
        handleDeleteClose()
      }, cb)
    }
  }

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(error?.response?.detail)}>
        <Alert severity={"error"}>
          {error?.response?.detail}
        </Alert>
      </Collapse>
      <List
        component="div"
        aria-labelledby="user-list-subheader"
        subheader={
          authState.isAdmin ? <ListSubheader component="div" className={classes.subhead} id="user-list-subheader">
            <IconButton edge="end" aria-label="add" className={classes.edit} onClick={() => {openEditDialog('New User')}}>
              <AddCircleOutlineIcon fontSize="inherit"/>
            </IconButton> 
          </ListSubheader> : undefined
        }
      >
        {users?.map((value: IUser, idx: number) => {
          return (
            <ListItem key={idx}>
              <ListItemIcon>
                <Tooltip title={value.is_activated ? "Vartotojas yra aktyvuotas" : "Vartotojas nėra neaktyvuotas"}>
                  <FiberManualRecordIcon fontSize={"large"} htmlColor={value.is_activated ? "#00ff00" : "#ff0000"}/>
                </Tooltip>
              </ListItemIcon>
              <div style={{display: "flex", flexWrap: "wrap"}}>
              <ListItemText primary={
                <div style={{display: "flex"}}>
                  <Typography>
                    {value.username}
                  </Typography>&ensp;
                  {value.is_staff ? <Chip size="small" label="staff" color="primary"/> : null}&ensp;
                  {value.is_superuser ? <Chip size="small" label="admin" color="secondary" /> : null}
                </div>} 
                secondary={`${value.email}`} />
              {authState.isAdmin ? 
                <React.Fragment>
                  <IconButton edge="end" aria-label="edit" className={classes.edit} onClick={() => {openEditDialog(`Edit ${value.username}`, value)}}>
                    <EditIcon fontSize="inherit"/>
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" className={classes.delete} onClick={() => {openDeleteDialog(value)}}>
                    <ClearIcon fontSize="inherit"/>
                  </IconButton>
                </React.Fragment> : null}
                </div>
            </ListItem>
          )
        })}
      </List>
      <EditDialog 
        title={title} 
        open={editOpen}
        containerClass={classes.formContainer}
        handleClose={handleEditClose}
        error={error}
      >
        <UserControlForm user={activeUser} onSuccess={handleEditClose}/>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleDeleteClose}
        onConfirm={deleteUser}
        error={error}
      >
        User {activeUser?.username} is about to be permanently deleted. Type <strong>delete me</strong> and confirm to delete
      </ConfirmDialog>
    </div>
  )
}

const actions = [
  { icon: <FiberManualRecordIcon fontSize="small" htmlColor="#ffa64d"/>, name: 'Nauji' },
  { icon: <FiberManualRecordIcon fontSize="small" htmlColor="#1a75ff"/>, name: 'Patvirtinti' },
  { icon: <FiberManualRecordIcon fontSize="small" htmlColor="#ff5c33"/>, name: 'Atmesti' },
  { icon: <FiberManualRecordIcon fontSize="small" htmlColor="#e6e600"/>, name: 'Pristatomi' },
  { icon: <FiberManualRecordIcon fontSize="small" htmlColor="#00b300"/>, name: 'Baigti' },
]

interface OrderTabPanelProps {
  status: number
  statusText: string
  setCount: (key: number, value: number) => void
}

const OrderTabPanel = ({ status, statusText, setCount }: OrderTabPanelProps) => {
  
  const classes = useListStyles()
  const authState = useGetAuthState()
  const { thunkUpdateOrder } = useManagementActions()
  const [newData, setNewData] = React.useState<OrderData>()
  const [activeOrder, setActiveOrder] = React.useState<OrderData>()
  const [reloadActive, setReloadActive] = React.useState(false)
  const [showOpen, setShowOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const { dispatch, thunkRemoveOrder, thunkGetOrders } = useManagementActions()
  const config = useSelector<AppState, AppConfig | undefined>(state => state.user.config, shallowEqual)
  const orders = useSelector<AppState, {next: number, count: number, orders: OrderData[]} | undefined>(state => state.management.orders.get(status))
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.orderError, shallowEqual)
  const [deliveryDates, setDeliveryDates] = React.useState<Map<string, Date>>(new Map())
  const [intervals, setIntervals] = React.useState<Map<string, number>>(new Map())
  const [sendEmail, setSendEmail] = React.useState(true)

  // const calculateMinDate = (order_data: OrderData) => {
  //   let d = new Date()
  //   if (d < config.available_from)
  //     d = new Date(config.available_from)
    
  //   d.setMinutes(d.getMinutes() + config.min_produce_period)
  //   return d
  // }

  const finishReload = () => {
    setReloadActive(false)
  }

  useEffect(() => {
    if (typeof orders === "undefined") { 
      setReloadActive(true)
      thunkGetOrders(1, status, status === 5, (data) => {
        let newDates = new Map()
        let newIntervals = new Map()
        data.results.forEach(o => {
          newDates.set(o.id, new Date(o.delivery_date))
          newIntervals.set(o.id, o.interval)
        })
        setDeliveryDates(newDates)
        setIntervals(newIntervals)
        setCount(status, data.count)
        finishReload()
      }, finishReload)
    } else {
      let newDates = new Map()
      let newIntervals = new Map()
      orders.orders.forEach(o => {
        newDates.set(o.id, new Date(o.delivery_date))
        newIntervals.set(o.id, o.interval)
      })
      setDeliveryDates(newDates)
      setIntervals(newIntervals)
      setCount(status, orders.count)
    }
    return cleanError
  // eslint-disable-next-line
  }, [orders, status])

  const cleanError = () => {
    dispatch(addError("orderError", undefined))
  }

  const openShowDialog = (order: OrderData) => {
    setActiveOrder(order)
    setShowOpen(true)
  }

  const handleShowClose = () => {
    cleanError()
    setShowOpen(false)
    setActiveOrder(undefined)
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, order: OrderData) => {
    setActiveOrder(order)
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const openDeleteDialog = (order: OrderData) => {
    setActiveOrder(order)
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    cleanError()
    setDeleteOpen(false)
    setActiveOrder(undefined)
  }

  const deleteOrder = (cb?: voidFn) => {
    if (activeOrder) {
      thunkRemoveOrder(activeOrder.status, activeOrder.id, () => {
        if (cb) cb()
        handleDeleteClose()
      }, cb)
    }
  }

  const reloadOrders = () => {
    cleanError()
    dispatch({type: CLEAN_ORDERS, status})
  }

  const loadMore = (next: number | undefined) => {
    if (next) thunkGetOrders(next, status)
  }

  const handleUpdateClose = () => {
    cleanError()
    setNewData(undefined)
    setActiveOrder(undefined)
  }

  const updateOrder = (cb?: voidFn) => {
    cleanError()
    if (activeOrder) {
      let clb = () => {
        if (cb) cb() 
        handleUpdateClose()
      }
      thunkUpdateOrder(activeOrder.status, activeOrder.id, {send_email: sendEmail, ...newData}, clb, clb)
    }
  }

  const payStatusLabel = (pay_status: number) => {
    let name
    let color
    switch (pay_status) {
      case 0:
        name = 'NEAPMOKĖTA'
        color = "#ff5c33"
        break
      case 1:
      case 2:
        name = 'APMOKĖTA'
        color = "#00b300"
        break
      case 3:
        name = 'NEBAIGTA'
        color = "#99ccff"
        break
      default:
        return;
    }
    return {name, color}
  }

  const payStatusView = (pay_status: number) => {
    let label = payStatusLabel(pay_status)
    if (Boolean(label))
      return <Chip component="span" size="small" label={label.name} style={{color: "white", backgroundColor: label.color}}/>
    else
      return null
  }

  const getDeliveryPrice = (city: string) => {
    for (let p of config.delivery_map) {
      if (p.option === city)
        return p.fee
    }
    return 0
  }

  const totalPrice = (order: OrderData) => {
    let total = Math.ceil(getDeliveryPrice(order.city) * (1 - order.discount / 100))
    order.orders.forEach(o => {
      if (o.price) {
        total += o.quantity * Math.ceil(o.price * (1 - order.discount / 100))
      }
    })
    return (total / 100).toFixed(2)
  }

  const handleDateChange = (id: string, date: Date) => {
    let newDates = new Map(deliveryDates)
    cleanError()
    newDates.set(id, date)
    setDeliveryDates(newDates)
  }

  const handleIntervalChange = (id: string, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let newIntervals = new Map(intervals)
    let num = Number(event.target.value)
    cleanError()
    newIntervals.set(id, num)
    setIntervals(newIntervals)
  }

  const deliveryDateView = (order: OrderData) => {
    if (order.delivery_date) {
      let hour = order.delivery_date.getHours() * 60 + order.delivery_date.getMinutes()
      let q = Math.floor(hour / 60)
      let m = hour % 60
      let eq = Math.floor((hour + order.interval) / 60)
      let em = (hour + order.interval) % 60
      let month = order.delivery_date.getMonth() + 1
      let day = order.delivery_date.getDate()
      let dateString = `${order.delivery_date.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}` 
      return <span>{dateString} {`${q > 9 ? "" : '0'}${q}:${m > 9 ? "" : '0'}${m}`}&ndash;{`${eq > 9 ? "" : '0'}${eq}:${em > 9 ? "" : '0'}${em}`}</span> 
    }
    return null;
  }

  const handleSendEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendEmail(event.target.checked)
  }

  return (
    <div className={classes.root}>
      {Boolean(error) ?
        <Alert severity={"error"}>
          {error.response?.detail || error.response?.status}
        </Alert> : null}
      <List
        className={classes.list}
        component="div"
        aria-labelledby={`order-list-subheader-${statusText}`}
        subheader={
          <ListSubheader component="div" className={classes.subhead} id={`order-list-subheader-${statusText}`}>
            {statusText}
            <IconButton edge="end" aria-label="reload" onClick={reloadOrders} disabled={reloadActive}>
              <LoopIcon fontSize="inherit" className={reloadActive ? classes.rotation : undefined}/>
            </IconButton> 
          </ListSubheader>
        }
      >
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={sendEmail} onChange={handleSendEmailChange} />} label="Siųsti laišką" />
        </FormGroup>
        {orders?.orders?.map((value: OrderData, idx: number) => {
          return (
            <InView key={idx} as="div" onChange={(inView, _) => {if (inView) loadMore(orders.next)}} skip={idx + 1 < orders.orders.length} triggerOnce={true} initialInView={false}>
              <ListItem style={{display: "flex", flexFlow: "row wrap", alignItems: "flex-start"}}>
                <ListItemText
                  disableTypography
                  primary={
                    <Typography>
                      {status !== 5 ? 
                        <IconButton edge="start" aria-label="edit-status" className={classes.edit} onClick={(e) => handleMenuOpen(e, value)}>
                          <EditIcon fontSize="inherit"/>
                        </IconButton> : null}
                      <Link className={classes.link} onClick={() => openShowDialog(value)}>{value.id.substr(0, 6)}</Link>
                      {value.pay_status === 0 && status !== 1 && status !== 3 ?
                        <IconButton
                          color="primary"
                          aria-label="set paid" 
                          title="Nustatyti į apmokėta"
                          onClick={() => {
                            setActiveOrder(value)
                            setNewData({pay_status: 1})
                          }}
                          className={classes.payment}
                          disabled={Boolean(newData)}
                        >
                          <PaymentIcon/>
                        </IconButton> : null}
                      {authState.isAdmin && value.pay_status > 0 ?
                        <IconButton
                          color="primary"
                          aria-label="set unpaid"
                          title="Nustatyti į neapmokėtą"
                          onClick={() => {
                            setActiveOrder(value)
                            setNewData({pay_status: 0})
                          }}
                          className={classes.cancelPayment}
                          disabled={Boolean(newData)}
                        >
                          <PaymentIcon/>
                        </IconButton> : null}
                      {authState.isAdmin ?
                        <IconButton edge="end" aria-label="delete" className={classes.delete} onClick={() => {openDeleteDialog(value)}}>
                          <ClearIcon fontSize="inherit"/>
                        </IconButton> : null}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="subtitle2" color="textSecondary">
                      <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}>Užsakovas: <b>{value.firstname} {value.lastname}</b></span>
                      <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}>Užsakymo data: {value.order_date?.toLocaleString('lt-LT')}</span>
                      <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}> 
                        {value.status === 1 || value.status === 3 ?
                          <KeyboardDateTimePicker
                            strictCompareDates={true}
                            cancelLabel="Atšaukti"
                            value={deliveryDates.get(value.id)}
                            ampm={false}
                            disablePast
                            minutesStep={5}
                            // minDate={calculateMinDate(value)}
                            minDateMessage={`Data negali būti mažesnė už ${new Date().toLocaleString('lt-LT')}`}
                            invalidDateMessage="Neteisingas datos formatas"
                            onChange={(date) => handleDateChange(value.id, date)}
                            format="dd/MM/yyyy HH:mm"
                            label="Pageidaujamas laikas"
                          />
                          : <span>Pageidaujamas laikas: {deliveryDateView(value)}</span>
                        }
                      </span>
                      {value.status === 1 || value.status === 3 ?
                        <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}>
                          <FormControl fullWidth error={Boolean(error?.response?.interval)}>
                            <InputLabel htmlFor={`${value.id}-interval`}>Intervalas (minutėmis)</InputLabel>
                            <Input
                              required
                              onChange={(event) => handleIntervalChange(value.id, event)}
                              name="interval"
                              type="number"
                              id={`${value.id}-interval`}
                              value={intervals.get(value.id)}
                              inputProps={{
                                min: "0"
                              }}
                            />
                            <FormHelperText>{error?.response?.interval?.join(' ')}</FormHelperText>
                          </FormControl>
                        </span>
                        : null
                      }
                      <span style={{display: "block", padding: 7}}>
                        Suma: <b>{totalPrice(value)}&euro;</b>&nbsp;{payStatusView(value.pay_ack_status || value.pay_status)}
                      </span>
                    </Typography>
                  }
                />
              </ListItem>
            </InView>
          )
        })}
      </List>
      <OrderDialog order={activeOrder} open={showOpen} close={handleShowClose}/>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleDeleteClose}
        onConfirm={deleteOrder}
        error={error}
      >
        Užsakymas Nr.: {activeOrder?.id.substring(0,6)} bus negrįžtamai pašalintas. Įvesti <strong>delete me</strong> ir patvirtinti
      </ConfirmDialog>
      <ConfirmDialog 
        open={Boolean(newData)}
        title='Perspėjimas'
        confirmAgainst='change me'
        handleClose={handleUpdateClose}
        onConfirm={updateOrder}
        error={error}
      >
        Užsakymo Nr.: {activeOrder?.id.substring(0,6)} duomenys bus pakeisti:&nbsp;
        {typeof newData?.status !== "undefined" && Boolean(activeOrder) ? 
          <span>statusas iš <em>{actions[activeOrder.status - 1]?.name}</em> į <em>{actions[newData.status - 1].name}</em>.&nbsp;</span> : null} 
        {typeof newData?.pay_status !== "undefined" && Boolean(activeOrder) ? 
          <span>apmokėjimo statusas iš <em>{payStatusLabel(activeOrder.pay_ack_status || activeOrder.pay_status).name}</em> į <em>{payStatusLabel(newData.pay_status).name}</em>.&nbsp;</span> : null}
        {typeof newData?.delivery_date !== "undefined" && Boolean(activeOrder) ? 
          <span>pristatymo laikas iš <em>{activeOrder.delivery_date.toLocaleString('lt-LT')}</em> į <em>{newData.delivery_date.toLocaleString('lt-LT')}</em>.&nbsp;</span> : null}
        Įvesti <strong>change me</strong> ir patvirtinti
      </ConfirmDialog>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        id="status-change-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {actions.map((act, idx) => {
          let allowed_status:number[]
          switch (status) {
            case 1:
              allowed_status = [1, 2]
              break
            case 2:
              allowed_status = [2, 3, 4]
              break
            case 3:
              allowed_status = [1]
              break
            case 4:
              allowed_status = [2, 4]
              break
            default:
              allowed_status = []
          }
          if (allowed_status.indexOf(idx) > -1)
            return <MenuItem key={idx} button={true} onClick={() => {
              let data: OrderData = {status: idx + 1}
              if (deliveryDates.get(activeOrder?.id)?.getTime() !== activeOrder?.delivery_date.getTime())
                data.delivery_date = deliveryDates.get(activeOrder.id)
              if (intervals.get(activeOrder?.id) !== activeOrder?.interval)
                data.interval = intervals.get(activeOrder.id)
              setNewData(data)
              handleMenuClose()
            }}>{act.icon}&nbsp;{act.name}</MenuItem>
          else
            return null
        })}
      </Menu>
    </div>
  )
}

const OrderList = () => {
  const classes = useListStyles()
  const [tab, setTab] = React.useState(0)
  const [count, setCount] = React.useState(new Map<number, number>())

  const handleTabChange = (event: unknown, newValue: number) => {
    setTab(newValue)
  }

  const statusMap = (status: number | undefined) => {
    let title = ""
    let color = ""
    switch (status) {
      case 1:
        title = 'NAUJI'
        color = '#ffa64d'
        break
      case 2:
        title = 'PATVIRTINTI'
        color = '#1a75ff'
        break
      case 3:
        title = 'ATMESTI'
        color = '#ff5c33'
        break
      case 4:
        title = 'PRISTATOMI'
        color = '#e6e600'
        break
      case 5:
        title = 'BAIGTI'
        color = '#00b300'
        break
      default:
       break
    }
    return {title, color}
  }

  const setOrderCount = (key: number, value: number) => {
    let newCount = new Map(count)
    newCount.set(key, value)
    setCount(newCount)
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="secondary"
        textColor="secondary"
      >
        {actions.map((act, idx) => {
          let cnt = count.get(idx + 1)
          return (
            <Tab
              key={idx}
              title={act.name}
              label={typeof cnt !== "undefined" ? `(${cnt})` : null}
              icon={act.icon}
            />
          )
        })}
      </Tabs>
      <OrderTabPanel status={tab + 1} statusText={statusMap(tab + 1).title} setCount={setOrderCount}/>
    </div>
  )
}

const useStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: '100%',
      marginTop: 20,
    },
    background: {
      backgroundColor: theme.palette.background.default
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    details: {
      flexDirection: "column",
      alignItems: 'center',
    },
    error: {
      width: '100%'
    },
  }),
)

export default function ManagementConsole() {
  const classes = useStyles()
  const authState = useGetAuthState()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  }

  return (authState.isAdmin || authState.isStaff ? 
    <div className={classes.root}>
      <Accordion 
        className={classes.background} 
        elevation={0} expanded={expanded === 'settings'} 
        onChange={handleChange('settings')} 
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-content"
          id="settings-header"
        >
          <Typography className={classes.heading}>Aplikacijos nustatymai</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <AppConfigView />
          {authState.isAdmin ? <SocAppSettings /> : null}
        </AccordionDetails>
      </Accordion>
      <Accordion 
        className={classes.background} 
        elevation={0} expanded={expanded === 'discounts'} 
        onChange={handleChange('discounts')} 
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="discounts-content"
          id="discounts-header"
        >
          <Typography className={classes.heading}>Nuolaidų kodai</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <DiscountCodeView />
        </AccordionDetails>
      </Accordion>
      <Accordion 
        className={classes.background} 
        elevation={0} 
        expanded={expanded === 'users'} 
        onChange={handleChange('users')} 
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="users-content"
          id="users-header"
        >
          <Typography className={classes.heading}>Vartotojai</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserList/>
        </AccordionDetails>
      </Accordion>
      <Accordion 
        className={classes.background} 
        elevation={0} 
        expanded={expanded === 'orders'} 
        onChange={handleChange('orders')} 
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="orders-content"
          id="orders-header"
        >
          <Typography className={classes.heading}>Užsakymai</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <OrderList/>
        </AccordionDetails>
      </Accordion>
    </div> : 
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  )
}