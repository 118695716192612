import {
  Product,
  Category,
  fn,
  voidFn,
} from './types';
import { URLS, useBaseActions } from './common';


export const SET_CATEGORIES = "SET_CATEGORIES"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const RM_CATEGORY = "RM_CATEGORY"
export const SET_PRODUCTS = "SET_PRODUCTS"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const RM_PRODUCT = "RM_PRODUCT"

/*
 * Categories
*/

interface SetCategoriesAction {
  type: typeof SET_CATEGORIES
  payload: Category[]
}

interface AddCategoryAction {
  type: typeof ADD_CATEGORY
  id: number | string | undefined
  payload: Category
}

interface removeCategoryAction {
  type: typeof RM_CATEGORY
  index: number | string
}

function setCategories(
  categories: Category[]
): InventoryAction {
  return {
    type: SET_CATEGORIES,
    payload: categories
  }
}

function addCategory(
  id: number | string | undefined,
  category: Category
): InventoryAction {
  return {
    type: ADD_CATEGORY,
    id: id,
    payload: category
  }
}

function rmCategory(
  index: number | string
): InventoryAction {
  return {
    type: RM_CATEGORY,
    index: index
  }
}

/*
 * Products
*/

interface SetProductsAction {
  type: typeof SET_PRODUCTS
  payload: {
    catId: number
    products: Product[]
  }
}

interface AddProductAction {
  type: typeof ADD_PRODUCT
  payload: {
    id: number | string | undefined
    catId: number
    product: Product
  }
}

interface RemoveProductAction {
  type: typeof RM_PRODUCT
  payload: {
    id: number | string
    catId: number
  }
}

function setProducts(
  catId: number,
  products: Product[]
): InventoryAction {
  return {
    type: SET_PRODUCTS,
    payload: {
      catId: catId,
      products: products
    }
  }
}

function addProduct(
  id: number | string | undefined,
  catId: number,
  product: Product
): InventoryAction {
  return {
    type: ADD_PRODUCT,
    payload: {
      id: id,
      catId: catId,
      product: product
    }
  }
}

function rmProduct(
  id: number | string,
  catId: number
): InventoryAction {
  return {
    type: RM_PRODUCT,
    payload: {
      id: id,
      catId: catId
    }
  }
}

export const useInventoryActions = () => {
  const { dispatch, baseGet, baseUpdate, baseDelete } = useBaseActions()

  const thunkGetCategories = async (cb?: fn<Category[]>, errorCb?: voidFn) => {
    baseGet(URLS.CATEGORIES, "categoryError", setCategories, cb, errorCb)
  }

  const thunkUpdateCategory = async (data: any, catId?: number, cb?: fn<Category>, errorCb?: voidFn) => {
    baseUpdate(URLS.CATEGORIES, "categoryError", addCategory, data, catId, cb, errorCb)
  }

  const thunkRemoveCategory = async (index: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.CATEGORIES, "categoryError", rmCategory, index, cb, errorCb)
  }

  const thunkGetProducts = async (catId: number, cb?: fn<Product[]>, errorCb?: voidFn) => {
    baseGet(`${URLS.CATEGORIES}${catId}/items/`, "productError", (data) => setProducts(catId, data), cb, errorCb)
  }

  const thunkUpdateProduct = async (data: any, catId: number, id?: number, cb?: fn<Product>, errorCb?: voidFn) => {
    baseUpdate(`${URLS.CATEGORIES}${catId}/items/`, "productError", (id, data) => addProduct(id, data.category || catId, data), data, id, cb, errorCb)
  }

  const thunkRemoveProduct = async (index: number, catId: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(`${URLS.CATEGORIES}${catId}/items/`, "productError", (id) => rmProduct(id, catId), index, cb, errorCb)
  }

  return {
    dispatch: dispatch,
    thunkGetCategories: thunkGetCategories,
    thunkUpdateCategory: thunkUpdateCategory,
    thunkRemoveCategory: thunkRemoveCategory,
    thunkGetProducts: thunkGetProducts,
    thunkUpdateProduct: thunkUpdateProduct,
    thunkRemoveProduct: thunkRemoveProduct
  }
}

export type InventoryAction = SetProductsAction | AddProductAction | RemoveProductAction | 
                              SetCategoriesAction | AddCategoryAction | removeCategoryAction