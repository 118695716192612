import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
let basicTheme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#eb4034',
    }
    
  },
});

const theme = responsiveFontSizes(basicTheme);

export default theme;
