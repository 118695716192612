import 'react-phone-number-input/style.css';
import React, { createRef, forwardRef, LegacyRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { voidFn, Cart, fn, AppConfig, Order } from '../store/types';
import CartView from './cart';
import { useOrderCount, BANK_MAP, PLACES, getCompanyCity, useDeliveryData } from './common';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../store';
import { addCartData, cleanupCart, useLoginActions } from '../store/user-actions';
import { addError, grecaptchaRequest, ResponseError} from '../store/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PhoneInput from 'react-phone-number-input';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import { Conditions, Privacy } from './info';
import { CLEAN_ORDERS } from '../store/management-actions';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';


const MAX_BIGINT = 9223372036854775807
const BREAK = "break"


const useStepStyles = makeStyles((theme) => ({
  subtitle: {
    fontWeight: 700
  },
  line: {
    height: 5, 
    backgroundColor: '#408753'
  },
  explanation: {
    padding: 12
  },
  bankLabel: {
    marginTop: 10,
    marginBottom: 0
  },
  buttons: {
    display: "flex",
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
  paymentLogo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 12,
    marginBottom: 12
  },
  center: {
    // marginTop: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}))

interface StepProps {
  handleNext?: voidFn
  setConfirmed?: fn<boolean>
  handleBack: voidFn
}

const Review = ({ handleBack, setConfirmed }: StepProps) => {
  const classes = useStepStyles()
  const [ active, setActive ] = React.useState(false)
  const cart = useSelector<AppState, Cart>(state => state.user.cart, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.orderError, shallowEqual)
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const orderCount = useOrderCount()
  const { dispatch, thunkCreateOrder, thunkGetAppConfig } = useLoginActions()
  const deliveryData = useDeliveryData(cart.data.city)
  
  const calculateMinDate = () => {
    let d = new Date()
    let af = new Date(config.available_from)
    let va = [config.min_produce_period]
    cart.orders.forEach(o => {
      va.push(o.item.produce_period)
    })
    d.setMinutes(d.getMinutes() + Math.max(...va))
    af.setMinutes(af.getMinutes() + config.min_produce_period)
    if (d < af)
      d = af
    return d
  }

  const discountedDeliveryFee = () => {
    return Math.ceil(deliveryData.fee * (1 - (cart.data.discount || 0) / 100))
  }

  const deliveryDateView = () => {
    if (cart.data.delivery_date) {
      let hour = cart.data.delivery_date.getHours() * 60 + cart.data.delivery_date.getMinutes()
      let q = Math.floor(hour / 60)
      let m = hour % 60
      let eq = Math.floor((hour + cart.data.interval) / 60)
      let em = (hour + cart.data.interval) % 60
      let month = cart.data.delivery_date.getMonth() + 1
      let day = cart.data.delivery_date.getDate()
      let dateString = `${cart.data.delivery_date.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}` 
      return <span>{dateString} {`${q > 9 ? "" : '0'}${q}:${m > 9 ? "" : '0'}${m}`}&ndash;{`${eq > 9 ? "" : '0'}${eq}:${em > 9 ? "" : '0'}${em}`}</span> 
    }
    return null;
  }

  const itemPrice = (order: Order) => {
    let coeffs:number[] = []
    order.choice?.split("|").forEach(ch => {
      let s = ch.split(";")
      let num = 1
      if (s.length === 2) {
        let cnum = parseFloat(s[1])
        if (!isNaN(cnum)) num = cnum
      }
      coeffs.push(num)
    })
    
    let coeff = coeffs.length ? coeffs.reduce((a, b) => a + b, 0) / coeffs.length : 1

    return order.quantity * Math.ceil(order.item.price * coeff * (1 - order.item.discount / 100)) / 100
  }

  const onError = () => {
    setActive(false)
  }

  const clearError = () => {
    dispatch(addError("orderError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const placeOrder = () => {
    // Save the order in the database and then initiate payment procedure
    
    let minD = calculateMinDate()
    for (let timed_config of config.timed_config.filter(x => x.name === BREAK)) {
      if (cart.data.delivery_date >= timed_config.date_from && cart.data.delivery_date <= timed_config.date_to) {
        dispatch(addError("orderError", new ResponseError(
          {
            detail: `Pageidaujamas laikas negali būti tarp ${timed_config.date_from.toLocaleString('lt-LT')} ir ${timed_config.date_to.toLocaleString('lt-LT')}`
          }, 
          400
        )))
        return
      }
    }
    if (minD > cart.data.delivery_date)
      dispatch(addError("orderError", new ResponseError(
        {
          detail: `Pageidaujamas laikas negali būti mažesnis už ${minD.toLocaleString('lt-LT')}`
        }, 
        400
      )))  
    else {
      clearError()
      setActive(true)
      grecaptchaRequest('order', (ctoken) => {
        let orders = cart.orders.map(order => {
          return {item: order.item.id, quantity: order.quantity, choice: order.choice}
        })
        let cartData = {...cart.data, orders}
        if (cartData.delivery_method === 0) {
          delete cartData.city
          delete cartData.address
        }
        thunkCreateOrder(cartData, ctoken, (data) => {
          thunkGetAppConfig(() => {
            dispatch(cleanupCart())
            dispatch({type: CLEAN_ORDERS, status: 0})
            setConfirmed(true)
          })
        }, onError)
      }, (err) => {
        dispatch(addError("orderError", new ResponseError({detail: 'Užsakymo priimti nepavyko'}, 400)))
        console.log(err)
        onError()
      })
    }
  }

  const companyInfo = () => {
    return <React.Fragment>
      <Typography variant="body2">
        <b>Įmonė:</b> {cart.data.company.name}
      </Typography>
      <Typography variant="body2">
        <b>Kodas:</b> {cart.data.company.code}
      </Typography>
      {Boolean(cart.data.company.pvm_code) ?
        <Typography variant="body2">
          <b>PVM kodas:</b> {cart.data.company.pvm_code}
        </Typography> : null}
      <Typography variant="body2">
        <b>Adresas:</b> {cart.data.company.address}
      </Typography>
      {Boolean(cart.data.company.zip_code) ?
        <Typography variant="body2">
          <b>Pašto kodas:</b> {cart.data.company.zip_code}
        </Typography> : null}
      <Typography variant="body2">
        <b>Miestas:</b> {getCompanyCity(cart.data.company.city)}
      </Typography>
    </React.Fragment>
  }  

  const deliveryInfo = () => {
    switch (cart.data.delivery_method) {
      case 0:
        return (
          <Typography variant="body1" gutterBottom>
            Užsakymą atsiimsite adresu:<br/><strong>{config.production.address}, {config.production.city}</strong>
          </Typography>
        )
      case 1:
        return (
          <Typography variant="body1" gutterBottom>
            Užsakymas bus pristatytas adresu:<br/>
            <strong>{cart.data.address}, {deliveryData.city}</strong>
          </Typography>
        )
      default:
        break;
    }
  }

  const choiceString = (choice: string) => {
    return choice.split("|").map(s => s.split(";")[0]).join(", ")
  }

  const paymentInfo = () => {
    switch (cart.data.pay_method) {
      case 1:
        return (
          <Typography variant="body1" gutterBottom>
            Apmokėsite grynaisiais pinigais arba bankine kortele užsakymo atsiėmimo arba pristatymo metu
          </Typography>
        )
      case 2:
        return (
          <Typography variant="body1" gutterBottom>
            Apmokėsite bankiniu pavedimu
          </Typography>
        )
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return (
          <React.Fragment>
            <Typography align="center" variant="body1" className={classes.bankLabel}>
              {BANK_MAP[cart.data.pay_method].alt}
            </Typography>
            <img 
              className={classes.paymentLogo} 
              src={`https://bank.paysera.com/assets/image/payment_types/${BANK_MAP[cart.data.pay_method].type}.png`}
              alt={BANK_MAP[cart.data.pay_method].alt}
            />
          </React.Fragment>
        )
      default:
        break
    }
  }

  return (
    <React.Fragment>
      {Boolean(error) ?
        <Alert severity="error">{error.response?.detail || error.response?.delivery_date?.join(", ") || "Užsakymo priimti nepavyko"}</Alert>
      : null}
      <hr className={classes.line}/>
      <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
        Užsakovas
      </Typography>
      <Typography variant="body1">
        {cart.data.firstname?.toUpperCase()} {cart.data.lastname?.toUpperCase()}
      </Typography>
      <Typography variant="body2">
        <b>El. paštas:</b> {cart.data.email}
      </Typography>
      <Typography variant="body2" gutterBottom>
        <b>Tel. nr.:</b> +{cart.data.tel}
      </Typography>
      {Boolean(cart.data.company) ? 
        <React.Fragment>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Juridinio asmens informacija
          </Typography>
          {companyInfo()}
        </React.Fragment>
      : null}
      <hr className={classes.line}/>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Pristatymas
      </Typography>
      {deliveryInfo()}
      {cart.data.comment ? 
        <React.Fragment>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Komentaras
          </Typography>
          <Typography variant="body1" gutterBottom>
            {cart.data.comment}
          </Typography>
        </React.Fragment> : null}
      <Typography variant="subtitle1" className={classes.subtitle}>
        Pageidaujamas laikas
      </Typography>
      <Typography variant="body1" gutterBottom>
        {deliveryDateView()}
      </Typography>
      <hr className={classes.line}/>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Apmokėjimas
      </Typography>
      {paymentInfo()}
      <hr className={classes.line}/>
      <Typography variant="subtitle1" className={classes.subtitle}>
        Užsakymas
      </Typography>
      <List disablePadding>
        {cart.orders.map((order, key) => (
          <ListItem key={key} style={{
            display: "flex",
            alignItems: "center",
            flexFlow: 'row wrap',
          }} disableGutters>
            <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <span>{order.item.name} × {order.quantity}</span>
              {order.choice ? <Chip size="small" color="primary" label={choiceString(order.choice)}/> : null}
            </div>} style={{
              marginRight: 15
            }}/>
            <Typography variant="body1" color="primary" component="span">
              {(itemPrice(order)).toFixed(2)}&euro; 
            </Typography>
          </ListItem>))}
        {deliveryData.fee ?
        <ListItem style={{
          display: "flex",
          alignItems: "center",
          flexFlow: 'row wrap',
        }} disableGutters>
          <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            <span>Pristatymas</span>
          </div>} style={{
            marginRight: 15
          }}/>
          <Typography variant="body1" color="primary" component="span">
            {(deliveryData.fee / 100).toFixed(2)}&euro; 
          </Typography>
        </ListItem> : null}
        {cart.data.discount ?
          <ListItem style={{
            display: "flex",
            alignItems: "center",
            flexFlow: 'row wrap',
          }} disableGutters>
            <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
              <span>Nuolaida ({cart.data.discount}%)</span>
            </div>} style={{
              marginRight: 15
            }}/>
            <Typography variant="body1" color="primary" component="span">
            {(orderCount.totalDiscountedPrice - orderCount.totalPrice + (discountedDeliveryFee() - deliveryData.fee) / 100).toFixed(2)}&euro;
            </Typography>
          </ListItem> : null}
        <hr />
        <ListItem style={{
          display: "flex",
          alignItems: "center",
          flexFlow: 'row wrap',
        }} disableGutters>
          <ListItemText style={{
            marginRight: 15
          }} primary={`Suma`}/>
          <Typography variant="body1" color="primary" component="span" style={{fontWeight: 800}}>
            {((orderCount.totalDiscountedPrice + discountedDeliveryFee() / 100)).toFixed(2)}&euro; 
          </Typography>
        </ListItem>
      </List>
      <div className={classes.buttons}>
          
        <Button onClick={handleBack} className={classes.button} disabled={active}>
            Atgal
        </Button>  
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={placeOrder}
          disabled={active}
        >
          {active ? <CircularProgress size={25} /> : 'Užsakyti'}
        </Button>
      </div>
    </React.Fragment>
  )
}


const phoneInput = forwardRef((props, ref) => {

  return (
    <TextField
      {...props}
      inputRef={ref}
      autoComplete="tel"
      label="Telefonas"
      name="phone"
      style={{
        marginBottom: 15
      }}
    />
    )
  })


interface InfoErrorData {
  firstname?: string
  lastname?: string
  email?: string
  tel?: string
  address?: string
  delivery_date?: string
  pay_method?: string
  conditionsAccepted?: string
  city?: string
  comment?: string
  company_name?: string
  company_code?: string
  pvm_code?: string
  company_address?: string
  company_city?: string
  zip_code?: string
  hour?: string
  discountCode?: string
}

const ClientInfoForm = ({handleNext, handleBack}: StepProps) => {
  const classes = useStepStyles()
  const { dispatch, thunkGetAppConfig, thunkVerifyDiscountCode } = useLoginActions()
  const cart = useSelector<AppState, Cart>(state => state.user.cart, shallowEqual)
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const [delivery_method, setDeliveryMethod] = React.useState(cart.data.delivery_method || 0)
  const [pay_method, setPaymentMethod] = React.useState(cart.data.pay_method || 2)
  const [firstname, setFirstname] = React.useState(cart.data.firstname || "")
  const [lastname, setLastname] = React.useState(cart.data.lastname || "")
  const [email, setEmail] = React.useState(cart.data.email || "")
  const [tel, setTel] = React.useState(cart.data.tel?.toString() || "")
  const [address, setAddress] = React.useState(cart.data.address || "")
  const [error, setError] = React.useState<InfoErrorData>({})
  const [conditionsAccepted, setConditionsAccepted] = React.useState(false)
  const [conditionsOpen, setConditionsOpen] = React.useState(false)
  const [privacyOpen, setPrivacyOpen] = React.useState(false)
  const [city, setCity] = React.useState(cart.data.city || "")
  const [comment, setComment] = React.useState(cart.data.comment || "")
  const [companyRepr, setCompanyRepr] = React.useState(Boolean(cart.data.company))
  const [companyName, setCompanyName] = React.useState(cart.data.company?.name || "")
  const [companyCode, setCompanyCode] = React.useState(cart.data.company?.code || "")
  const [pvmCode, setPvmCode] = React.useState(cart.data.company?.pvm_code || "")
  const [companyAddress, setCompanyAddress] = React.useState(cart.data.company?.address || "")
  const [companyCity, setCompanyCity] = React.useState(cart.data.company?.city || "")
  const [zipCode, setZipCode] = React.useState(cart.data.company?.zip_code || "")
  const [hour, setHour] = React.useState<number | string>("")
  const [discountCode, setDiscountCode] = React.useState(cart.data.discount_code || "")

  const calculateMinDate = () => {
    let d = new Date()
    let af = new Date(config.available_from)
    let va = [config.min_produce_period]
    cart.orders.forEach(o => {
      va.push(o.item.produce_period)
    })
    d.setMinutes(d.getMinutes() + Math.max(...va))
    af.setMinutes(af.getMinutes() + config.min_produce_period)
    if (d < af)
      d = af
    return d
  }

  const [selectedDate, setSelectedDate] = React.useState<Date>(null)

  const clearError = () => {
    setError({})
  }

  const handleDateChange = (value: Date) => {
    clearError()
    setHour("")
    setSelectedDate(value)
  }

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    clearError()
    setCity(event.target.value)
  }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setComment((event.target as HTMLInputElement).value)
  }

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setCompanyName((event.target as HTMLInputElement).value)
  }

  const handleCompanyCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    if (event.target.value === "" || !isNaN(Number(event.target.value)))
      setCompanyCode(event.target.value)
  }

  const handlePvmCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPvmCode((event.target as HTMLInputElement).value)
  }

  const handleCompanyAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setCompanyAddress((event.target as HTMLInputElement).value)
  }

  const handleCompanyCityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    clearError()
    setCompanyCity((event.target as HTMLInputElement).value)
  }

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setZipCode((event.target as HTMLInputElement).value)
  }

  const handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setDeliveryMethod(Number((event.target as HTMLInputElement).value))
  }

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPaymentMethod(Number((event.target as HTMLInputElement).value))
  }

  const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setFirstname((event.target as HTMLInputElement).value)
  }

  const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setLastname((event.target as HTMLInputElement).value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail((event.target as HTMLInputElement).value)
  }

  const handleTelChange = (value?: string) => {
    clearError()
    setTel(value || "")
  }

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setAddress((event.target as HTMLInputElement).value)
  }

  const handleBankChange = (event: React.ChangeEvent<{ value: string }>) => {
    clearError()
    setPaymentMethod(Number(event.target.value) as number)
  }

  const handleConditionsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setConditionsAccepted(event.target.checked)
  }

  const handleCompanyReprChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setCompanyRepr(event.target.checked)
  }

  const handleHourChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    clearError()
    let num = Number(event.target.value)
    setHour(isNaN(num) ? "" : num)
  }

  const handleDiscountCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setDiscountCode((event.target as HTMLInputElement).value.toUpperCase())
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    let newErr: InfoErrorData = {}
    if (isNaN(selectedDate?.getTime()))
      newErr.delivery_date = `Pageidaujamas laikas yra privalomas`
    if (hour === "")
      newErr.hour = `Pageidaujama pristatymo valanda yra privaloma`
    if (!("delivery_date" in newErr) && !("hour" in newErr)) {
      let deliveryDate = new Date(selectedDate)
      deliveryDate.setHours(Math.floor(hour as number / 60), hour as number % 60, 0) 
      for (let timed_config of config.timed_config.filter(x => x.name === BREAK)) {
        if (deliveryDate >= timed_config.date_from && deliveryDate <= timed_config.date_to) {
          newErr = {delivery_date: `Data negali būti tarp ${timed_config.date_from.toLocaleString('lt-LT')} ir ${timed_config.date_to.toLocaleString('lt-LT')}`}
          break
        }
      }
      
      let minD = calculateMinDate()
      if (minD > deliveryDate)
        newErr = {delivery_date: `Data negali būti mažesnė už ${minD.toLocaleString('lt-LT')}`}
    }
    
    if (!conditionsAccepted)
      newErr.conditionsAccepted = `Jūs turite sutikti su paslaugų teikimo sąlygomis kad galėtumėte eiti toliau`
    if (!firstname)
      newErr.firstname = `Vardas yra privalomas`
    if (!lastname)
      newErr.lastname = `Pavardė yra privaloma`
    if (!email)
      newErr.email = `El. paštas yra privalomas`
    else {    
      let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
      if (!pattern.test(email)) {
        newErr.email = `Neteisingas el. pašto formatas`
      }
    }
    if (!tel)
      newErr.tel = `Tel. nr. yra privalomas`
    else {
      let telephone = Number(tel.startsWith("+") ? tel.substring(1): tel)
      if (telephone > MAX_BIGINT)
        newErr.tel = `Tel. nr. yra neteisingas`
    }
    if (delivery_method === 1) {
      if (!address)
        newErr.address = `Adresas yra privalomas`
      if (!city)
        newErr.city = `Miestas arba rajonas yra privalomas`
    }
    if (pay_method === 0)
      newErr.pay_method = `Pasirinkite banką`
    if (companyRepr) {
      if (!companyName)
        newErr.company_name = `Įmonės pavadinimas yra privalomas`
      if (!companyCode)
        newErr.company_code = `Įmonės kodas yra privalomas`
      if (!companyAddress)
        newErr.company_address = `Įmonės buveinės adresas yra privalomas`
      if (!companyCity)
        newErr.company_city = `Įmonės buveinės miestas yra privalomas`
    }

    setError(newErr)

    if (Object.keys(newErr).length === 0) {
      let company
      let deliveryDate = new Date(selectedDate)
      deliveryDate.setHours(Math.floor(hour as number / 60), hour as number % 60)
      if (companyRepr) {
        company = {
          company: {
            name: companyName,
            code: companyCode,
            pvm_code: Boolean(pvmCode) ? pvmCode : undefined,
            address: companyAddress,
            city: companyCity,
            zip_code: Boolean(zipCode) ? zipCode : undefined
          }
        }
      }
      thunkGetAppConfig((conf) => {
        let month = selectedDate.getMonth() + 1
        let day = selectedDate.getDate()
        let dateString = `${selectedDate.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}`
        if (dateString in conf.delivery_date_map && hour in conf.delivery_date_map[dateString] && conf.delivery_date_map[dateString][hour] >= conf.orders_per_moment) {
          setError({hour: 'Pageidaujama pristatymo valanda nebegalima'})  
          setHour('')
        } else {
          if (discountCode) {
            grecaptchaRequest('order', (ctoken) => {
              thunkVerifyDiscountCode(discountCode, ctoken, (data) => {
                dispatch(addCartData({
                  firstname,
                  lastname,
                  email,
                  tel: Number(tel.startsWith("+") ? tel.substring(1): tel),
                  comment: Boolean(comment) ? comment : undefined,
                  delivery_method,
                  pay_method,
                  city,
                  address: delivery_method > 0 ? address : undefined,
                  delivery_date: deliveryDate,
                  interval: config.interval,
                  discount_code: discountCode,
                  discount: data.percent,
                  ...company
                }, true))
                if (handleNext) handleNext()
              }, () => {
                setError({discountCode: 'Nuolaidos kodas negalioja'})  
              })
            }, (err) => {
              setError({discountCode: 'Nepavyko patikrinti nuolaidos kodo'}) 
            })
            
          } else {
            dispatch(addCartData({
              firstname,
              lastname,
              email,
              tel: Number(tel.startsWith("+") ? tel.substring(1): tel),
              comment: Boolean(comment) ? comment : undefined,
              delivery_method,
              pay_method,
              city,
              address: delivery_method > 0 ? address : undefined,
              delivery_date: deliveryDate,
              interval: config.interval,
              ...company
            }, true))
            if (handleNext) handleNext()
          }
        }
      })
    }
  }

  const getDeliveryFee = () => {
    for (let p of config.delivery_map) {
      if (p.option === city) 
        return (p.fee/100).toFixed(2)
    }
  }

  const hourView = () => {
    let options = []
    let hour = config.day_start 
    let endHour = hour
    if (selectedDate && !isNaN(selectedDate.getTime())) {
      let minD = calculateMinDate()
      let deliveryDate = new Date(selectedDate)
      while (hour <= config.day_end) {
        let q = Math.floor(hour / 60)
        let m = hour % 60
        let month = selectedDate.getMonth() + 1
        let day = selectedDate.getDate()
        let dateString = `${selectedDate.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}`
        endHour += config.interval
        let eq = Math.floor(endHour / 60)
        let em = endHour % 60
        deliveryDate.setHours(q, m)
        let disabled = false
        for (let timed_config of config.timed_config.filter(x => x.name === BREAK)) {
          if (deliveryDate >= timed_config.date_from && deliveryDate <= timed_config.date_to) {
            disabled = true
            break
          }
        }
        options.push(
          <option 
            key={hour} 
            value={hour}
            disabled={disabled || deliveryDate < minD || (dateString in config.delivery_date_map && hour in config.delivery_date_map[dateString] && config.delivery_date_map[dateString][hour] >= config.orders_per_moment)}
          >
            {`${q > 9 ? "" : '0'}${q}:${m > 9 ? "" : '0'}${m}`}&ndash;{`${eq > 9 ? "" : '0'}${eq}:${em > 9 ? "" : '0'}${em}`}
          </option>
        )
        hour = endHour
      }
    }
    return options
  }

  const deliveryMethodView = () => {
    switch (delivery_method) {
      case 0:
        return (
          <Typography className={classes.explanation}>
            Užsakymą atsiimsite adresu: {config.production.address}, {config.production.city}
          </Typography>
        )
      case 1:
        return (
          <React.Fragment>
            <TextField
              required={delivery_method === 1}
              id="address"
              name="address"
              label="Adresas"
              value={address}
              onChange={handleAddressChange}
              fullWidth
              autoComplete="street-address"
              error={Boolean(error.address)}
              helperText={error.address}
            />
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={12} sm={4} style={{marginTop: 20}}>
                <FormControl fullWidth required error={Boolean(error.city)}>
                  <InputLabel htmlFor="order-city">Miestas/Rajonas</InputLabel>
                  <NativeSelect 
                    id="order-city"
                    value={city} 
                    onChange={handleCityChange}
                  >
                    <option value="" disabled></option>
                    {config.delivery_map.map((p, idx) => <option key={idx} value={p.option}>{p.value}</option>)}
                  </NativeSelect>
                  <FormHelperText>{error.city}</FormHelperText>
                </FormControl>
              </Grid>
              {city ? 
                <Grid item xs={12} sm={8} style={{marginTop: 20}}>
                  <Typography className={classes.subtitle} align="center">
                    Pristatymo kaina: {getDeliveryFee()}&euro;
                  </Typography>
                </Grid> : null}
            </Grid>
            
          </React.Fragment>
          
        )
      default:
        break;
    }
  }

  const paymentMethodView = () => {
    switch (pay_method) {
      // case 1:
      //   return (
      //     <Typography className={classes.explanation}>
      //       Apmokėsite grynaisiais pinigais&nbsp;
      //       {/* arba bankine kortele&nbsp;  */}
      //       užsakymo atsiėmimo arba pristatymo metu
      //     </Typography>
      //   )
      case 2:
        return (
          <Typography className={classes.explanation}>
            Apmokėsite bankiniu pavedimu
          </Typography>
        )
      // case 3:
      //   return (
      //     <img 
      //       className={classes.paymentLogo} 
      //       src="https://bank.paysera.com/assets/image/payment_types/card.png" 
      //       alt="Mokėjimo kortelės"
      //     />)
      case 0:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return (
          <React.Fragment>
            <div className={classes.center}>
              <FormControl required error={Boolean(error.pay_method)}>
                <InputLabel>Bankas</InputLabel>
                <NativeSelect value={pay_method || ""} onChange={handleBankChange}>
                  <option value="" disabled></option>
                  <option value={4}>{BANK_MAP[4].alt}</option>
                  <option value={5}>{BANK_MAP[5].alt}</option>
                  <option value={6}>{BANK_MAP[6].alt}</option>
                  <option value={7}>{BANK_MAP[7].alt}</option>
                  <option value={8}>{BANK_MAP[8].alt}</option>
                  <option value={9}>{BANK_MAP[9].alt}</option>
                  <option value={10}>{BANK_MAP[10].alt}</option>
                </NativeSelect>
                <FormHelperText>{error.pay_method}</FormHelperText>
              </FormControl>
              
            </div>
            {pay_method !== 0 ? 
              <img 
                className={classes.paymentLogo} 
                src={`https://bank.paysera.com/assets/image/payment_types/${BANK_MAP[pay_method].type}.png`}
                alt={BANK_MAP[pay_method].alt}
              /> : null}
          </React.Fragment>
        )
      default:
        break;
    }
  }

  const phRef = createRef()

  return (
    <div>
      <form id='client-info-form' noValidate onSubmit={submit}>
        <Typography variant="h6" gutterBottom>
          Užsakovo informacija
        </Typography>
        <hr className={classes.line}/>
        <Grid container spacing={3} style={{marginBottom: 15}}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="firstName"
              label="Vardas"
              value={firstname}
              onChange={handleFirstnameChange}
              fullWidth
              autoComplete="given-name"
              error={Boolean(error.firstname)}
              helperText={error.firstname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="lastName"
              label="Pavardė"
              value={lastname}
              onChange={handleLastnameChange}
              fullWidth
              autoComplete="family-name"
              error={Boolean(error.lastname)}
              helperText={error.lastname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="email"
              label="El. paštas"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              autoComplete="email"
              error={Boolean(error.email)}
              helperText={error.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={Boolean(error.tel)}>
              <PhoneInput
                required
                ref={phRef as LegacyRef<any>}
                defaultCountry="LT"
                value={tel}
                onChange={handleTelChange}
                inputComponent={phoneInput}
              />
              <FormHelperText style={{
                marginTop: -10
              }}>{error.tel}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <FormControlLabel
          control={<Checkbox checked={companyRepr} color="primary" onChange={handleCompanyReprChange}/>}
          label="Juridinis asmuo (reikalinga sąskaita-faktūra)"
        />
        {companyRepr ? <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="companyName"
              label="Įmonės pavadinimas"
              value={companyName}
              onChange={handleCompanyNameChange}
              autoComplete="organization"
              fullWidth
              error={Boolean(error.company_name)}
              helperText={error.company_name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="companyCode"
              label="Įmonės kodas"
              value={companyCode}
              onChange={handleCompanyCodeChange}
              fullWidth
              error={Boolean(error.company_code)}
              helperText={error.company_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="pvmCode"
              label="PVM kodas"
              value={pvmCode}
              onChange={handlePvmCodeChange}
              fullWidth
              error={Boolean(error.pvm_code)}
              helperText={error.pvm_code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              name="companyAddress"
              label="Adresas"
              value={companyAddress}
              onChange={handleCompanyAddressChange}
              fullWidth
              error={Boolean(error.company_address)}
              helperText={error.company_address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" required error={Boolean(error.company_city)}>
              <InputLabel htmlFor="company-city">Miestas/Rajonas</InputLabel>
              <NativeSelect 
                id="company-city"
                fullWidth
                value={companyCity} 
                onChange={handleCompanyCityChange}
              >
                <option value="" disabled></option>
                {PLACES.map((place, idx) => <option key={idx} value={place[0]}>{place[1]}</option>)}
              </NativeSelect>
              <FormHelperText>{error.company_city}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="postal-code"
              label="Pašto Kodas"
              value={zipCode}
              onChange={handleZipCodeChange}
              fullWidth
              autoComplete="postal-code"
              error={Boolean(error.zip_code)}
              helperText={error.zip_code}
            />
          </Grid>
        </Grid> : null}
        <Typography variant="h6" gutterBottom style={{marginTop: 25}}>
          Pristatymas
        </Typography>
        <hr className={classes.line}/>
        <Grid container spacing={2} style={{marginBottom: 25}}>
          <Grid item xs={12}>
            <RadioGroup value={delivery_method} onChange={handleDeliveryChange}>
              <FormControlLabel value={0} control={<Radio />} label="Atsiimsiu pats" />
              <FormControlLabel value={1} control={<Radio />} label="Pristatyti mano nurodytu adresu" />
            </RadioGroup>
            {deliveryMethodView()}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              multiline={true}
              rows={3}
              fullWidth
              onChange={handleCommentChange}
              name="comment"
              label="Papildoma informacija"
              id="order-comment"
              value={comment}
              error={Boolean(error.comment)}
              helperText={error.comment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={Boolean(error.delivery_date)}>
              <KeyboardDatePicker
                cancelLabel="Atšaukti"
                fullWidth
                required
                value={selectedDate}
                clearable
                disablePast
                minDateMessage={''}
                invalidDateMessage="Neteisingas datos formatas"
                onChange={handleDateChange}
                minDate={new Date(calculateMinDate().toDateString())}
                format="dd/MM/yyyy"
                label="Pageidaujama data"
              />
              <FormHelperText>{error.delivery_date}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required error={Boolean(error.hour)} disabled={!selectedDate || isNaN(selectedDate.getTime())}>
              <InputLabel htmlFor="order-hour">Pageidaujamas intervalas</InputLabel>
              <NativeSelect 
                id="order-hour"
                value={hour} 
                onChange={handleHourChange}
              >
                <option value="" disabled></option>
                {hourView()}
              </NativeSelect>
              <FormHelperText>{error.hour}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Apmokėjimo būdas
        </Typography>
        <hr className={classes.line}/>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup value={pay_method > 3 ? 0 : pay_method} onChange={handlePaymentChange}>
              {/* <FormControlLabel value={1} control={<Radio />} label="Grynieji pinigai" /> */}
              <FormControlLabel value={2} control={<Radio />} label="Bankinis pavedimas" />
              <FormControlLabel value={0} control={<Radio />} label="Elektroninė bankininkystė" />
              {/*<FormControlLabel value={3} control={<Radio />} label="VISA/MasterCard internetu" /> */}
            </RadioGroup>
            {paymentMethodView()}
          </Grid>
        </Grid>
        <Typography variant="h6" gutterBottom>
          Nuolaidos kodas
        </Typography>
        <hr className={classes.line}/>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl margin='normal' error={Boolean(error.discountCode)} fullWidth>
              <div className={classes.center}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  onChange={handleDiscountCodeChange}
                  name="discount-code"
                  id="order-discount-code"
                  style={{
                    width: 120
                  }}
                  inputProps={{ maxLength: 8 }}
                  value={discountCode}
                />
                <FormHelperText>{error.discountCode}</FormHelperText>
              </div>
            </FormControl>
          </Grid>
        </Grid>
        <hr className={classes.line}/>
        <FormControl margin='normal' error={Boolean(error.conditionsAccepted)}>
          <FormHelperText style={{marginLeft: 10}}>{error.conditionsAccepted}</FormHelperText>
          <div style={{display: "flex", alignItems: "flex-start", color: Boolean(error.conditionsAccepted) ? "red" : undefined}}>
            <Checkbox 
              checked={conditionsAccepted} 
              onChange={handleConditionsAcceptedChange} 
              color='primary' 
              name="conditions-accepted" 
              style={{color: Boolean(error.conditionsAccepted) ? "red" : undefined}}
            /> 
            <span style={{paddingTop: 9, fontSize: 15}}>
              Patvirtinu, kad susipažinau ir sutinku su&nbsp; 
              <Link style={{cursor: "pointer"}} onClick={() => {setConditionsOpen(true)}}>
                paslaugų teikimo sąlygomis
              </Link>&nbsp; 
              ir&nbsp;
              <Link style={{cursor: "pointer"}} onClick={() => {setPrivacyOpen(true)}}>
                privatumo politika
              </Link>&nbsp;
              </span>
          </div>
        </FormControl>
        <div style={{width: "100%", textAlign: "right", color: "red", marginTop: 15}}>{Boolean(Object.keys(error).length) ? "Jūsų pateiktuose duomenyse yra klaidų!": ""}</div>
        <div className={classes.buttons}>
          <Button onClick={handleBack} className={classes.button}>
            Atgal
          </Button>  
          <Button
            form='client-info-form'
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Patvirtinti
          </Button>
        </div>
      </form>
      <Conditions open={conditionsOpen} onClose={() => setConditionsOpen(false)} />
      <Privacy open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '0 auto',
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      width: 600,
    },
    backgroundColor: theme.palette.background.default, 
  },
  stepper: {
    [theme.breakpoints.up(300 + theme.spacing(3) * 2)]: {
      width: 300,
    },
    margin: '0 auto',
    padding: theme.spacing(3, 0, 5),
    backgroundColor: theme.palette.background.default,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))


const steps = ['Krepšelis', 'Duomenys', 'Užsakymas']


const Checkout = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [confirmed, setConfirmed] = React.useState(false)
  const orderCount = useOrderCount()

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <CartView onNextClick={handleNext} />
      case 1:
        return <ClientInfoForm handleNext={handleNext} handleBack={handleBack}/>
      case 2:
        return <Review handleBack={handleBack} setConfirmed={setConfirmed}/>
      default:
        throw new Error('Unknown step');
    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return confirmed ? 
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
      <Alert severity="success" style={{maxWidth: 600, alignSelf: "center", marginTop: 50}}>
        <AlertTitle>Užsakymas priimtas!</AlertTitle>
        Patvirtinimas ir informacija dėl tolesnės eigos išsiųsta užsakyme pateiktu elektroniniu paštu.
      </Alert>
    </div> : orderCount.count === 0 ?
    <Typography style={{marginTop: 40}} variant="h5" align="center">Jūsų krepšelis tuščias</Typography> :
    <Paper className={classes.paper} elevation={0}>
      <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </Paper>
}


export default Checkout