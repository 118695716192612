import React, { useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeUseStyles, useSearchQuery } from './common';
import { useLoginActions } from "../store/user-actions";
import { useParams, useHistory } from 'react-router-dom';
import { addError, grecaptchaRequest, ResponseError } from '../store/common';
import CenteredCircularProgress from './progress';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../store';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';


const paymentAckInfo = (status: number) => {
  let severity
  let title
  let message
  switch (status) {
    case 0:
      severity = "error"
      title = "Mokėjimas nepavyko!"
      message = "Mokėjimo užsakymo įvykdyti nepavyko"
      break
    case 1:
      severity = "success"
      title = "Užsakymas apmokėtas!"
      message = "Apmokėjimas už šį užsakymą patvirtintas"
      break
    case 2:
      severity = "info"
      title = "Mokėjimas priimtas!"
      message = "Mokėjimo užklausa priimta, laukiama patvirtinimo dėl jos įvykdymo"
      break
    case 3:
      severity = "warning"
      title = "Trūksta informacijos!"
      message = "Mokėjimo užklausai patvirtinti trūksta informacijos"
      break
    default:
      break 
  }
  return <Alert severity={severity} style={{maxWidth: 600}}>
    <AlertTitle>{title}</AlertTitle>
    {message}
  </Alert>
}


const useStyles = makeUseStyles((theme: Theme) => (
    {
      root: {
        marginTop: 50, 
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      },
      bankLabel: {
        marginTop: 10,
        marginBottom: 5
      },
      link: {
        marginTop: 30
      }
    }
  )
)


export const ResolvePaymentView = () => {
  let { state } = useParams<{ state: string }>()
  let query = useSearchQuery()
  const history = useHistory()
  const classes = useStyles()
  const [ active, setActive ] = React.useState(false)
  const [ payAckStatus, setPayAckStatus ] = React.useState<number>()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.orderError, shallowEqual)
  const { dispatch, thunkVerifyOrder } = useLoginActions()

  const onError = () => {
    setActive(false)
  }

  const clearError = () => {
    dispatch(addError("orderError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    switch (state) {
      case "accept":
        let data = query.get("data") || ""
        let ss1 = query.get("ss1") || ""
        let ss2 = query.get("ss2") || ""
        if (data && ss1 && ss2) {
          clearError()
          setActive(true)
          grecaptchaRequest('order', (ctoken) => {
            thunkVerifyOrder({data, ss1, ss2}, ctoken, (data) => {
              setActive(false)
              setPayAckStatus(data.pay_ack_status)
            }, onError)
          }, (err) => {
            dispatch(addError("orderError", new ResponseError({detail: 'Apmokėjimo patvirtinimo klaida!'}, 400)))
            console.log(err)
            onError()
          })
        } else {
          history.push("/")    
        }
        break
      case "cancel":
        break
      default:
        history.push("/")  
        break
    }
  // eslint-disable-next-line
  }, [])

  return active ? <CenteredCircularProgress /> :
    <div className={classes.root}>
      {Boolean(error) ?  
        <Alert severity="error">
          <AlertTitle>
            Patvirtinimo klaida!
          </AlertTitle>
          Neteisingi duomenys arba užsakymo apmokėjimas jau patvirtintas
        </Alert> :
        state === "accept" ?
          paymentAckInfo(payAckStatus)
        : state === "cancel" ?
        <Alert severity="error">
          <AlertTitle>
            Apmokėjimas nutrauktas!
          </AlertTitle>
          Jūs atsisakėte apmokėjimo. Apmokėti galite vėliau ta pačia nuoroda.
        </Alert>
        : null}
    </div> 
}