import { combineReducers } from 'redux'
import { inventoryReducer } from './inventory-reducer'
import { userReducer } from './user-reducer'
import { UserAction } from './user-actions'
import { InventoryAction } from './inventory-actions'
import { ErrorAction } from './types'
import { errorReducer } from './error-reducer'
import { managementReducer } from './management-reducer'
import { ManagementAction } from './management-actions'


const rootReducer = combineReducers({
  inventory: inventoryReducer,
  user: userReducer,
  error: errorReducer,
  management: managementReducer,
})

export type RootAction = UserAction | InventoryAction | ErrorAction | ManagementAction
export type AppState = ReturnType<typeof rootReducer>

export default rootReducer