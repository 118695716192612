import React from 'react';
import { Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link as RouteLink, useHistory } from 'react-router-dom';
import SignIn from './user';
import { useGetAuthState, useOrderCount, makeUseStyles } from './common';
import { useLoginActions } from "../store/user-actions";
import { AppConfig } from '../store/types';
import { AppState } from '../store';
import { shallowEqual, useSelector } from 'react-redux';
import Popper from '@material-ui/core/Popper';


const usePopperStyles = makeUseStyles((theme: Theme) => (
  {
    popper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      zIndex: 100000,
      borderRadius: 10
    },
    
  }
))


const TelButton = ({ tel }: {tel: string}) => {
  const classes = usePopperStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl);
  const id = open ? 'tel-popper' : undefined;

  return (
    <div>
      <IconButton aria-describedby={id} edge="end" aria-label="show phone" onClick={handleClick} style={{color: "white"}}>
        <PhoneRoundedIcon />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper}>
        <a href={`tel:${tel}`} onClick={() => {setAnchorEl(null)}}>
          {tel}
        </a>
      </Popper>
    </div>
  )
}


const useStyles = makeUseStyles((theme: Theme) => (
    {
      container: {
        width: "100%",
        backgroundColor: '#000000',
      },
      grow: {
        maxWidth: 1000,
        margin: "0 auto"
      },
      root: {
        backgroundColor: '#000000',
        boxShadow: "none"
      },
      title: {
        flexGrow: 1,
      },
      account: {
        color: '#99ff99'
      },
      staffAccount: {
        color: '#acdb00'
      },
      logo: {
        height: 50,
        objectFit: "contain"
      },
      sectionMobile: {
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      sectionDisplay: {
        display: 'flex',
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
      navLink: {
        color: "white",
        cursor: "pointer"
      },
      separator: {
        fontSize: 10,
        marginLeft: 10,
        marginRight: 10
      }
    }
  )
)


export default function MyAppBar() {
  const [loginOpen, setLoginOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState<null | HTMLElement>(null)
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const classes = useStyles()
  const history = useHistory()
  const menuId = 'account-menu'
  const mobileMenuId = 'mobile-menu'
  const categoryMenuId = 'category-menu'
  const orderCount = useOrderCount()
  const authState = useGetAuthState()
  const { thunkLogout } = useLoginActions()

  let staffUser = authState.isStaff && authState.isActivated
  let loggedUser = authState.loggedIn && authState.isActivated

  const openLogin = () => {
    setLoginOpen(true)
  }

  const handleLoginClose = () => {
    setLoginOpen(false)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileAnchorEl(null)
  }

  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAccountMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setLoginOpen(false)
    setAnchorEl(null)
    thunkLogout()
  }

  const goto = (path: string) => {
    setLoginOpen(false)
    setAnchorEl(null)
    history.push(path)
  }

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.grow}>
          <AppBar position="relative" className={classes.root}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                <Button color="inherit" component={RouteLink} to="/">
                  <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="logo" className={classes.logo} />
                </Button>
              </Typography>

              <div className={classes.sectionDisplay}>
                <Link className={classes.navLink} onClick={() => {goto("/about")}} variant="h6">
                  Apie
                </Link>
                <FiberManualRecordIcon className={classes.separator}/>
                <Link
                  className={classes.navLink} 
                  onClick={() => {goto("/")}} 
                  variant="h6"
                  aria-label="show categories"
                  aria-controls={categoryMenuId}
                  aria-haspopup="true"
                >
                  Meniu
                </Link>
                <FiberManualRecordIcon className={classes.separator}/>
                <Link className={classes.navLink} onClick={() => {goto("/contacts")}} variant="h6">
                  Kontaktai
                </Link>
              </div>

              <TelButton tel={config.production.tel}/>
              
              <IconButton edge="end" aria-label="show shopping cart" color="inherit" onClick={() => {goto("/checkout")}}>
                <Badge overlap="rectangular" badgeContent={orderCount.count} color="secondary">
                  <ShoppingCartOutlined />
                </Badge>
              </IconButton>
              
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={loggedUser ? handleAccountMenuOpen : openLogin}
                color="inherit"
              >
                <AccountCircle className={staffUser ? classes.staffAccount : loggedUser ? classes.account : undefined} />
              </IconButton>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <SignIn open={loginOpen} handleLoginClose={handleLoginClose}/>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleAccountMenuClose}
          >
            {loggedUser ? 
            <div>
              {staffUser ? <MenuItem onClick={() => {goto("/management")}}>Valdymas</MenuItem> : null }
              <MenuItem divider={true} onClick={() => {goto("/profile")}}>Profilis</MenuItem>
              <MenuItem onClick={handleLogout}>Atsijungti</MenuItem>
            </div> : null}
          </Menu>
          <Menu
            anchorEl={mobileAnchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(mobileAnchorEl)}
            onClose={handleMobileMenuClose}
          > 
            <div>
              <MenuItem divider={true} onClick={() => {
                handleMobileMenuClose()
                goto("/about")
              }}>
                <Typography variant="h6" style={{fontWeight: 550}}>Apie</Typography>
              </MenuItem>
              <MenuItem divider={true} onClick={() => {
                handleMobileMenuClose()
                goto("/")
              }}>
                <Typography variant="h6" style={{fontWeight: 550}}>Meniu</Typography>
              </MenuItem>
              <MenuItem onClick={() => {
                handleMobileMenuClose()
                goto("/contacts")
              }}>
                <Typography variant="h6" style={{fontWeight: 550}}>Kontaktai</Typography>
              </MenuItem>
            </div>
          </Menu>
        </div>
      </div>
      <div style={{width: "100%", height: "7px", backgroundImage: "linear-gradient(#262626, white)"}}/>
    </div>
  )
}