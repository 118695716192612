import React, { useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { EditDialog, ConfirmDialog } from './dialogs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Grid from '@material-ui/core/Grid';
import { Theme, useTheme } from '@material-ui/core/styles';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Product, fn, voidFn, Category } from '../store/types';
import { useParams, useHistory } from 'react-router-dom';
import { makeUseStyles, useGetAuthState, useGetCategory, useGetProducts, useProductOrder } from './common';
import { AppState } from '../store';
import IconButton from '@material-ui/core/IconButton';
import { useInventoryActions } from '../store/inventory-actions';
import Button from '@material-ui/core/Button';
import { addError, ResponseError } from '../store/common';
import { PictureInput } from './inputs';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Link from '@material-ui/core/Link';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { addOrder, removeOrder } from '../store/user-actions';
import LazyImage from './image';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DoneIcon from '@material-ui/icons/Done';
import { useInView } from 'react-intersection-observer';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useMediaQuery } from '@material-ui/core';

interface NumberDialProps {
  value: number
  onDialChange: fn<number>
  minValue: number
  hardMin: number
  maxValue: number | undefined
}

export const NumberDial = ({ value, onDialChange, minValue, hardMin, maxValue }: NumberDialProps) => {
  const hardMax = maxValue || 9999

  const changeValue = (delta: number) => {
    let n = value + delta
    if (n < minValue) {
      n = delta > 0 ? minValue : hardMin
    }
    n = n > hardMax ? hardMax : n
    onDialChange(n)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      onDialChange(hardMin)
    } else {
      let n = Number(event.target.value)
      if (!isNaN(n) && n >= minValue && n <= hardMax) {
        onDialChange(n)
      }  
    }
  }

  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <IconButton disabled={value <= (hardMin || 0)} onClick={() => {
          changeValue(-1)
        }}>
        <RemoveCircleOutlineIcon />
      </IconButton>
      <TextField
        variant="outlined"
        onChange={handleChange}
        value={value}
        inputProps={{
          style: {
            margin: -10,
            width: 80,
            textAlign: "center"
          }
        }}
      />
      <IconButton disabled={value >= hardMax} onClick={() => {
          changeValue(1)
        }}>
        <AddCircleOutlineIcon/>
      </IconButton>
    </div>
  )
}

interface ChoicesProps {
  choices: string[]
  onChoiceChange: fn<string[]>
  choiceCount: number
}

const ChoicesView = ({ choices, onChoiceChange, choiceCount }: ChoicesProps) => {
  const [active, setActive] = React.useState<number[]>([])

  const changeValue = (index: number) => {
    let newActive = active.filter(v => v !== index)
    if (newActive.length === active.length) {
      if (newActive.length === choiceCount)
        newActive[newActive.length - 1] = index
      else
        newActive.push(index)
    }
    setActive(newActive.sort((e1, e2) => e1 - e2))
    onChoiceChange(newActive.map(idx => choices[idx]))
  }

  const getChoiceWord = () => {
    let word = "variantus"
    let h = choiceCount % 100
    if (h > 10 && h < 20)
      word = "variantų"
    else if ((choiceCount - 1) % 10 === 0)
      word = "variantą"
    return word  
  }

  return (
    Boolean(choices.length) ? <div style={{
      display: "flex",
      margin: 15,
      flexFlow: "column",
      justifyContent: "center"
    }}>
      {choiceCount ? <Typography style={{fontSize: 16, fontWeight: 600}}>Pasirinkite {choiceCount} {getChoiceWord()}</Typography> : null}
      <div style={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center"
      }}>
        {choices.map((value, index) => 
          <Chip
            key={index}
            onClick={() => changeValue(index)}
            icon={active.indexOf(index) > -1 ? <DoneIcon/> : null}
            label={value.split(";")[0]}
            size='small'
            color="primary"
            clickable
            variant={active.indexOf(index) > -1 ? 'default' : 'outlined'}
            style={{
              margin: 2
            }}
          />)}
      </div>
    </div> : null
  )
}


const useDetailCardStyles = makeUseStyles((theme: Theme) => (
  {
    container: {
      position: "relative",
      maxWidth: 500,
      minWidth: 320
    },
    card: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      padding: 20
    },
    actionContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    price: {
      fontWeight: "bold",
      fontSize: 16,
      padding: 5
    },
    fullPrice: {
      textDecoration: "line-through"
    },
    badge: {
      position: "relative",
      background: "orange",
      borderRadius: "50%",
      textAlign: "center",
      color: "white",
      fontWeight: "bold",
      fontSize: 15,
      transform: "translateY(-50%)",
    },
    activeCart: {
      backgroundColor: "#009933",
      color: "white",
      opacity: 0.7,
      padding: 5,
      margin: 10,
      '&:hover': {
        backgroundColor: "#009933",
        opacity: 1
      },
    },
    disabledCart: {
      backgroundColor: "#bbbbbb",
      color: "white",
      padding: 5,
      margin: 10,
    },
    link: {
      cursor: "pointer"
    },
    removeLink: {
      color: "red",
      cursor: "pointer"
    },
    spicy: {
      display: "flex",
      flexDirection: "row",
      marginTop: 5,
    }
  }
))

interface DetailCardProps { 
  product: Product
  onClose: voidFn
  onAdd: voidFn
}

export const ProductDetailCard = ({product, onClose, onAdd}: DetailCardProps) =>  {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useDetailCardStyles()
  const [choices, setChoices] = React.useState<string[]>([])
  const order = useProductOrder(product.id, choices.join('|'))
  const [amount, setAmount] = React.useState(0)

  const discountedPrice = (discounted: boolean = true) => {
    let coeffs:number[] = []
    choices?.forEach(ch => {
      let s = ch.split(";")
      let num = 1
      if (s.length === 2) {
        let cnum = parseFloat(s[1])
        if (!isNaN(cnum)) num = cnum
      }
      coeffs.push(num)
    })
    
    let coeff = coeffs.length ? coeffs.reduce((a, b) => a + b, 0) / coeffs.length : 1
    
    return Math.ceil(product.price * coeff * (1 - (discounted ? product.discount : 0) / 100)) / 100
  }

  const onDialChange = (amount: number) => {
    setAmount(amount)
  }

  const addToCart = () => {
    setAmount(0)
    let choice = choices.length ? choices.join("|") : undefined
    dispatch(addOrder({
      item: product,
      quantity: amount,
      choice
    }, true))
    onAdd()
  }

  return (
    <div className={classes.container}> 
      <Card 
        className={classes.card} 
        elevation={0}
      >
        <CardContent className={classes.content}>
          {Boolean(product.produce_period) ?
            <Alert severity="info" style={{maxWidth: 300, marginBottom: 10}}>
              Pagaminimo trukmė {Math.trunc(product.produce_period / 60) ? `${Math.trunc(product.produce_period / 60)} val.` : ""} {Boolean(product.produce_period % 60) ? `${product.produce_period % 60} min.` : ""}
            </Alert>
          : null}
          <div className={classes.actionContent}>
            <LazyImage src={product.picture} alt={product.name} maxWidth={300}/>
            {Boolean(product.discount) ? <Chip label={`${product.discount}%`} className={classes.badge}/> : null}
            {Boolean(product.spicy) ? 
              <div className={classes.spicy}>
                {Array.from(Array(product.spicy).keys()).map((_, idx) => <img key={idx} height={20} width={20} alt="red-pepper" src={`${process.env.PUBLIC_URL}/img/hot-pepper.png`}/>)}
              </div> : null}
            <ChoicesView choices={product.choices} onChoiceChange={setChoices} choiceCount={product.choice_count}/>
            <Typography variant="body2" color={product.discount ? "primary" : "textPrimary"} className={classes.price} component="div">
              {discountedPrice().toFixed(2)}&euro; {product.discount ? 
                <Typography className={classes.fullPrice} component="span" variant="body2" color="textSecondary">
                  {discountedPrice(false).toFixed(2)}&euro;
                </Typography> : null}
            </Typography>
            {!product.readonly ? 
              <React.Fragment>
                <NumberDial 
                  minValue={order ? order.order.quantity > product.quantity ? 1 : product.quantity - order.order.quantity : product.quantity} 
                  value={amount} 
                  onDialChange={onDialChange} 
                  hardMin={0} 
                  maxValue={product.max_quantity}
                />
                <Tooltip title={Boolean(amount) ? "Pridėti į krepšelį" : "Pasirinkite kiekį aukščiau"}>
                  <span>
                    <Fab size="medium" className={amount ? classes.activeCart : classes.disabledCart} onClick={addToCart} disabled={!amount || (choices.length !== product.choice_count)}>
                      <AddShoppingCartIcon />
                    </Fab>
                  </span>
                </Tooltip>
                <Typography variant="body1" component="div">
                  Pasirinkta {amount} vnt. už&nbsp;
                  <Typography variant="body1" color="primary" className={classes.price} component="span">
                    {(amount * discountedPrice()).toFixed(2)}&euro; 
                  </Typography>
                </Typography>
                <Typography variant="body1" style={{fontSize: 10}} component="div">
                  (mažiausias užsakymo kiekis yra {product.quantity} vnt.)
                </Typography>
              </React.Fragment> : null}
          </div>
          {order ?
          <div style={{marginTop: 10, maxWidth: 240}}>
            <Alert severity="success">
              Krepšelyje yra {order.order.quantity} vnt. šio produkto.
            </Alert>
            <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: "center", marginTop: 5}}>
              <Chip
                style={{
                  color: 'green',
                  margin: 2
                }}
                size="small"
                variant="outlined"
                label="Eiti į krepšelį"
                onClick={() => {
                  history.push("/checkout")
                  onClose()
                }}
              />
              <Chip
                style={{
                  margin: 2
                }}
                size="small"
                variant="outlined"
                color="secondary"
                label="Šalinti iš krepšelio"
                onClick={() => {
                  dispatch(removeOrder(order.idx))
                }}
              />
            </div>
          </div> : null}
        </CardContent>
        <CardContent style={{justifyContent: 'space-evenly'}}>
          <Typography variant="body1" component="p">
            Aprašymas
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" dangerouslySetInnerHTML={{__html: product.info}} />
        </CardContent>
        <CardContent style={{justifyContent: 'space-evenly'}}>
          {product.alergens ?
            <div>
              <Typography variant="body1" component="p">
                Alergenai
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {product.alergens}
              </Typography>
            </div>
          : null}
        </CardContent>
      </Card>
    </div>
  )
}


const useCardStyles = makeUseStyles((theme: Theme) => (
  {
    container: {
      position: "relative"
    },
    card: {
      marginTop: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      alignItems: "center",
      maxWidth: 600,
      backgroundColor: theme.palette.background.default,
    },
    action: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "zoom-in",
    },
    manageAction: {
      position: "absolute",
      top: 0,
      zIndex: 1,
      cursor: "pointer",
    },
    edit: {
      color: "green",
      backgroundColor: theme.palette.background.default,
      left: 0,
    },
    delete: {
      color: "red",
      backgroundColor: theme.palette.background.default,
      right: 0,
    },
    link: {
      cursor: "pointer",
    },
    badge: {
      position: "relative",
      background: "orange",
      borderRadius: "50%",
      textAlign: "center",
      color: "white",
      fontWeight: "bold",
      fontSize: 15,
      transform: "translateY(-70%)",
      marginBottom: -20
    },
    price: {
      fontWeight: "bold",
      fontSize: 18
    },
    fullPrice: {
      textDecoration: "line-through"
    },
    spicy: {
      display: "flex",
      flexDirection: "row",
      marginTop: 5,
    }
  }
))

interface CardProps { 
  product: Product
  openEditDialog: (title: string, data?: Product) => void
  openDetailDialog: (data: Product) => void
  openDeleteDialog: (id: number, name: string) => void
  enlargePicture: (src: string) => void
}

const ProductCard = ({product, openEditDialog, openDetailDialog, openDeleteDialog, enlargePicture}: CardProps) =>  {
  const authState = useGetAuthState()
  const classes = useCardStyles()

  const discountedPrice = () => (product.price / 100) * (1 - product.discount / 100)

  return (
    <div className={classes.container}>
      <Card 
        className={classes.card} 
        elevation={0}
      >
        <div className={classes.action} onClick={() => enlargePicture(product.picture)}>
          <LazyImage src={product.picture} alt={product.name} maxWidth={300}/>
          {Boolean(product.discount) ? <Chip label={`${product.discount}%`} className={classes.badge}/> : null}
          {Boolean(product.spicy) ? 
          <div className={classes.spicy}>
            {Array.from(Array(product.spicy).keys()).map((_, idx) => <img key={idx} height={20} width={20} alt="red-pepper" src={`${process.env.PUBLIC_URL}/img/hot-pepper.png`}/>)}
          </div> : null}
        </div>
        {authState.isAdmin || authState.isStaff ?
          (<EditIcon 
            className={`${classes.manageAction} ${classes.edit}`}
            onClick={(event) => {
              event.stopPropagation()
              openEditDialog('Edit Product', {...product})
            }}
          />) : null}
        {authState.isAdmin || authState.isStaff ?
          <DeleteIcon
            aria-label="remove"
            className={`${classes.manageAction} ${classes.delete}`}
            onClick={(event) => {
              event.stopPropagation()
              openDeleteDialog(product.id, product.name)
            }}
          /> : null}
        <CardContent style={{justifyContent: 'center'}}>
          {authState.isAdmin || authState.isStaff ? <Typography style={{fontSize: 12, fontWeight: 600}}>ID: {product.id}</Typography> : null}
          {(authState.isAdmin || authState.isStaff) && !product.active ? <Typography style={{fontSize: 10, color: "red", fontWeight: 600}}>NEAKTYVUS</Typography> : null}
          <Link
            className={classes.link}
            onClick={() => {openDetailDialog(product)}}
          >
            <Typography gutterBottom variant="h5" component="h2" style={{maxWidth: 400, margin: "0 auto"}}>
              {product.name}
            </Typography>
          </Link>
          {!product.readonly ? 
            <Chip
              style={{
                color: 'green',
                marginTop: 7,
                marginBottom: 15
              }}
              size="small"
              variant="outlined"
              label="Užsakyti"
              onClick={() => {openDetailDialog(product)}}
            />: null}
          <Typography variant="body2" color="textSecondary" component="p" dangerouslySetInnerHTML={{__html: product.info}} />
          <Typography variant="body2" color={product.discount ? "primary" : "textPrimary"} className={classes.price} component="p">
            {discountedPrice().toFixed(2)}&euro;
          </Typography>
          {product.discount ? 
          <Typography className={classes.fullPrice} component="span" variant="body2" color="textSecondary">
            {(product.price / 100).toFixed(2)}&euro;
          </Typography> : null}
        </CardContent>
      </Card>
    </div>
  )
}


const useProductGridStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: "100%",
      display: 'flex',
      flexDirection: "column",
      alignItems: "center",
    },
    newIcon: {
      fontSize: "30px"
    },
    formContainer: {
      margin: theme.spacing(4),
      maxWidth: 400,
    },
    detailContainer: {
      marginTop: -30,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      overflow: "auto",
    },
    backdropImage: {
      width: 500, 
      objectFit: 'contain', 
      cursor: "zoom-out",
      [theme.breakpoints.up(500)]: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }, 
    },
    alert: {
      position: "absolute",
      boxShadow: theme.shadows[5],
      width: 200,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    submit: {
      marginTop: 15
    }
  }
))


interface IProductGridProps { 
  catId: number
  subcategory?: string
}

const ProductsGrid = ({catId, subcategory}: IProductGridProps) => {
  const classes = useProductGridStyles()
  const authState = useGetAuthState()
  const { dispatch, thunkUpdateProduct, thunkRemoveProduct } = useInventoryActions()
  const products = useGetProducts(catId, subcategory)
  const categories = useSelector<AppState, Category[] | undefined>(state => state.inventory.categories, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.productError, shallowEqual)
  const [activeAction, setActiveAction] = React.useState(false)
  const [active, setActive] = React.useState(true)
  const [readonly, setReadonly] = React.useState(false)
  const [activeProduct, setActiveProduct] = React.useState<Product>()
  const [enlarged, setEnlarged] = React.useState<string>()
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState("")
  const [subCat, setSubCat] = React.useState("")
  const [activeId, setActiveId] = React.useState<number>()
  const [picture, setImage] = React.useState<string>()
  const [imageFile, setImageFile] = React.useState<File>()
  const [name, setName] = React.useState<string>("")
  const [info, setInfo] = React.useState<string>("")
  const [alergens, setAlergens] = React.useState<string>("")
  const [price, setPrice] = React.useState<number | string>("")
  const [quantity, setQuantity] = React.useState<number | string>("")
  const [maxQuantity, setMaxQuantity] = React.useState<number | string>("")
  const [discount, setDiscount] = React.useState<number | string>("")
  const [quantityDiscount, setQuantityDiscount] = React.useState<number | string>("")
  const [producePeriod, setProducePeriod] = React.useState<number | string>("")
  const [spicy, setSpicy] = React.useState<number | string>("")
  const [category, setCategory] = React.useState<number | string>(catId)
  const [choiceCount, setChoiceCount] = React.useState<number | string>("")
  const [order, setOrder] = React.useState<number | string>("")
  const [choices, setChoices] = React.useState<string>("")
  const [added, setAdded] = React.useState(false)

  const clearError = () => {
    dispatch(addError("productError", undefined))
  }

  const setDeleteDialogOpen = (id: number, name: string) => {
    setActiveId(id)
    setName(name)
    setDeleteOpen(true)
  }

  const setDeleteDialogClosed = () => {
    clearError()
    setDeleteOpen(false)
  }

  const deleteProduct = (cb: voidFn) => {
    if (!activeAction && activeId) {
      setActiveAction(true)
      thunkRemoveProduct(activeId, catId, () => {
        cb()
        setDeleteOpen(false)
        setActiveAction(false)
      }, () => {
        cb()
        setActiveAction(false)
      })
    }
  }

  const setEditDialogOpen = (title: string, data?: Product) => {
    setImageFile(undefined)
    setActiveId(data?.id)
    setImage(data?.picture)
    setName(data?.name || "")
    setInfo(data?.info || "")
    setAlergens(data?.alergens || "")
    setSubCat(data?.subcategory || subcategory || "")
    setPrice(data?.price || 0)
    setQuantity(data?.quantity || 1)
    setMaxQuantity(data?.max_quantity || "")
    setDiscount(data?.discount || 0)
    setQuantityDiscount(data?.quantity_discount || 0)
    setProducePeriod(data?.produce_period || 0)
    setSpicy(data?.spicy || 0)
    setOrder(data?.order || 0)
    setChoiceCount(data?.choice_count || 0)
    setCategory(data?.category || catId)
    setChoices(data?.choices.join(",") || "")
    setActive(data ? data.active : true)
    setReadonly(data ? data.readonly : false)
    setDialogTitle(title)
    setEditOpen(true)
  }

  const setEditDialogClosed = () => {
    clearError()
    setEditOpen(false)
  }

  const setDetailDialogOpen = (data: Product) => {
    setActiveProduct(data)
  }

  const setDetailDialogClosed = () => {
    setActiveProduct(undefined)
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!activeAction) {
      setActiveAction(true)
      const formData = new FormData();
      if (imageFile) 
        formData.append('picture', imageFile)
      formData.append('name', name)
      formData.append('info', info)
      formData.append('alergens', alergens)
      formData.append('price', String(price))
      formData.append('quantity', String(quantity))
      if (Boolean(String(maxQuantity)))
        formData.append('max_quantity', String(maxQuantity))
      formData.append('discount', String(discount))
      formData.append('quantity_discount', String(quantityDiscount))
      formData.append('produce_period', String(producePeriod))
      formData.append('spicy', String(spicy))
      formData.append('choice_count', String(choiceCount))
      formData.append('order', String(order))
      formData.append('subcategory', subCat)
      formData.append('category', String(category))
      formData.append('choices', JSON.stringify(Boolean(choices) ? choices.split(",") : []))
      formData.append('active', String(active))
      formData.append('readonly', String(readonly))
      thunkUpdateProduct(formData, catId, activeId, () => {
        setActiveAction(false)
        setEditOpen(false)
      }, () => {
        setActiveAction(false)
      })
    }
  }

  const handlePictureChange = (file: File) => {
    clearError()
    setImageFile(file)
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setName(event.target.value)
  }

  const handleSubCatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setSubCat(event.target.value)
  }

  const handleInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setInfo(event.target.value)
  }

  const handleAlergensChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setAlergens(event.target.value)
  }

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = parseFloat(event.target.value)
    setPrice(isNaN(num) ? "" : num)
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setQuantity(isNaN(num) ? "" : num)
  }

  const handleMaxQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setMaxQuantity(isNaN(num) ? "" : num)
  }

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setDiscount(isNaN(num) ? "" : num)
  }

  const handleQuantityDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setQuantityDiscount(isNaN(num) ? "" : num)
  }

  const handleProducePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setProducePeriod(isNaN(num) ? "" : num)
  }

  const handleSpicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setSpicy(isNaN(num) ? "" : num)
  }

  const handleChoiceCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setChoiceCount(isNaN(num) ? "" : num)
  }

  const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setOrder(isNaN(num) ? "" : num)
  }

  const handleChoices = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoices(event.target.value)
  }

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    clearError()
    setCategory((event.target as HTMLInputElement).value)
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked)
  }

  const handleReadonlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReadonly(event.target.checked)
  }

  return (
    <div className={classes.root} >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            products?.map((value, index) => (
              <div key={index}>
                <ProductCard 
                  product={value} 
                  openEditDialog={setEditDialogOpen} 
                  openDetailDialog={setDetailDialogOpen}
                  openDeleteDialog={setDeleteDialogOpen}
                  enlargePicture={(src: string) => setEnlarged(src)}
                />
              </div>
            ))
          }
        </Grid>
      </Grid>
      {authState.isAdmin || authState.isStaff ?
        <IconButton
            onClick={() => {setEditDialogOpen("New Product")}}
          >
            <AddCircleOutlineOutlinedIcon className={classes.newIcon}/>
        </IconButton> 
      : null}
      <EditDialog 
        title={activeProduct?.name} 
        open={Boolean(activeProduct)}
        containerClass={classes.detailContainer}
        handleClose={setDetailDialogClosed}
      >
        {activeProduct ? 
        <ProductDetailCard 
          product={activeProduct} 
          onClose={setDetailDialogClosed}
          onAdd={() => {
            setDetailDialogClosed()
            setAdded(true)
            setTimeout(() => {
              setAdded(false)
            }, 1000)
          }}
        /> : null}
      </EditDialog>
      <EditDialog 
        title={dialogTitle} 
        open={editOpen}
        containerClass={classes.formContainer}
        handleClose={setEditDialogClosed}
        error={error}
      >
        <form className={classes.form} id='product-form' onSubmit={submit} noValidate>
          <InputLabel htmlFor="product-picture">Product picture</InputLabel>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.picture)}>
            <PictureInput id="product-picture" height={200} maxWidth={320} image={picture} onChange={handlePictureChange}/> 
            <FormHelperText>{error?.response?.picture?.join(' ')}</FormHelperText>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleNameChange}
            id="product-name"
            label="Vardas"
            name="name"
            value={name}
            autoFocus
            error={Boolean(error?.response?.name)}
            helperText={error?.response?.name?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            multiline={true}
            rows={3}
            required
            fullWidth
            onChange={handleInfoChange}
            name="info"
            label="Aprašymas"
            id="product-info"
            value={info}
            error={Boolean(error?.response?.info)}
            helperText={error?.response?.info?.join(' ')}
          />
          <FormControl fullWidth required margin="normal" error={Boolean(error?.response?.category)}>
            <InputLabel htmlFor="product-category">Kategorija</InputLabel>
            <NativeSelect 
              id="product-category"
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="" disabled></option>
              {categories?.map((value, idx) => <option key={idx} value={value.id}>{`${value.name} (${value.id})`}</option>)}
            </NativeSelect>
            <FormHelperText>{error?.response?.category}</FormHelperText>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleSubCatChange}
            name="subcategory"
            label="Kategorijos grupė"
            id="product-subcategory"
            value={subCat}
            error={Boolean(error?.response?.subcategory)}
            helperText={error?.response?.subcategory?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            multiline={true}
            rows={3}
            fullWidth
            onChange={handleAlergensChange}
            name="alergens"
            label="Alergenai"
            id="product-alergens"
            value={alergens}
            error={Boolean(error?.response?.alergens)}
            helperText={error?.response?.alergens?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleChoices}
            name="choices"
            label="Pasirinkimai"
            id="product-choices"
            value={choices}
            error={Boolean(error?.response?.choices)}
            helperText={error?.response?.choices?.join(' ')}
          />
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.choice_count)}>
            <InputLabel htmlFor="product-quantity">Privalomas pasirinkimų skaičius</InputLabel>
            <Input
              onChange={handleChoiceCountChange}
              name="choice-count"
              type="number"
              id="product-choice-count"
              value={choiceCount}
              inputProps={{
                min: "0"
              }}
            />
            <FormHelperText>{error?.response?.choice_count?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.price)}>
            <InputLabel htmlFor="product-price">Kaina (centais)</InputLabel>
            <Input
              required
              onChange={handlePriceChange}
              name="price"
              type="number"
              id="product-price"
              value={price}
              inputProps={{
                min: "0"
              }}
            />
            <FormHelperText>{error?.response?.price?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.quantity)}>
            <InputLabel htmlFor="product-quantity">Minimalus užsakymo kiekis</InputLabel>
            <Input
              required
              onChange={handleQuantityChange}
              name="quantity"
              type="number"
              id="product-quantity"
              value={quantity}
              inputProps={{
                min: "1"
              }}
            />
            <FormHelperText>{error?.response?.quantity?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.max_quantity)}>
            <InputLabel htmlFor="product-max-quantity">Maksimalus užsakymo kiekis</InputLabel>
            <Input
              onChange={handleMaxQuantityChange}
              name="max-quantity"
              type="number"
              id="product-max-quantity"
              value={maxQuantity}
              inputProps={{
                min: "0"
              }}
            />
            <FormHelperText>{error?.response?.max_quantity?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.discount)}>
            <InputLabel htmlFor="product-quantity">Nuolaida (%)</InputLabel>
            <Input
              required
              onChange={handleDiscountChange}
              name="discount"
              type="number"
              id="product-discount"
              value={discount}
              inputProps={{
                min: "0",
                max: "100"
              }}
            />
            <FormHelperText>{error?.response?.discount?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.quantity_discount)}>
            <InputLabel htmlFor="product-quantity-discount">Kiekio nuolaida (%)</InputLabel>
            <Input
              required
              onChange={handleQuantityDiscountChange}
              name="quantity_discount"
              type="number"
              id="product-quantity-discount"
              value={quantityDiscount}
              inputProps={{
                min: "0",
                max: "100"
              }}
            />
            <FormHelperText>{error?.response?.quantity_discount?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.produce_period)}>
            <InputLabel htmlFor="product-produce-period">Paruošimo laikas (minutės)</InputLabel>
            <Input
              required
              onChange={handleProducePeriodChange}
              name="produce-period"
              type="number"
              id="product-produce-period"
              value={producePeriod}
              inputProps={{
                min: "0"
              }}
            />
            <FormHelperText>{error?.response?.produce_period?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.spicy)}>
            <InputLabel htmlFor="product-produce-period">Aštrumas</InputLabel>
            <Input
              required
              onChange={handleSpicyChange}
              name="spicy"
              type="number"
              id="product-spicy"
              value={spicy}
              inputProps={{
                min: "0",
                max: "3"
              }}
            />
            <FormHelperText>{error?.response?.spicy?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.order)}>
            <InputLabel htmlFor="product-quantity-discount">Vieta eilėje</InputLabel>
            <Input
              required
              onChange={handleOrderChange}
              name="order"
              type="number"
              id="product-order"
              value={order}
            />
            <FormHelperText>{error?.response?.order?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={Boolean(error?.response?.active)}>
            <FormControlLabel
              control={<Checkbox checked={active} color="primary" onChange={handleActiveChange} name="active" />}
              label="Aktyvus"
            />
            <FormHelperText>{error?.response?.active?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth error={Boolean(error?.response?.readonly)}>
            <FormControlLabel
              control={<Checkbox checked={readonly} color="primary" onChange={handleReadonlyChange} name="readonly" />}
              label="Tik skaitymui"
            />
            <FormHelperText>{error?.response?.readonly?.join(' ')}</FormHelperText>
          </FormControl>
          <Button
            type="submit"
            form='product-form'
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={activeAction}
          >
            {activeAction ? <CircularProgress size={25} /> : 'Save'}
          </Button>
        </form>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst="delete me"
        handleClose={setDeleteDialogClosed}
        onConfirm={deleteProduct}
        error={error}
      >
        You are planning to delete product {name}. Type <strong>delete me</strong> and confirm to delete
      </ConfirmDialog>
      <Modal 
        disableAutoFocus
        disableEnforceFocus
        className={classes.backdrop} 
        open={Boolean(enlarged)} 
        onClick={() => {setEnlarged(undefined)}}
      >
        <img
          alt="Large view"
          src={enlarged}
          className={classes.backdropImage}
          onClick={() => {setEnlarged(undefined)}}
        />
      </Modal>
      <Modal
        disableAutoFocus
        disableEnforceFocus
        open={added}
        hideBackdrop={true}
      >
        <div className={classes.alert}>
          <Alert severity="success">
            Įdėta į krepšelį!
          </Alert>
        </div>
      </Modal>
    </div>
  )
}


const useSubCatGridStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: "100%",
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
    },
    row: {
      width: "100%",
    },
    heading: {
      fontSize: 30,
      marginTop: 15,
    },
    description: {
      fontSize: 16,
      marginTop: 20,
      marginBottom: 20,
      padding: 5,
      maxWidth: 700,
      textAlign: "center"
    },
    newIcon: {
      fontSize: "50px"
    },
    edit: {
      color: 'green',
      fontSize: 20
    },
    delete: {
      color: 'red',
      fontSize: 20
    },
    formContainer: {
      margin: theme.spacing(4),
      maxWidth: 300,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    link: {
      cursor: "pointer",
      color: "grey",
      fontSize: 15
    },
    breadcrumbs: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.default,
      maxWidth: 700,
      padding: 5
    },
    navigation: {
      position: 'fixed',
      top: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 2
    }
  }
))

interface RefList {
  [key: number]: HTMLElement | null
}

interface ISubCatGridProps { 
  catId: number
}

const SubCatGrid = ({catId}: ISubCatGridProps) => {
  const classes = useSubCatGridStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const category = useGetCategory(catId)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.categoryError, shallowEqual)
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const subCatRefs: RefList = {}
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const scrollToRef = (ref: HTMLElement | null) => {
    if (ref !== null) {
      ref.scrollIntoView({ behavior: 'auto', block: 'start' })
      window.scrollBy(0,-50)
    }
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const navigate = (idx: number) => {
    scrollToRef(subCatRefs[idx])
    handleMenuClose()
  }

  return (
    <div className={classes.root}>
      {category?.sub.length > 0 ? 
        <React.Fragment>
          <div ref={ref} className={classes.breadcrumbs} aria-label="subcategories-breadcrumbs">
            {category?.sub.map((value, index) => (
              <React.Fragment key={index}>
                <Link onClick={() => {scrollToRef(subCatRefs[index])}} className={classes.link}>
                  {value}
                </Link>
                {index !== category.sub.length - 1 ? <span style={{margin: 5}}>&#183;</span> : null}
              </React.Fragment>
            ))}
          </div>
          {!inView ? 
            <Fab color="default" size="small" className={classes.navigation} aria-label="navigate to category" onClick={handleMenuOpen}>
              {Boolean(anchorEl) ? <ExpandMoreIcon/> : <NavigateBeforeIcon />}
            </Fab>
          : null}
        </React.Fragment>
      : null}

      {Boolean(error) ? 
        <Alert severity="error">
          Produktai neprieinami. Atsiprašome už nepatogumus
        </Alert>
      : null}
      {Boolean(category?.description) ? <Typography align="center" className={classes.description}>{category.description}</Typography> : null}
      <div>
        <div className={classes.row}>
          <ProductsGrid catId={catId} subcategory={undefined} />
        </div>
      </div>
      {
        category?.sub.map((value, index) => {
          return <div 
            ref={ref => subCatRefs[index] = ref}
            key={index}
          >
            <Typography variant="h1" component="h2" align="center" className={classes.heading}>
              {value}
            </Typography>
            <div className={classes.row}>
              <ProductsGrid catId={catId} subcategory={value} />
            </div>
          </div>
        })
      }
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="category-navigation"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <div>
          {category?.sub.map((value, index) => (
            <MenuItem key={index} onClick={() => navigate(index)}>{value}</MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  )
}

const Products = () => {
  const { id } = useParams<{ id: string }>()
  const [catId, setCatId] = React.useState<number | undefined>()
  
  useEffect(() => {
    try {
      let num = Number(id)
      setCatId(num)
    } catch (err) {  
    }
  // eslint-disable-next-line
  }, [id])

  
  return typeof catId === "number" ? <SubCatGrid catId={catId}/> : null
}

export default Products
