import { 
  ManagementAction,
  SET_USERS,
  ADD_USER,
  RM_USER,
  SET_SOC_APPS,
  ADD_SOC_APP,
  RM_SOC_APP,
  SET_ORDERS,
  SET_ORDER,
  UPDATE_ORDER,
  RM_ORDER,
  CLEAN_ORDERS,
  SET_TIMED_CONF,
  ADD_TIMED_CONF,
  RM_TIMED_CONF,
  SET_DISCOUNT_CODE,
  ADD_DISCOUNT_CODE,
  RM_DISCOUNT_CODE
} from './management-actions'
import { ManagementState, IUser, ISocialApp, ITimedConfig, IDiscountCode } from './types'

const initialManagementState: ManagementState = {
  orders: new Map(),
  order_map: new Map()
}

export function managementReducer(
  state = initialManagementState,
  action: ManagementAction
): ManagementState {
  switch (action.type) {
    case SET_USERS:
      return {...state, users: action.payload}
    case ADD_USER: {
      let pushed = false
      let newList:IUser[] = [] 
      state.users?.forEach(element => {
        if (element.id === action.id) {
          newList.push(action.payload)
          pushed = true
        } else
          newList.push(element)
      })  
      if (!pushed) newList.push(action.payload)
      return {
        ...state,
        users: newList
      }
    }
    case RM_USER: {
      return {
        ...state,
        users: state.users?.filter(e => e.id !== action.id)
      }
    }
    case SET_SOC_APPS:
      return {...state, socapps: action.payload}
    case ADD_SOC_APP: {
      let pushed = false
      let newList:ISocialApp[] = [] 
      state.socapps?.forEach(element => {
        if (element.id === action.id) {
          newList.push(action.payload)
          pushed = true
        } else
          newList.push(element)
      })  
      if (!pushed) newList.push(action.payload)
      return {
        ...state,
        socapps: newList
      }
    }
    case RM_SOC_APP: {
      return {
        ...state,
        socapps: state.socapps?.filter(e => e.id !== action.id)
      }
    }
    case SET_TIMED_CONF:
      return {...state, timedconf: action.payload.map(v => {
        return {
          ...v, 
          date_from: new Date(v.date_from), 
          date_to: new Date(v.date_to)
        }
      })}
    case ADD_TIMED_CONF: {
      let pushed = false
      let newList:ITimedConfig[] = [] 
      state.timedconf?.forEach(element => {
        if (element.id === action.id) {
          newList.push({
            ...action.payload,
            date_from: new Date(action.payload.date_from), 
            date_to: new Date(action.payload.date_to)
          })
          pushed = true
        } else
          newList.push(element)
      })  
      if (!pushed) newList.push(action.payload)
      return {
        ...state,
        timedconf: newList
      }
    }
    case RM_TIMED_CONF: {
      return {
        ...state,
        timedconf: state.timedconf?.filter(e => e.id !== action.id)
      }
    }
    case SET_DISCOUNT_CODE:
      return {...state, discount_codes: action.payload.map(v => {
        return {
          ...v, 
          expiration_date: new Date(v.expiration_date)
        }
      })}
    case ADD_DISCOUNT_CODE: {
      let pushed = false
      let newList:IDiscountCode[] = [] 
      state.discount_codes?.forEach(element => {
        if (element.id === action.id) {
          newList.push({
            ...action.payload,
            expiration_date: new Date(action.payload.expiration_date)
          })
          pushed = true
        } else
          newList.push(element)
      })  
      if (!pushed) newList.push(action.payload)
      return {
        ...state,
        discount_codes: newList
      }
    }
    case RM_DISCOUNT_CODE: {
      return {
        ...state,
        discount_codes: state.discount_codes?.filter(e => e.id !== action.id)
      }
    }
    case SET_ORDER: {
      let order_map = new Map(state.order_map)
      action.payload.delivery_date = new Date(action.payload.delivery_date)
      action.payload.order_date = new Date(action.payload.order_date)
      order_map.set(action.payload.id, action.payload)
      return {...state, order_map}
    }
    case SET_ORDERS: {
      let orders = new Map(state.orders)
      let order_map = new Map(state.order_map)
      let prevOrders = orders.get(action.status)
      action.payload.orders = action.payload.orders.map(o => {
        o.delivery_date = new Date(o.delivery_date)
        o.order_date = new Date(o.order_date)
        order_map.set(o.id, o)
        return o
      })
      if (prevOrders)
        action.payload = {...action.payload, orders: [...prevOrders.orders, ...action.payload.orders]}
      orders.set(action.status, action.payload)
      return {...state, orders, order_map}
    }
    case CLEAN_ORDERS: {
      let orders = state.orders.get(action.status)
      let order_map = new Map(state.order_map)
      orders?.orders.forEach(o => {
        order_map.delete(o.id)
      })
      state.orders.delete(action.status)
      return {...state, orders: new Map(state.orders), order_map}
    }
    case UPDATE_ORDER: {
      /** Remove from previous status */
      let newOrders = new Map(state.orders)
      let order_map = new Map(state.order_map)
      if (action.status !== action.payload.status) {
        let orders = newOrders.get(action.status)
        newOrders.set(action.status, {
          ...orders,
          count: orders.count - 1,
          orders: orders?.orders.filter(o => o.id !== action.id)
        })
        let po = newOrders.get(action.payload.status)
        po?.orders.forEach(o => {
          order_map.delete(o.id)
        })
        newOrders.delete(action.payload.status)
      } 
      else {
        let po = newOrders.get(action.status)
        po?.orders.forEach(o => {
          order_map.delete(o.id)
        })
        newOrders.delete(action.status)
      }
      return {...state, orders: newOrders, order_map}
    }
    case RM_ORDER: {
      let orders = state.orders.get(action.status)
      let order_map = new Map(state.order_map)
      state.orders.set(action.status, {
        ...orders,
        count: orders.count - 1,
        orders: orders?.orders.filter(o => {
          let c = o.id !== action.id
          if (!c) order_map.delete(o.id)
          return c
        })
      })
      return {...state, order_map}
    }
    default:
      return state
  }
}
