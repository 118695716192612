import React from 'react'
import { useInView } from 'react-intersection-observer'

const LazyImage = ({ maxWidth, alt, src, ...rest }) => {
  const [opacity, setOpacity] = React.useState(0.2)
  const [ref, inView] = useInView({
    triggerOnce: true,
  })
  
  const onLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (!event.currentTarget.src.endsWith('/img/logo.png')) setOpacity(1)
    else setOpacity(0.2)
  }

  let show = inView && src

  return (
    <div style={{maxWidth}}>
      <img 
        ref={ref}
        {...rest}
        alt={alt}
        src={show ? src : `${process.env.PUBLIC_URL}/img/logo.png`}
        onLoad={onLoad}
        style={{width: "100%", opacity: opacity, objectFit: 'contain'}} 
      />
    </div>
  )
}
 
export default LazyImage