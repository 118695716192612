import React, { useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useGetAuthState, makeUseStyles } from './common';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { OrderData, Profile, voidFn, AppConfig } from '../store/types';
import { AppState } from '../store';
import Alert from '@material-ui/lab/Alert';
import { ResponseError, addError, grecaptchaRequest } from '../store/common';
import { AlertDialogContent, ConfirmDialog } from './dialogs';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { useLoginActions, addProfile } from '../store/user-actions';
import Collapse from '@material-ui/core/Collapse';
import { CLEAN_ORDERS, useManagementActions } from '../store/management-actions';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import LoopIcon from '@material-ui/icons/Loop';
import { InView } from 'react-intersection-observer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { OrderDialog } from './order';


const useListStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.default,
      marginBottom: 20,
      // margin: theme.spacing(4),
      maxWidth: 200,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    }
  })
)


interface ChangePasswordProps {
  profile: Profile
}

const ChangePassForm = ( { profile }: ChangePasswordProps ) => {
  const classes = useListStyles()
  const [open, setOpen] = React.useState(false)
  const [activeAction, setActiveAction] = React.useState(false)
  const [password, setPassword] = React.useState<string>("")
  const [oldPassword, setOldPassword] = React.useState<string>("")
  const [confirmPassword, setConfirmPassword] = React.useState<string>("")
  const { dispatch, thunkLogout, thunkUpdateProfile } = useLoginActions()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.profileError, shallowEqual)

  const cleanError = () => {
    dispatch(addError("profileError", undefined))
  }

  useEffect(() => {
    return cleanError
  // eslint-disable-next-line
  }, [])

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!activeAction) {
      setActiveAction(true)
      grecaptchaRequest('update', (token) => {
        let payload = {
          old_password: oldPassword, 
          password: password, 
          confirm_password: confirmPassword, 
          ctoken: token
        }
        thunkUpdateProfile(payload, () => {
          setActiveAction(false)
          setOpen(true)
        }, () => {
          setActiveAction(false)
          setOldPassword("")
          setPassword("")
          setConfirmPassword("")
        })
      }, (err) => {
        dispatch(addError("profileError", new ResponseError({detail: 'Slaptažodžio pakeisti nepavyko'}, 400)))
        setActiveAction(false)
        console.log(err)
      })
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setConfirmPassword(event.target.value)
  }

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    cleanError()
    setOldPassword(event.target.value)
  }

  const handleClose = () => {
    thunkLogout()
    setOpen(false)
  }

  const passNotOK = Boolean(password) && password !== confirmPassword

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(Boolean(error?.response?.detail))}>
        <Alert severity="error">{error?.response?.detail}</Alert>
      </Collapse>
      <form className={classes.form} id='pass-change-form' onSubmit={onSubmit} noValidate>  
        {profile.usable_passw ? 
          <TextField
            // variant="outlined"
            margin="normal"
            required
            fullWidth
            value={oldPassword}
            onChange={handleOldPasswordChange}
            name="old_password"
            label="Senasis slaptažodis"
            type="password"
            autoComplete="password"
            error={Boolean(error?.response?.old_password)}
            helperText={error?.response?.old_password?.join(' ')}
          /> : null}
        <TextField
          // variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          name="password"
          label="Naujas slaptažodis"
          type="password"
          autoComplete="current-password"
          error={Boolean(error?.response?.password)}
          helperText={error?.response?.password?.join(' ')}
        />
        <TextField
          // variant="outlined"
          margin="normal"
          required
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          name="confirm_password"
          label="Pakartoti naują slaptažodį"
          type="password"
          autoComplete="confirm-password"
          error={passNotOK}
          helperText={passNotOK ? 'Must match the password above' : undefined}
        />
        <Button
          fullWidth
          type="submit"
          form="pass-change-form"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={activeAction || (profile.usable_passw && !oldPassword) || !password || password !== confirmPassword}
        >
          {activeAction ? <CircularProgress size={25} /> : 'Pakeisti'}
        </Button>
      </form>
      <Dialog open={open}>
        <AlertDialogContent handleClose={handleClose}>
          Slaptažodis sėkmingai atnaujintas. Prisijungkite iš naujo!
        </AlertDialogContent>
      </Dialog>
    </div>
  )
}

const useOrderPanelStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: '100%',
      // backgroundColor: theme.palette.background.paper,
    },
    list: {
      maxWidth: 300,
      margin: "0 auto"
    },
    link: {
      cursor: "pointer"
    },
    edit: {
      color: 'green',
      fontSize: 20
    },
    delete: {
      color: 'red',
      fontSize: 20
    },
    formContainer: {
      margin: theme.spacing(4),
      maxWidth: 500,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    subhead: {
      position: "relative",
      fontWeight: "bold",
      color: "#000000",
      width: "100%"
    },
    rotation: {
      animation: "$rotation 2s infinite linear"
    },
    "@keyframes rotation": {
      "from": {
        transform: "rotate(0deg)"
      },
      "to": {
        transform: "rotate(359deg)"
      }
    }
  })
)

const OrderPanel = () => {
  
  const classes = useOrderPanelStyles()
  const { dispatch, thunkGetOrders } = useManagementActions()
  const [reloadActive, setReloadActive] = React.useState(false)
  const [activeOrder, setActiveOrder] = React.useState<OrderData>()
  const [showOpen, setShowOpen] = React.useState(false)
  const config = useSelector<AppState, AppConfig | undefined>(state => state.user.config, shallowEqual)
  const orders = useSelector<AppState, {next: number, count: number, orders: OrderData[]}>(state => state.management.orders.get(0))
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.orderError, shallowEqual)

  const finishReload = () => {
    setReloadActive(false)
  }

  const openShowDialog = (order: OrderData) => {
    setActiveOrder(order)
    setShowOpen(true)
  }

  const handleShowClose = () => {
    cleanError()
    setShowOpen(false)
    setActiveOrder(undefined)
  }

  useEffect(() => {
    if (typeof orders === "undefined") {
      setReloadActive(true)
      thunkGetOrders(1, undefined, true, (data) => {
        finishReload()
      }, finishReload)
    }
    
    return cleanError
  // eslint-disable-next-line
  }, [orders])

  const cleanError = () => {
    dispatch(addError("orderError", undefined))
  }

  const reloadOrders = () => {
    dispatch({type: CLEAN_ORDERS, status: 0})
  }

  const loadMore = (next: number | undefined) => {
    if (next) thunkGetOrders(next, undefined)
  }

  const getDeliveryPrice = (city: string) => {
    for (let p of config.delivery_map) {
      if (p.option === city)
        return p.fee
    }
    return 0
  }

  const totalPrice = (orders: {price?: number, item: number, quantity: number}[], discount: number, city: string) => {
    let total = Math.ceil(getDeliveryPrice(city) * (1 - discount / 100))
    orders.forEach(o => {
      if (o.price) {
        total += o.quantity * Math.ceil(o.price * (1 - discount / 100))
      }
    })
    return (total / 100).toFixed(2)
  }

  const statusChip = (status: number) => {
    let color = ""
    let label = ""
    switch (status) {
      case 1:
        label = 'NAUJAS'
        color = '#ffa64d'
        break
      case 2:
        label = 'PATVIRTINTAS'
        color = '#1a75ff'
        break
      case 3:
        label = 'ATMESTAS'
        color = '#ff5c33'
        break
      case 4:
        label = 'PRISTATOMAS'
        color = '#e6e600'
        break
      case 5:
        label = 'BAIGTAS'
        color = '#00b300'
        break
      default:
        break;
    }
    return <Chip size="small" label={label} style={{color: "white", backgroundColor: `${color}`}}/>
  }

  const deliveryDateView = (order: OrderData) => {
    if (order.delivery_date) {
      let hour = order.delivery_date.getHours() * 60 + order.delivery_date.getMinutes()
      let q = Math.floor(hour / 60)
      let m = hour % 60
      let eq = Math.floor((hour + order.interval) / 60)
      let em = (hour + order.interval) % 60
      let month = order.delivery_date.getMonth() + 1
      let day = order.delivery_date.getDate()
      let dateString = `${order.delivery_date.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}` 
      return <span>{dateString} {`${q > 9 ? "" : '0'}${q}:${m > 9 ? "" : '0'}${m}`}&ndash;{`${eq > 9 ? "" : '0'}${eq}:${em > 9 ? "" : '0'}${em}`}</span> 
    }
    return null;
  }

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(error?.response?.detail)}>
        <Alert severity={"error"}>
          {error?.response?.detail}
        </Alert>
      </Collapse>
      <List
        className={classes.list}
        component="div"
        aria-labelledby={`profile-order-list-subheader`}
        subheader={
          <ListSubheader component="div" className={classes.subhead} id={`profile-order-list-subheader`}>
            Perkrauti
            <IconButton edge="end" aria-label="reload" onClick={reloadOrders} disabled={reloadActive}>
              <LoopIcon fontSize="inherit" className={reloadActive ? classes.rotation : undefined}/>
            </IconButton> 
          </ListSubheader>
        }
      >
        {orders?.orders?.sort((o1, o2) => o2.order_date.getTime() - o1.order_date.getTime()).map((value: OrderData, idx: number) => {
          return (
            <InView key={idx} as="div" onChange={(inView, _) => {if (inView) loadMore(orders.next)}} skip={idx + 1 < orders.orders.length} triggerOnce={true} initialInView={false}>
              <ListItem style={{display: "flex", flexFlow: "row wrap", alignItems: "flex-start"}}>
                <ListItemText 
                  primary={
                    <span style={{padding: 5}}>
                      Nr.:&nbsp;
                      <Link className={classes.link} onClick={() => openShowDialog(value)}>
                        {value.id.substr(0, 6)}
                      </Link>
                      &nbsp;&nbsp;
                      {statusChip(value.status)}
                    </span>
                  }
                  secondary={
                    <React.Fragment>
                      <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}>Užsakymo data: {value.order_date?.toLocaleString('lt-LT')}</span>
                      <span style={{display: "block", padding: 7, borderBottom: "1px solid grey"}}>Pageidaujamas laikas: {deliveryDateView(value)}</span>
                      <span style={{display: "block", padding: 7}}>Suma: <b>{totalPrice(value.orders, value.discount, value.city)}&euro;</b>&nbsp;</span>
                    </React.Fragment>
                  }
                />
              </ListItem>
            </InView>
          )
        })}
      </List>
      <OrderDialog order={activeOrder} open={showOpen} close={handleShowClose}/>
    </div>
  )
}


const DeleteProfileView = () => {
  const [open, setOpen] = React.useState(false)
  const [activeAction, setActiveAction] = React.useState(false)
  const { dispatch, thunkDeleteProfile } = useLoginActions()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.profileError, shallowEqual)

  const cleanError = () => {
    dispatch(addError("profileError", undefined))
  }

  useEffect(() => {
    return cleanError
  // eslint-disable-next-line
  }, [])

  const onConfirm = (cb?: voidFn) => {
    setOpen(false)
    if (cb) cb()
    if (!activeAction) {
      setActiveAction(true)
      grecaptchaRequest('delete', (token) => {
        thunkDeleteProfile(token, () => {
          dispatch(addProfile(undefined))
        }, () => {
          setActiveAction(false)
        })
      }, (err) => {
        dispatch(addError("profileError", new ResponseError({detail: 'Vartotojo ištrinit nepavyko'}, 400)))
        setActiveAction(false)
        console.log(err)
      })
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div style={{display: "flex", maxWidth: 500, flexDirection: "column", alignItems: "center"}}>
      {Boolean(error) ? 
        <Alert severity="error">{error?.response?.detail}</Alert>
      : null}
      
      <Button
        style={{
          marginTop: 15
        }}
        variant="contained"
        color="secondary"
        onClick={() => {
          cleanError()
          setOpen(true)
        }}
      >
        {activeAction ? <CircularProgress size={25} /> : 'Ištrinti Profilį'}
      </Button>
      <ConfirmDialog 
        open={open}
        title='Perspėjimas'
        confirmAgainst='delete me'
        handleClose={handleClose}
        onConfirm={onConfirm}
        error={error}
      >
        Jūs norite ištrinti savo profilį. Įveskite <strong>delete me</strong> ir spauskite <em>Patvirtinti</em>
      </ConfirmDialog>
    </div>
  )
}


const useStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      width: '100%',
      marginTop: 20
    },
    background: {
      backgroundColor: theme.palette.background.default
    },
    info: {
      marginLeft: 10,
      marginTop: 30,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '100%',
      flexShrink: 0,
    },
    details: {
      flexDirection: "column",
      alignItems: 'center',
    },
    error: {
      width: '100%'
    },
  }),
)

export default function ProfileConsole() {
  const classes = useStyles()
  const authState = useGetAuthState()
  const profile = useSelector<AppState, Profile | undefined>(state => state.user.profile, shallowEqual)
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  // useEffect(() => {
  // // eslint-disable-next-line
  // }, [])

  return (authState.loggedIn && profile ? 
    <div className={classes.root}>
      <Typography variant="h4" className={classes.info} paragraph={true}>Labas, {profile.username}</Typography>
      <Accordion elevation={0} expanded={expanded === 'change-pass'} onChange={handleChange('change-pass')} className={classes.background}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="change-password-content"
          id="change-pass-header"
        >
          <Typography className={classes.heading}>Pakeisti slaptažodį</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <ChangePassForm profile={profile} />
        </AccordionDetails>
      </Accordion>
      <Accordion elevation={0} expanded={expanded === 'delete-user'} onChange={handleChange('delete-user')} className={classes.background}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="delete-profile"
          id="delete-profile"
        >
          <Typography className={classes.heading}>Nustatymai</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <DeleteProfileView />
        </AccordionDetails>
      </Accordion>
      {!authState.isAdmin && !authState.isStaff ? 
        <Accordion elevation={0} expanded={expanded === 'orders'} className={classes.background} onChange={handleChange('orders')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="user-rders-content"
            id="user-orders-header"
          >
            <Typography className={classes.heading}>Mano užsakymai</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <OrderPanel />
          </AccordionDetails>
        </Accordion> : null}
    </div> : 
    <Redirect
      to={{
        pathname: "/"
      }}
    />
  )
}