import React, { useEffect } from 'react';
import { Theme } from '@material-ui/core/styles';
import { makeUseStyles, useGetAuthState } from './common';
import { useLoginActions } from "../store/user-actions";
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from '../store';
import { ResponseError, addError } from '../store/common';
import CenteredCircularProgress from './progress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import SignIn from './user';
import Link from '@material-ui/core/Link';


const useStyles = makeUseStyles((theme: Theme) => (
    {
      root: {
        top: 40, 
        position: "relative", 
        textAlign: "center",
        maxWidth: 400,
        margin: "0 auto"
      },
      margins: {
        paddingTop: 20,
        paddingBottom: 20
      },
      link: {
        cursor: 'pointer'
      }
    }
  )
)


export default function Activation() {
  let { token } = useParams<{ token: string }>()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.validationError, shallowEqual)
  const authState = useGetAuthState()
  const [open, setOpen] = React.useState(false)
  const [validating, setValidating] = React.useState(true)
  const classes = useStyles()
  const { dispatch, thunkValidateUser } = useLoginActions()

  const clearError = () => {
    dispatch(addError("validationError", undefined))
  }

  const endValidation = () => {
    setValidating(false)
  }
  
  useEffect(() => {
    thunkValidateUser(token, endValidation, endValidation)
    return clearError
  // eslint-disable-next-line
  }, [])

  return (authState.loggedIn ? 
    <Redirect
      to={{
        pathname: "/"
      }}
    /> :
    validating ? <CenteredCircularProgress /> :
    <React.Fragment>
      <div className={classes.root}>
        <Alert severity={Boolean(error) ? "error" : "success"}>
          <AlertTitle>
            {Boolean(error) ? "Aktyvavimo klaida!" : "Aktyvavimas sėkmingas!"}
          </AlertTitle>
          {Boolean(error) ? <span>{error.response?.detail || error.response?.token?.join(", ") || "Vartotojo aktyvuoti nepavyko dėl nežinomos klaidos"}</span> : 
          <span>Vartotojas aktyvuotas.&nbsp;
            <Link onClick={() => setOpen(true)} className={classes.link}>
              Prisijunkite
            </Link>
          </span>}
        </Alert>
      </div>
      <SignIn open={open} handleLoginClose={() => {setOpen(false)}}/>
    </React.Fragment>
  )
}
