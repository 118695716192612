import React, { useMemo } from 'react';
import BuildIcon from '@material-ui/icons/Build';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppState } from '../store';
import { Redirect, useLocation } from 'react-router-dom';
import { AuthState, Product, Order, Category } from '../store/types';
import { Typography } from '@material-ui/core';

const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
type StyleFunction = (theme: Theme, context?: any) => Record<any, any>

export function useHashQuery() {
  return new URLSearchParams(useLocation().hash)
}

export function useSearchQuery() {
  return new URLSearchParams(useLocation().search)
}

export const makeUseStyles = (styles: StyleFunction, context?: any) => makeStyles((theme: Theme) =>
  createStyles(styles(theme, context))
)

export const PLACES = [
  ["Vln", "Vilnius"],
  ["Kn", "Kaunas"], 
  ["Kl", "Klaipėda"],
  ["Ši", "Šiauliai"],
  ["Pa", "Panevėžys"],
  ["Al", "Alytus"],
  ["Ak", "Akmenė"],
  ["Akr", "Akmenės raj."], 
  ["Alr", "Alytaus raj."], 
  ["An", "Anykščiai"], 
  ["Anr", "Anykščių raj."], 
  ["Ar", "Ariogala"], 
  ["BV", "Baltoji Vokė"], 
  ["Br", "Birštonas"], 
  ["Brž", "Biržai"], 
  ["Brr", "Biržų raj."], 
  ["Da", "Daugai"], 
  ["Dr", "Druskininkai"], 
  ["Dū", "Dūkštas"], 
  ["Du", "Dusetos"], 
  ["Ei", "Eišiškės"], 
  ["El", "Elektrėnai"], 
  ["Ež", "Ežerėlis"], 
  ["Grž", "Gargždai"], 
  ["Gar", "Garliava"], 
  ["Gel", "Gelgaudiškis"], 
  ["Gri", "Grigiškės"], 
  ["Ig", "Ignalina"], 
  ["Igr", "Ignalinos raj."], 
  ["Ji", "Jieznas"], 
  ["Jo", "Jonava"], 
  ["Jor.", "Jonavos raj."], 
  ["Jon", "Joniškėlis"], 
  ["Jn", "Joniškis"], 
  ["Jnr", "Joniškio raj."], 
  ["Ju", "Jurbarkas"], 
  ["Jur", "Jurbarko raj."], 
  ["Ka", "Kaišiadorys"], 
  ["Kar", "Kaišiadorių raj."], 
  ["Kal", "Kalvarija"],
  ["Knr", "Kauno raj."], 
  ["Kav", "Kavarskas"], 
  ["KR", "Kazlų Rūda"], 
  ["Kė", "Kėdainiai"],  
  ["Kėr", "Kėdainių raj."],  
  ["Ke", "Kelmė"],  
  ["Ker", "Kelmės raj."],  
  ["Ky", "Kybartai"],   
  ["Klr", "Klaipėdos raj."],  
  ["Kr", "Kretinga"],  
  ["Krr", "Kretingos raj."],  
  ["KuN", "Kudirkos Naumiestis"],  
  ["Ku", "Kupiškis"],  
  ["Kur", "Kupiškio raj."],  
  ["Kš", "Kuršėnai"],  
  ["La", "Lazdijai"],  
  ["Lar", "Lazdijų raj."],  
  ["Len", "Lentvaris"],  
  ["Lin", "Linkuva"],  
  ["Ma", "Marijampolė"],  
  ["Mž", "Mažeikiai"],  
  ["Mžr", "Mažeikių raj."],
  ["Mo", "Molėtai"],  
  ["Mor", "Molėtų raj."],  
  ["NA", "Naujoji Akmenė"],  
  ["Ne", "Nemenčinė"],  
  ["Ner", "Neringa"],  
  ["Ob", "Obeliai"],  
  ["Pb", "Pabradė"],  
  ["Pg", "Pagėgiai"],  
  ["Pr", "Pakruojis"],  
  ["Prr", "Pakruojo raj."],  
  ["Pal", "Palanga"],  
  ["Pė", "Pandėlys"],  
  ["Pn", "Panemunė"],   
  ["Par", "Panevėžio raj."],  
  ["Ps", "Pasvalys"],  
  ["Psr", "Pasvalio raj."],  
  ["Pl", "Plungė"],  
  ["Plr", "Plungės raj."],  
  ["Prė", "Priekulė"],  
  ["Pi", "Prienai"],  
  ["Pir", "Prienų raj."],  
  ["Rd", "Radviliškis"],  
  ["Rdr", "Radviliškio raj."],  
  ["Ry", "Ramygala"],  
  ["Ra", "Raseiniai"],  
  ["Rar", "Raseinių raj."],  
  ["Ri", "Rietavas"],  
  ["Ro", "Rokiškis"],  
  ["Ror", "Rokiškio raj."],  
  ["Rū", "Rūdiškės"],  
  ["Sa", "Salantai"],  
  ["Se", "Seda"],  
  ["Si", "Simnas"],  
  ["Sk", "Skaudvilė"],  
  ["Sku", "Skuodas"],  
  ["Skr", "Skuodo raj."],
  ["Sm", "Smalininkai"],  
  ["Su", "Subačius"],  
  ["Šk", "Šakiai"],  
  ["Škr", "Šakių raj."],  
  ["Ša", "Šalčininkai"],  
  ["Šar", "Šalčininkų raj."],  
  ["Še", "Šeduva"],    
  ["Šir", "Šiaulių raj."],  
  ["Šl", "Šilalė"],  
  ["Šlr", "Šilalės raj."],  
  ["Št", "Šilutė"],  
  ["Štr", "Šilutės raj."],  
  ["Šo", "Širvintos"],  
  ["Šor", "Širvintų raj."],  
  ["Šč", "Švenčionėliai"],  
  ["Šv", "Švenčionys"],  
  ["Švr", "Švenčionių raj."],  
  ["Tr", "Tauragė"],  
  ["Trr", "Tauragės raj."],  
  ["Te", "Telšiai"],  
  ["Ter", "Telšių raj."],  
  ["Ty", "Tytuvėnai"],  
  ["Tk", "Trakai"],  
  ["Tkr", "Trakų raj."],  
  ["To", "Troškūnai"],  
  ["Uk", "Ukmergė"],  
  ["Ukr", "Ukmergės raj."],  
  ["Ut", "Utena"],
  ["Utr", "Utenos raj."],
  ["Už", "Užventis"],
  ["Vab", "Vabalninkas"],
  ["Var", "Varėna"],
  ["Vrr", "Varėnos raj."],
  ["Vrn", "Varniai"],
  ["Vei", "Veisiejai"],
  ["Ve", "Venta"],
  ["Vie", "Viekšniai"],
  ["Viv", "Vievis"],
  ["Vlk", "Vilkaviškis"],
  ["Vlkr", "Vilkaviškio raj."],
  ["Vilk", "Vilkija"],
  ["Vlr", "Vilniaus raj."],
  ["Vir", "Virbalis"],
  ["Vis", "Visaginas"],
  ["Za", "Zarasai"],
  ["Zar", "Zarasų raj."],
  ["Žag", "Žagarė"],
  ["Žie", "Žiežmariai"]
]


export const BANK_MAP = {
  3: {type: "card", alt: ""},
  4: {type: "sb", alt: "AB Šiaulių bankas"},
  5: {type: "mb", alt: "UAB Medicinos bankas"},
  6: {type: "lku", alt: "Lietuvos kredito unijos"},
  7: {type: "vb2", alt: "AB SEB bankas"},
  8: {type: "hanza", alt: 'AB bankas "Swedbank"'},
  9: {type: "nord", alt: "AS Luminor bankas"},
  10: {type: "parex", alt: "AS Citadele bankas"}
}

/* 
 * Order selectors
 */

const makeGetDeliveryData = (city: string | undefined) => {
  return createSelector(
    (state: AppState) => state.user.config,
    config => {
      for (let p of config.delivery_map) {
        if (p.option === city)
          return {city: p.value, fee: p.fee}
      }
      return {city: "Vilnius", fee: 0}
    }
  )
}

export function useDeliveryData(city: string | undefined) {
  
  const getDeliveryData = useMemo(() => makeGetDeliveryData(city), [city])
  const deliveryData = useSelector<AppState, {
    city: string
    fee: number
  }>(state =>
    getDeliveryData(state)
  )
  return deliveryData
}


export const getCompanyCity = (tag: string) => {
  for (let place of PLACES) {
    if (place[0] === tag) 
      return place[1]
  }
}

const makeGetOrderCount = () => {
  return createSelector(
    (state: AppState) => state.user.cart,
    cart => {
      let totalPrice = 0
      let totalDiscountedPrice = 0
      cart.orders.forEach(order => {
        let coeffs:number[] = []
        order.choice?.split("|").forEach(ch => {
          let s = ch.split(";")
          let num = 1
          if (s.length === 2) {
            let cnum = parseFloat(s[1])
            if (!isNaN(cnum)) num = cnum
          }
          coeffs.push(num)
        })
        
        let coeff = coeffs.length ? coeffs.reduce((a, b) => a + b, 0) / coeffs.length : 1
        let orderPrice = Math.ceil(order.item.price * coeff * (1 - order.item.discount / 100))
        totalPrice += order.quantity * orderPrice
        totalDiscountedPrice += order.quantity * Math.ceil(orderPrice * (1 - (cart.data.discount || 0) / 100))
      })
      return {
        count: cart.orders.length,
        totalPrice: totalPrice / 100,
        totalDiscountedPrice: totalDiscountedPrice / 100
      }
    }
  )
}

export function useOrderCount() {
  
  const getOrderCount = useMemo(makeGetOrderCount, [])
  const orderCount = useSelector<AppState, {
    count: number
    totalPrice: number
    totalDiscountedPrice: number
  }>(state =>
    getOrderCount(state)
  )
  return orderCount
}


const makeGetOrderCountByStatus = (status: number) => {
  return createSelector(
    (state: AppState) => state.management.orders.get(status),
    orders => orders?.count
  )
}

export function useGetOrderCountByStatus(status: number) {
  
  const getOrderCount = useMemo(() => makeGetOrderCountByStatus(status), [status])
  const orderCount = useSelector<AppState, number>(state =>
    getOrderCount(state)
  )
  return orderCount
}


const makeGetProductOrder = (productId: number, choice: string | undefined) => {
  return createSelector(
    (state: AppState) => state.user.cart.orders,
    orders => {
      for (let idx = 0; idx < orders.length; idx++) {
        let order = orders[idx]
        if (order.item.id === productId && order.choice === choice) return {order, idx}
      }
    }
  )
}

export function useProductOrder(productId: number, choice: string | undefined) {
  
  const getProductOrder = useMemo(() => makeGetProductOrder(productId, choice), [productId, choice])
  return useSelector<AppState, {order: Order, idx: number} | undefined>(state =>
    getProductOrder(state)
  )
}

/* 
 * Auth selectors
 */


const makeGetAuthState = () => {
  return createSelector(
    (state: AppState) => state.user.profile,
    profile => {
      if (typeof profile !== 'undefined')
        return {
          loggedIn: true,
          username: profile.username,
          email: profile.email,
          isAdmin: profile.is_superuser,
          isStaff: profile.is_staff,
          isActivated: profile.is_activated
        }
      else
        return {
          loggedIn: false,
          isAdmin: false,
          isStaff: false,
          isActivated: false
        }
    }
  )
}

export function useGetAuthState() {
  
  const getAuthState = useMemo(makeGetAuthState, [])
  const authState = useSelector<AppState, AuthState>(state =>
      getAuthState(state)
  )
  return authState
}

/* 
 * Category selector
 */

const makeGetCategory = (id: number) => {
  return createSelector(
    (state: AppState) => state.inventory.categories,
    categories => {
      if (categories) {
        for (let cat of categories) {
          if (cat.id === id) return cat
        }
      }
    }
  )
}

export function useGetCategory(id: number) {
  
  const getCategory = useMemo(() => makeGetCategory(id), [id])
  const prodcuts = useSelector<AppState, Category | undefined>(state =>
    getCategory(state)
  )
  return prodcuts
}

/* 
 * Products selectors
 */

const makeGetProducts = (catId: number, subcategory: string | undefined) => {
  return createSelector(
    (state: AppState) => state.inventory.categories,
    categories => {
      if (categories) {
        for (let cat of categories) {
          if (cat.id === catId) {
            return cat.products?.filter(pr => Boolean(subcategory) ? pr.subcategory === subcategory : cat.sub.indexOf(pr.subcategory) < 0)
          }
        }
      }
    }
  )
}

export function useGetProducts(catId: number, subcategory: string | undefined) {
  
  const getProducts = useMemo(() => makeGetProducts(catId, subcategory), [catId, subcategory])
  const prodcuts = useSelector<AppState, Product[] | undefined>(state =>
    getProducts(state)
  )
  return prodcuts
}

const makeGetProductMap = (ids: number[]) => {
  return createSelector(
    (state: AppState) => state.inventory.categories,
    categories => {
      let products = new Map<number, Product>()
      let idxs = [...ids]
      if (categories && idxs.length) {
        for (let cat of categories) {
          if (cat.products) {
            for (let pr of cat.products) {
              let idx = idxs.indexOf(pr.id)
              if (idx > -1) {
                products.set(pr.id, pr)
                idxs.splice(idx, 1)
                if (idxs.length === 0) return products
              }
            }
          }
        }
      }
      return products
    }
  )
}

export function useGetProductMap(ids: number[]) {
  
  const getProducts = useMemo(() => makeGetProductMap(ids), [ids])
  const prodcuts = useSelector<AppState, Map<number, Product>>(state =>
    getProducts(state)
  )
  return prodcuts
}


export const PROVIDERS = {
  GOOGLE: "google-oauth2",
  FACEBOOK: "facebook"
}

export function randomState() {
  let numberArray = new Array<number>()
  let array = new Uint8Array(40)
  window.crypto.getRandomValues(array)
  array.forEach(x => {
    numberArray.push(validChars.charCodeAt(x % validChars.length))
  })
  const randomState = String.fromCharCode.apply(null, numberArray)
  return randomState
}


const useStyles = makeUseStyles(() => (
  {
    middle: {
      top: "25%", 
      left: "50%",
      msTransform: "translate(-50%, -25%)",
      transform: "translate(-50%, -25%)",
      position: "fixed",
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    build: {
      fontSize: 50,
      marginBottom: 20
    }
  }
))


export function NoMatch() {
  return <Redirect to="/" />
}


export function MaintenanceAlert() {
  const classes = useStyles()
  return (
    <div className={classes.middle}>
      <BuildIcon className={classes.build} />
      <Typography align="center" variant="h4">Maintenance in progress!</Typography>
    </div>
  )
}