import { useDispatch } from "react-redux";
import {
  Profile,
  SocialAppConfig,
  voidFn,
  fn,
  AppConfig,
  Order, 
  LoginData,
  OrderData,
  PaymentData,
  Product,
} from './types';
import { URLS, addError, sendRequest, sendTokenRefreshRequest, ResponseError } from './common';

export const SET_USER_PROFILE = "SET_USER_PROFILE"
export const SET_SOC_APP_CONFIG = "SET_SOC_APP_CONFIG"
export const SET_REMEMBER_ME = "SET_REMEMBER_ME"
export const SET_SIGNING_IN = "SET_SIGNING_IN"
export const SET_APP_CONFIG = "SET_APP_CONFIG"
export const ADD_ORDER = "ADD_ORDER_ITEM"
export const RM_ORDER = "RM_ORDER_ITEM"
export const ADD_CART_DATA = "ADD_CART_DATA"
export const CLEAN_CART = "CLEAN_CART"

const RETENTION_PERIOD = 43200000 /** 12 hours */


/**
 * Cart actions
 */

interface AddCartDataAction {
  type: typeof ADD_CART_DATA
  payload: OrderData
  replace: boolean
}

interface CleanupCart {
  type: typeof CLEAN_CART
}

interface AddOrderAction {
  type: typeof ADD_ORDER
  payload: Order
  store: boolean
}

interface RemoveOrderAction {
  type: typeof RM_ORDER
  index: number
}

export function addCartData(
  payload: OrderData,
  replace: boolean
): UserAction {
  return {
    type: ADD_CART_DATA,
    payload,
    replace,
  }
}

export function cleanupCart(): UserAction {
  return {
    type: CLEAN_CART
  }
}

export function addOrder(
  order: Order,
  store: boolean
): UserAction {
  return {
    type: ADD_ORDER,
    payload: order,
    store
  }
}

export function removeOrder(
  index: number
): UserAction {
  return {
    type: RM_ORDER,
    index: index
  }
}

/** */

interface SetUserProfile {
  type: typeof SET_USER_PROFILE
  payload: Profile | undefined
}

interface SetSocApps {
  type: typeof SET_SOC_APP_CONFIG
  payload: SocialAppConfig[]
}

interface SetRememberMe {
  type: typeof SET_REMEMBER_ME
  payload: boolean
}

interface SetSigningIn {
  type: typeof SET_SIGNING_IN
  payload: boolean
}

interface SetAppConfig {
  type: typeof SET_APP_CONFIG
  payload: AppConfig
}

export function addProfile(
  profile: Profile | undefined
): UserAction {
  return {
    type: SET_USER_PROFILE,
    payload: profile
  }
}

function addSocialApps(
  socapps: SocialAppConfig[]
): UserAction {
  return {
    type: SET_SOC_APP_CONFIG,
    payload: socapps
  }
}

export function setRememberMe(
  remember_me: boolean
): UserAction {
  return {
    type: SET_REMEMBER_ME,
    payload: remember_me
  }
}

export function setSigningIn(
  signingIn: boolean
): UserAction {
  return {
    type: SET_SIGNING_IN,
    payload: signingIn
  }
}

export function setAppConfig(
  config: AppConfig
): UserAction {
  return {
    type: SET_APP_CONFIG,
    payload: config
  }
}

interface SocialCredentials {
  access_token: string
  remember_me: boolean
}

interface Credentials {
  username?: string
  first_name?: string
  last_name?: string
  email: string
  password: string
  ctoken: string | undefined
  old_password?: string
  confirm_password?: string
  remember_me?: boolean
}

export const useLoginActions = () => {
  const dispatch = useDispatch()
  
  const thunkUpdateConfig = async (data: any, cb?: fn<{available_from: Date}>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.APP_CONFIG, 'POST', JSON.stringify(data), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      setAppConfig(result)
      if (cb) cb(result)
    }
    catch(err) {
      dispatch(
        addError("configError", err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }
  
  const thunkLogin = async (credentials: Credentials, successCb?: fn<LoginData>, errorCb?: voidFn) => {
    try {
      const data = await sendRequest(URLS.LOGIN, 'POST', JSON.stringify(credentials), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(data)
    }
    catch(err) {
      dispatch(addError("authError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkLogout = async () => {
    delete window.token_data
    dispatch(addProfile(undefined))
    dispatch(addCartData({
      firstname: undefined,
      lastname: undefined,
      email: undefined
    }, false))
    try {
      await sendRequest(URLS.LOGOUT, 'POST')
    }
    catch (err) {
      // dispatch(addError("authError", err))
    }
  }

  const thunkSocialLogin = async (provider: string, credentials: SocialCredentials, cb: voidFn) => {
    try {
      const data = await sendRequest(`${URLS.SOCIAL}${provider}/`, 'POST', JSON.stringify(credentials), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      window.token_data = data.token
      dispatch(addProfile(data.user))
      dispatch(addCartData({
        firstname: data.user.first_name,
        lastname: data.user.last_name,
        email: data.user.email
      }, false))
      cb()
    }
    catch(err) {
      dispatch(addError("authError", err as ResponseError))
    }
  }

  const thunkGetProfile = async () => {
    try {
      try {
        window.token_data = await sendTokenRefreshRequest()
      } catch (_) {
        delete window.token_data
      }
      const data = await sendRequest(URLS.PROFILE, 'GET')
      dispatch(
        addProfile(data)
      )
      dispatch(addCartData({
        firstname: data.first_name,
        lastname: data.last_name,
        email: data.email
      }, false))
    }
    catch(err) {
      dispatch(
        addProfile(undefined)
      )
    }
  }

  const thunkCreateProfile = async (credentials: Credentials, successCb?: fn<Profile>, errorCb?: voidFn) => {
    try {
      const data = await sendRequest(URLS.PROFILE, 'POST', JSON.stringify(credentials), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(data)
    }
    catch(err) {
      dispatch(addError("authError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkUpdateProfile = async (data: any, cb?: fn<Profile>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.PROFILE, 'PATCH', JSON.stringify(data), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (cb) cb(result)
    }
    catch(err) {
      dispatch(
        addError("profileError", err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }

  const thunkDeleteProfile = async (token: string | undefined, cb?: fn<Profile>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.PROFILE, 'DELETE', JSON.stringify({token}), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      delete window.token_data
      if (cb) cb(result)
    }
    catch(err) {
      dispatch(
        addError("profileError", err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }

  const thunkGetSocApps = async () => {
    try {
      const data = await sendRequest(URLS.SOCAPPCONFIG, 'GET')
      dispatch(
        addSocialApps(data)
      )
    }
    catch(err) {
      dispatch(
        dispatch(addError("socappGetError", err as ResponseError))
      )
    }
  }

  const thunkGetAppConfig = async (cb?: fn<AppConfig>) => {
    try {
      const data = await sendRequest(URLS.APP_CONFIG, 'GET')
      data.available_from = new Date(data.available_from)
      data.timed_config = data.timed_config.map(v => {
        return {
          ...v,
          date_from: new Date(v.date_from), 
          date_to: new Date(v.date_to)
        }
      })
      dispatch(
        setAppConfig(data)
      )
      if (cb) cb(data)
    }
    catch(err) {
      dispatch(
        dispatch(addError("appConfigError", err as ResponseError))
      )
    }
  }

  const thunkSendValidationEmail = async (email: string, ctoken: string | undefined, error: "authError" | "validationError", successCb?: voidFn, errorCb?: voidFn) => {
    try {
      await sendRequest(URLS.VALIDATION_EMAIL, 'POST', JSON.stringify({
        email, 
        ctoken,
        host: `${window.location.origin.toString()}/activate/`
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb()
    }
    catch(err) {
      dispatch(addError(error, err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkValidateUser = async (token: string, successCb?: voidFn, errorCb?: voidFn) => {
    try {
      await sendRequest(URLS.VALIDATE, 'POST', JSON.stringify({
        token
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb()
    }
    catch(err) {
      dispatch(addError("validationError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkSendResetEmail = async (email: string, ctoken: string | undefined, successCb?: voidFn, errorCb?: voidFn) => {
    try {
      await sendRequest(URLS.RESET_EMAIL, 'POST', JSON.stringify({
        email,
        ctoken,
        host: `${window.location.origin.toString()}/reset/`
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb()
    }
    catch(err) {
      dispatch(addError("authError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkResetPassword = async (password: string, confirm_password: string, token: string, ctoken: string | undefined, successCb?: voidFn, errorCb?: voidFn) => {
    try {
      await sendRequest(URLS.RESET, 'POST', JSON.stringify({
        token,
        password,
        confirm_password, 
        ctoken
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb()
    }
    catch(err) {
      dispatch(addError("resetError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkGetCart = (products: Product[], orders: string[]) => {
    let ord: number[][] = []

    orders.forEach(o => {
      let n: number[] = []
      o.split(",").forEach(i => {
        let num = parseInt(i)
        if (!isNaN(num))
          n.push(num)
      })
      if (n.length > 1)
        ord.push(n)
    })
    
    let cartOrd: Order[] = []
    try {
      let cart: {orders: Order[], timestamp: number} = JSON.parse(localStorage.getItem("cart"))
      if (cart.timestamp + RETENTION_PERIOD > new Date().getTime())
        cartOrd = cart.orders
    } catch {
      localStorage.removeItem("cart")
    }
    products.forEach(p => {
      if (ord.length > 0) {
        ord.filter(o => o[0] === p.id).forEach(o => {
          let choice
          if (o.length > 2) {
            choice = p.choices[o[2]]
          }
          dispatch(addOrder({
            item: p,
            quantity: o[1],
            choice
          }, true))
        })
      } else {
        cartOrd.filter(o => o.item.id === p.id).forEach(o => {
          dispatch(addOrder({
            item: p,
            quantity: o.quantity,
            choice: o.choice
          }, false))
        })
      }
    })
  }

  const thunkCreateOrder = async (data: OrderData, token: string | undefined, successCb?: fn<{id: string, status: number, pay_status: number}>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.ORDERS, 'POST', JSON.stringify({...data, ctoken: token}), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(result)
    }
    catch(err) {
      dispatch(addError("orderError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkGetPaymentInfo = async (order_id: string, token: string | undefined, successCb?: fn<{data?: string, sign?: string, method?: number, pay_status?: number, pay_ack_status?: number}>, errorCb?: voidFn) => {
    try {
      const redirectUrl = `${window.location.origin.toString()}`
      const result = await sendRequest(URLS.PAY, 'POST', JSON.stringify({
        order_id,
        accepturl: `${redirectUrl}/payment/accept`, 
        cancelurl: `${redirectUrl}/payment/cancel`,
        ctoken: token
      }), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(result)
    }
    catch(err) {
      dispatch(addError("paymentError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkVerifyOrder = async (data: PaymentData, token: string | undefined, successCb?: fn<{order_id: string, pay_ack_status: number}>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.PAYMENT_VERIFY, 'POST', JSON.stringify({...data, ctoken: token}), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(result)
    }
    catch(err) {
      dispatch(addError("orderError", err as ResponseError))
      if (errorCb) errorCb()
    }
  }

  const thunkVerifyDiscountCode = async (code: string, token: string | undefined, successCb?: fn<{percent: number}>, errorCb?: voidFn) => {
    try {
      const result = await sendRequest(URLS.DISCOUNT_CODE_VERIFY, 'POST', JSON.stringify({code, ctoken: token}), {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
      if (successCb) successCb(result)
    }
    catch(err) {
      if (errorCb) errorCb()
    }
  }

  return {
    dispatch: dispatch, 
    thunkLogin: thunkLogin,
    thunkLogout: thunkLogout,
    thunkValidateUser: thunkValidateUser,
    thunkSendValidationEmail: thunkSendValidationEmail,
    thunkSocialLogin: thunkSocialLogin,
    thunkGetProfile: thunkGetProfile,
    thunkCreateProfile: thunkCreateProfile,
    thunkGetCart: thunkGetCart,
    thunkUpdateProfile: thunkUpdateProfile,
    thunkSendResetEmail: thunkSendResetEmail,
    thunkResetPassword: thunkResetPassword,
    thunkGetSocApps: thunkGetSocApps,
    thunkGetAppConfig: thunkGetAppConfig,
    thunkCreateOrder: thunkCreateOrder,
    thunkGetPaymentInfo: thunkGetPaymentInfo,
    thunkVerifyOrder: thunkVerifyOrder,
    thunkUpdateConfig: thunkUpdateConfig,
    thunkDeleteProfile: thunkDeleteProfile,
    thunkVerifyDiscountCode: thunkVerifyDiscountCode
  } 
}
  
export type UserAction = SetUserProfile | SetSocApps | SetRememberMe | SetSigningIn | SetAppConfig | 
                         AddOrderAction | CleanupCart | 
                         RemoveOrderAction | AddCartDataAction
