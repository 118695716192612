import React, { useEffect } from 'react';
import { Theme,  } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useLoginActions, SET_REMEMBER_ME, addProfile, addCartData } from './store/user-actions';
import CategoriesGrid from './components/categories';
import Products from './components/products';
import AppBar from './components/appbar';
import Activation from './components/activation';
import { BusinessRules, Conditions, Privacy, PrivacyPolicy } from './components/info';
import { About, Contacts } from './components/about';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import { AppConfig, LoginData } from './store/types';
import { NoMatch, makeUseStyles, useGetAuthState, MaintenanceAlert, PROVIDERS, useOrderCount, useSearchQuery } from './components/common';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from './store';
import CenteredCircularProgress from './components/progress';
import Reset from './components/reset';
import ManagementConsole from './components/management';
import ProfileConsole from './components/profile';
import { addError, ResponseError, grecaptchaRequest } from './store/common';
import Checkout from './components/checkout';
import OrderDetails from './components/order';
import { ResolvePaymentView } from './components/payment';
import { useInventoryActions } from './store/inventory-actions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import FacebookIcon from '@material-ui/icons/Facebook';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';


const useLoginStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      top: "20%", 
      left: "50%",
      msTransform: "translate(-50%, -20%)",
      transform: "translate(-50%, -20%)",
      position: "fixed",
      maxWidth: 300
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }
))


const AdminLogin = () => {
  const history = useHistory()
  const classes = useLoginStyles()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const remember_me = useSelector<AppState, boolean>(state => state.user.remember_me, shallowEqual)
  const [signingIn, setSigningIn] = React.useState(false)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.authError, shallowEqual)
  const { dispatch, thunkLogin } = useLoginActions()

  const clearError = () => {
    dispatch(addError("authError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPassword(event.target.value)
  }

  const handleRemeberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: SET_REMEMBER_ME, payload: event.target.checked })
  }

  const onLoginError = () => {
    setPassword("")
    setSigningIn(false)
  }

  const onLoginSuccess = (data: LoginData) => {
    window.token_data = data.token
    clearError()
    setSigningIn(false)
    dispatch(addProfile(data.user))
    dispatch(addCartData({
      firstname: data.user.first_name,
      lastname: data.user.last_name,
      email: data.user.email
    }, false))
    history.push('/')
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSigningIn(true)
    grecaptchaRequest('signin', (ctoken) => {
      thunkLogin({
        email: email, 
        password: password,
        remember_me: remember_me,
        ctoken: ctoken
      }, onLoginSuccess, onLoginError)
    }, (err) => {
      dispatch(addError("authError", new ResponseError({detail: 'Failed to signin'}, 400)))
      console.log(err)
      onLoginError()
    })
  }

  return (
    <div className={classes.root}>
      <Collapse in={Boolean(error)}>
        <Alert severity={"error"}>
          {error?.response?.detail}
        </Alert>
      </Collapse>
      <form id='admin-sign-in-form' onSubmit={onSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={email}
          onChange={handleEmailChange}
          label="El. paštas"
          name="email"
          autoComplete="email"
          autoFocus
          error={Boolean(error?.response?.email?.join(' '))}
          helperText={error?.response?.email?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          name="password"
          label="Slaptažodis"
          type="password"
          autoComplete="current-password"
          error={Boolean(error?.response?.password?.join(' '))}
          helperText={error?.response?.password?.join(' ')}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" onChange={handleRemeberMe}/>}
          label="Prisiminti mane"
        />
        <Button
          fullWidth
          type="submit"
          form="admin-sign-in-form"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={signingIn || !email || !password}
        >
          {signingIn ? <CircularProgress size={25} /> : 'Prisijungti'}
        </Button>
      </form>
    </div>
  )
}


const BusinessConditions = () => {
  return <React.Fragment>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 15 }}>
      Paslaugų teikimo taisyklės
      </Typography>
      <Typography component="div" style={{ marginBottom: 30 }}>
        <BusinessRules />
      </Typography>
    </React.Fragment>
}

const PrivacyView = () => {
  return <React.Fragment>
      <Typography variant="h5" style={{ marginTop: 30, marginBottom: 15 }}>
      Privatumo politika
      </Typography>
      <Typography component="div" style={{ marginBottom: 30 }}>
        <PrivacyPolicy />
      </Typography>
    </React.Fragment>
}

const MaintenanceLandingPage = () => {
  return (
    <Container maxWidth="md">
      <Switch>
        <Route exact path="/valdyti" component={AdminLogin} />
        <Route exact path="/conditions" component={BusinessConditions} />
        <Route exact path="/privacy" component={PrivacyView} />
        <Route component={MaintenanceAlert}/>
      </Switch>
    </Container>
  )
}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{fontSize: 11}}>
      {'Copyright © canapes.lt. '}
      {new Date().getFullYear()}
    </Typography>
  );
}


const useAppStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      display:'flex', 
      flexDirection: 'column',
      minHeight: '100vh',
    },
    container: {
      backgroundColor: theme.palette.background.default, 
      height: '100%',
      paddingBottom: 30
    },
    cartButton: {
      border: "1px solid grey",
      backgroundColor: theme.palette.background.default,
      padding: 6,
      marginBottom: 5 
    },
    scrollButton: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 3
    },
    breadcrumbs: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 11
    },
    link: {
      cursor: "pointer"
    },
    footer: {
      padding: theme.spacing(3, 2),
      marginTop: 'auto',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
  }
))

const App = () => {
  const history = useHistory()
  const [ conditionsOpen, setConditionsOpen ] = React.useState(false)
  const [ privacyOpen, setPrivacyOpen ] = React.useState(false)
  const { thunkGetCart } = useLoginActions()
  const { thunkGetCategories, thunkGetProducts } = useInventoryActions()
  const orderCount = useOrderCount()
  const classes = useAppStyles()
  let query = useSearchQuery()
  let orders = query.getAll("order")

  useEffect(() => {
    /** Get inventory */
    thunkGetCategories(categories => {
      categories.forEach(cat => {
        thunkGetProducts(cat.id, products => {
          thunkGetCart(products, orders)
        })
      })
    })
  // eslint-disable-next-line
  }, [])

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const onClick = () => {
    const anchor = document.querySelector(
      '#app-container',
    )
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <div className={classes.root} id="app-container">
      <AppBar />
      <Container maxWidth="md" className={classes.container}>
        <Switch>
          <Route exact path="/" component={CategoriesGrid} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/conditions" component={BusinessConditions} />
          <Route exact path="/privacy" component={PrivacyView} />
          <Route exact path="/products/:id" component={Products} />
          <Route exact path="/activate/:token" component={Activation} />
          <Route exact path="/reset/:token" component={Reset} />
          <Route exact path="/management" component={ManagementConsole} />
          <Route exact path="/profile" component={ProfileConsole} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/payment/:state" component={ResolvePaymentView} />
          <Route exact path="/order/:id" component={OrderDetails} />
          <Route component={NoMatch}/>
        </Switch>
      </Container>
      <Zoom in={trigger}>
        <div className={classes.scrollButton}>
          {Boolean(orderCount.count) && history.location.pathname !== "/checkout" ? 
            <IconButton 
              aria-label="show shopping cart" 
              color="inherit" 
              onClick={() => {
                history.push("/checkout")
                onClick()
              }}
              className={classes.cartButton}
            >
              <Badge overlap="rectangular" badgeContent={orderCount.count} color="secondary">
                <ShoppingCartOutlined />
              </Badge>
            </IconButton> : null}
          <Fab color="primary" size="small" aria-label="scroll back to top" onClick={onClick}>
            <KeyboardArrowUpIcon />
          </Fab>
        </div>
      </Zoom>
      <footer className={classes.footer}>
        <Container maxWidth="md" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <Link aria-label="facebook" href="https://www.facebook.com/canapes.lt" target="_blank" color="inherit" style={{marginTop: -12}}>
            <FacebookIcon />
          </Link>
          <Copyright />
          <Breadcrumbs separator="&#183;" aria-label="app-breadcrumbs" className={classes.breadcrumbs}>
            <Link color="inherit" onClick={() => setPrivacyOpen(true)} className={classes.link}>
              Privatumo politika
            </Link> 
            <Link color="inherit" onClick={() => setConditionsOpen(true)} className={classes.link}>
              Paslaugų sąlygos
            </Link>
          </Breadcrumbs>
        </Container>
      </footer>
      <Privacy open={privacyOpen} onClose={() => setPrivacyOpen(false)} />
      <Conditions open={conditionsOpen} onClose={() => setConditionsOpen(false)} />
    </div>
  )
}


const useStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      display:'flex', 
      flexDirection: 'column', 
      minHeight: '100vh'
    },
  }
))


const RootApp = () => {
  const history = useHistory()
  const { dispatch, thunkGetAppConfig, thunkGetProfile, thunkGetSocApps, thunkSocialLogin } = useLoginActions()
  const authState = useGetAuthState()
  const profileLoaded = useSelector<AppState, boolean>(state => "profile" in state.user, shallowEqual)
  const config = useSelector<AppState, AppConfig | undefined>(state => state.user.config, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.appConfigError, shallowEqual)
  const classes = useStyles()
  const [loginError, setLoginError] = React.useState<string>()
  const [rcLoaded, setRCLoaded] = React.useState(false)
  let query = new URLSearchParams(window.location.hash)

  const loadConfig = () => {
    thunkGetAppConfig((cfg) => {
      if (cfg.recaptcha.force && (!cfg.recaptcha.url || !cfg.recaptcha.client_id))
        dispatch(addError("appConfigError", new ResponseError({
          detail: 'Recaptcha is required but config is missing'
        }, 404)))
      else if (cfg.recaptcha.url && cfg.recaptcha.client_id) {
        // Add reCaptcha
        window.captchaOnLoad = () => {
          if (cfg.recaptcha) {
            window.grecaptcha.render('recaptcha-badge', {
              sitekey: cfg.recaptcha.client_id,
              badge: 'inline',
              size: 'invisible'
            })
            window.grecaptcha.ready(() => {
              setRCLoaded(true)
            })
          }
        }
        const url = cfg.recaptcha.url
        const queryString = '?onload=captchaOnLoad&render=explicit'
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url + queryString
        script.async = true
        script.defer = true

        document.body.appendChild(script)
      } else
        setRCLoaded(true)
    })
    thunkGetSocApps()
  }

  const socialLogin = (provider: string, remember_me: boolean, redirect: string) => {
    let access_token = query.get("access_token") || query.get("#access_token")
    let error = query.get("error") || query.get("#error")
    switch (provider) {
      case PROVIDERS.FACEBOOK:
        let denied_scopes = query.get("#denied_scopes") || query.get("denied_scopes")
        if (error || denied_scopes || !access_token) {
          setLoginError('User cancelled login or did not fully authorize.')
        } else {
          thunkSocialLogin(provider, { access_token, remember_me }, () => {
            loadConfig()
            history.push(redirect)
          })
        }
        break;
      case PROVIDERS.GOOGLE:
        if (error || !access_token) {
          setLoginError('User cancelled login or did not fully authorize.')
        } else {
          thunkSocialLogin(provider, { access_token, remember_me }, () => {
            loadConfig()
            history.push(redirect)
          })
        }
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    // Handle location search parameters
    try {
      let socauth = JSON.parse(sessionStorage.getItem('_socauth-state')) as {provider: string, state: string, remember_me: boolean, redirect: string}
      sessionStorage.removeItem('_socauth-state')
      let state = query.get("state") || query.get("#state")
      if (socauth.provider && socauth.state && socauth.state === state) {
        socialLogin(socauth.provider, socauth.remember_me, socauth.redirect)
        return
      }
    } catch {}

    loadConfig()
    thunkGetProfile()
  // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      {config && rcLoaded && profileLoaded ? config.env !== 'D' || authState.loggedIn ?
      <App /> :
      <MaintenanceLandingPage /> : 
      error ? 
      <Alert severity="error">{error?.response?.detail}</Alert> : 
      loginError ? 
        <Alert severity="error">{loginError}</Alert>
      : <CenteredCircularProgress />}
    </div>
  )

}


export default RootApp