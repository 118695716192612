import React from 'react';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeUseStyles, useOrderCount } from './common';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Order, Cart, Product, voidFn, AppConfig } from '../store/types';
import { AppState } from '../store';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { SimpleConfirmDialog, EditDialog } from './dialogs';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { addOrder, removeOrder } from '../store/user-actions';
import { ProductDetailCard } from './products';
import Link from '@material-ui/core/Link';
import { NumberDial } from './products';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import LazyImage from './image';


const useCartStyles = makeUseStyles((theme: Theme) => (
  {
    item: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: 'column',
    },
    edit: {
      color: 'green',
      fontSize: 20
    },
    delete: {
      color: 'red',
      fontSize: 20
    },
    subhead: {
      fontWeight: "bold",
      color: "#000000",
      width: "100%"
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    alert: {
      position: "absolute",
      boxShadow: theme.shadows[5],
      width: 200,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }
  })
)

interface CartViewProps {
  onNextClick: voidFn
}

const CartView = ({onNextClick}: CartViewProps) => {
  const dispatch = useDispatch()
  const classes = useCartStyles()
  const cart = useSelector<AppState, Cart>(state => state.user.cart, shallowEqual)
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const orderCount = useOrderCount()
  const [activeProduct, setActiveProduct] = React.useState<{item: Product, choice: string}>()
  const [deleteIndex, setDeleteIndex] = React.useState<number>()
  const [added, setAdded] = React.useState(false)

  let lowSum = config.min_order_price / 100 > orderCount.totalPrice

  const removeFromCart = () => {
    if (typeof deleteIndex !== "undefined") dispatch(removeOrder(deleteIndex))
  }

  const setConfirmDialogClosed = () => {
    setDeleteIndex(undefined)
  }

  const setDetailDialogClosed = () => {
    setActiveProduct(undefined)
  }

  const onDialChange = (item: Product, quantity: number, choice: string | undefined) => {
    dispatch(addOrder({
      item,
      quantity,
      choice
    }, true))
  }

  const itemPrice = (order: Order) => {

    let coeffs:number[] = []
    order.choice?.split("|").forEach(ch => {
      let s = ch.split(";")
      let num = 1
      if (s.length === 2) {
        let cnum = parseFloat(s[1])
        if (!isNaN(cnum)) num = cnum
      }
      coeffs.push(num)
    })
    
    let coeff = coeffs.length ? coeffs.reduce((a, b) => a + b, 0) / coeffs.length : 1

    return order.quantity * Math.ceil(order.item.price * coeff * (1 - order.item.discount / 100)) / 100
  }

  const choiceString = (choice: string) => {
    return choice.split("|").map(s => s.split(";")[0]).join(", ")
  }

  return (
    <React.Fragment>
      <List
        component="div"
      >
        {cart.orders.map((value: Order, idx: number) => {
          return (
            <ListItem key={idx} className={classes.item}>
              <div style={{display: 'flex', flexDirection: "column"}}>
                <ListItemAvatar>
                  <LazyImage
                    alt={value.item.name} 
                    maxWidth={120} 
                    src={value.item.picture}
                    style={{
                      marginTop: 15
                    }}
                  />
                </ListItemAvatar>
                {value.choice ? <Chip size="small" color="primary" label={choiceString(value.choice)}/> : null}
              </div>
              
              <ListItemText 
                primary={
                  <div>
                    <Link 
                      style={{cursor: "pointer", fontSize: 20}} 
                      onClick={()=>{setActiveProduct({item: value.item, choice: value.choice})}}>{value.item.name}
                    </Link>
                  </div>
                }
                secondary={<Typography variant="body1" style={{color: "grey"}} dangerouslySetInnerHTML={{__html: value.item.info}}/>}
              />
              <div style={{display: "flex", alignItems: "center", flexFlow: "wrap"}}>
                <Typography variant="body1" color="primary" component="span">
                  {(itemPrice(value)).toFixed(2)}&euro; 
                </Typography>
                <NumberDial 
                  value={value.quantity} 
                  onDialChange={(amount: number) => {onDialChange(value.item, amount - value.quantity, value.choice)}}
                  minValue={value.item.quantity}
                  hardMin={value.item.quantity}
                  maxValue={value.item.max_quantity}
                />
                <IconButton aria-label="delete" className={classes.delete} onClick={() => {setDeleteIndex(idx)}}>
                  <ClearIcon fontSize="inherit"/>
                </IconButton>
              </div>
            </ListItem>
          )
        })}
        <hr />
        <ListItem>
          <ListItemText primary={`Suma`}/>
          <Typography variant="body1" color="primary" component="span" style={{fontWeight: 800}}>
            {orderCount.totalPrice.toFixed(2)}&euro; 
          </Typography>
        </ListItem>
      </List>
      <Collapse in={lowSum}>
        <Alert severity="error">Užsakymo suma turi būti ne mažesnė už {(config.min_order_price / 100).toFixed(2)}&euro;</Alert>
      </Collapse>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onNextClick}
          disabled={lowSum}
        >
          Į Užsakymą
        </Button>
      </div>
      <EditDialog 
        title={activeProduct?.item.name} 
        open={Boolean(activeProduct)}
        containerClass={classes.detailContainer}
        handleClose={setDetailDialogClosed}
      >
        {activeProduct ? 
          <ProductDetailCard 
            product={activeProduct.item}
            onClose={setDetailDialogClosed}
            onAdd={() => {
              setDetailDialogClosed()
              setAdded(true)
              setTimeout(() => {
                setAdded(false)
              }, 1000)
            }}
          /> : null}
      </EditDialog>
      <Modal 
        open={added}
        disableAutoFocus
        disableEnforceFocus
        hideBackdrop={true}
      >
        <div className={classes.alert}>
          <Alert severity="success">
            Įdėta į krepšelį!
          </Alert>
        </div>
      </Modal>
      <SimpleConfirmDialog 
        open={typeof deleteIndex !== "undefined"}
        title='Perspėjimas'
        handleClose={setConfirmDialogClosed}
        onConfirm={removeFromCart}
      >
        Ar tikrai norite pašalinti šį užsakymą iš krepšelio?
      </SimpleConfirmDialog>
    </React.Fragment>
  )
}

export default CartView