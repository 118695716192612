import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setSigningIn } from "../store/user-actions";
import { SocialAppConfig } from "../store/types";
import { PROVIDERS, makeUseStyles, randomState } from "./common";
import SvgIcon from '@material-ui/core/SvgIcon';
import { Theme, Button, Typography } from '@material-ui/core';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { AppState } from '../store';
import { useLocation } from 'react-router-dom';


const useStyles = makeUseStyles((theme: Theme) => (
  {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    base: {
      width: '100%'
      // [theme.breakpoints.down('sm')]: {
      //   width: '100%',
      // },
      // [theme.breakpoints.up('sm')]: {
      //   width: '50%',
      // },
    },
    facebook: {
      backgroundColor: '#3C5A99',
      border: '0.5px solid #3C5A99',
      '&:hover': {
        backgroundColor: '#3C5A99',
      },
    },
    facebookText: {
      marginLeft: '10px',
      textTransform: 'none',
      fontSize: 16,
      color: 'white',
    },
    "google-oauth2": {
      border: '0.5px solid #aaa',
    },
    "google-oauth2Text": {
      marginLeft: '5px',
      textTransform: 'none',
      fontSize: 16,
    }
  }
))


interface SocLoginProps extends SocialAppConfig {
  loggingIn: boolean
}


export const SocialLoginComponent = ({provider, client_id }: SocLoginProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const remember_me = useSelector<AppState, boolean>(state => state.user.remember_me, shallowEqual)
  const signingIn = useSelector<AppState, boolean>(state => state.user.signingIn, shallowEqual)
  const classes = useStyles()
  const loginState = randomState()

  const startLogin = () => {
    sessionStorage.setItem(`_socauth-state`, JSON.stringify({state: loginState, remember_me: remember_me, provider, redirect: location.pathname}))
    dispatch(setSigningIn(true))
  }

  const signInUri = () => {
    let redirectUri = `${window.location.origin.toString()}`
    switch (provider) {
      case PROVIDERS.FACEBOOK:
        return `https://www.facebook.com/v9.0/dialog/oauth?` +
          `client_id=${client_id}` +
          `&redirect_uri=${redirectUri}` +
          `&state=${loginState}` +
          `&response_type=granted_scopes,token` +
          `&scope=email` +
          `&auth_type=rerequest`
      case PROVIDERS.GOOGLE:
        return `https://accounts.google.com/o/oauth2/v2/auth?` +
          `&client_id=${client_id}` +
          `&scope=profile email` +
          `&include_granted_scopes=true` +
          `&response_type=token` +
          `&state=${loginState}` +
          `&redirect_uri=${redirectUri}`
      default:
        return ``
    }
  } 

  const renderSocialButton = () => {
    switch (provider) {
      case PROVIDERS.FACEBOOK:
        return (
          signingIn ? 
          <Button className={`${classes.base} ${classes[provider]}`} disabled={signingIn}>
            <CircularProgress size={25} /> 
          </Button> :
          <Button className={`${classes.base} ${classes[provider]}`} href={signInUri()} onClick={startLogin}>
          <SvgIcon version="1.1" x="0px" y="0px"
            width="266.893px" height="266.895px" viewBox="0 0 266.893 266.895" enableBackground="new 0 0 266.893 266.895"
            >
            <path id="Blue_1_" fill="#3C5A99" d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812
              c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225
              H248.082z"/>
            <path id="f" fill="#FFFFFF" d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935
              l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585
              v99.803H182.409z"/>
          </SvgIcon>
          <Typography className={classes[`${provider}Text`]}>Prisijungti su Facebook</Typography>
          </Button>
        )
      case PROVIDERS.GOOGLE:
        return (
          signingIn ? 
          <Button className={`${classes.base} ${classes[provider]}`} disabled={signingIn}>
            <CircularProgress size={25} /> 
          </Button> :
          <Button className={`${classes.base} ${classes[provider]}`} href={signInUri()} onClick={startLogin}>
            <SvgIcon x="0px" y="0px" viewBox="0 0 533.5 544.3"
                enableBackground="new 0 0 533.5 544.3">
              <path fill="#4285F4" d="M533.5,278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1,33.8-25.7,63.7-54.4,82.7v68h87.7
                C503.9,431.2,533.5,361.2,533.5,278.4z"/>
              <path fill="#34A853" d="M272.1,544.3c73.4,0,135.3-24.1,180.4-65.7l-87.7-68c-24.4,16.6-55.9,26-92.6,26c-71,0-131.2-47.9-152.8-112.3
                H28.9v70.1C75.1,486.3,169.2,544.3,272.1,544.3z"/>
              <path fill="#FBBC04" d="M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z"/>
              <path fill="#EA4335" d="M272.1,107.7c38.8-0.6,76.3,14,104.4,40.8l0,0l77.7-77.7C405,24.6,339.7-0.8,272.1,0C169.2,0,75.1,58,28.9,150
                l90.4,70.1C140.8,155.6,201.1,107.7,272.1,107.7z"/>
            </SvgIcon>
            <Typography className={classes[`${provider}Text`]}>Prisijungti su Google</Typography>
          </Button>
        )
      default:
        break;
    }
  }

  return renderSocialButton()
}
