import { ErrorAction, fn, voidFn } from "./types"
import { RootAction } from "."
import { useDispatch } from "react-redux"


export const SET_ERROR = "SET_ERROR"

export function addError(
  key: string,
  error: ResponseError | undefined
): ErrorAction {
  return {
    type: SET_ERROR,
    key: key,
    payload: error
  }
}

export const URLS = {
    API: '/api/v1',
    CATEGORIES: '/api/v1/categories/',
    PROFILE: '/api/v1/profile/',
    LOGIN: '/api/v1/login/',
    REFRESH: '/api/v1/refresh/',
    LOGOUT: '/api/v1/logout/',
    VALIDATE: '/api/v1/activate/',
    VALIDATION_EMAIL: '/api/v1/activate-email/',
    RESET: '/api/v1/reset/',
    RESET_EMAIL: '/api/v1/reset-email/',
    SOCIAL: '/api/v1/social/',
    SOCAPPCONFIG: '/api/v1/socappconfig/',
    TIMEDCONFIG: '/api/v1/timedconfig/',
    DISCOUNT_CODES: '/api/v1/discountcodes/',
    DISCOUNT_CODE_VERIFY: '/api/v1/discountcode/verify/',
    USERS: '/api/v1/users/',
    APP_CONFIG: '/api/v1/config/',
    SOCIAL_APPS: '/api/v1/socialapps/',
    ORDERS: '/api/v1/orders/',
    PAYMENT_VERIFY: '/api/v1/payment/verify/',
    PAY: '/api/v1/pay/'
}

export class ResponseError extends Error {
  
  response: any
  status: number
  
  constructor(response: any, status: number, message?: string) {
      super(message);
      Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
      this.name = ResponseError.name; // stack traces display correctly now
      this.response = response;
      this.status = status;
  }
}

export async function sendTokenRefreshRequest() {
  
  const resp = await fetch(`${process.env.REACT_APP_API_HOST || ''}${URLS.REFRESH}`, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors',
    credentials: 'include'
  })
  if (!resp.ok) {
    const errorData = await resp.json()
    throw new ResponseError(errorData, resp.status, resp.statusText)
  }
  return await resp.json()
}

export async function sendRequest(url: string, method: string, body: any = undefined, headers?: any) {
  if (window.token_data) {
    if (window.token_data.expireAt < Date.now() + 1000) {
      try {
        window.token_data = await sendTokenRefreshRequest()
      } catch {
        delete window.token_data
      }
    }
    if (window.token_data) {
      headers = headers || {}
      headers.Authorization = `Bearer ${window.token_data.token}`
    }
  }
  
  const response = await fetch(`${process.env.REACT_APP_API_HOST || ''}${url}`, {
    method,
    body,
    headers,
    mode: 'cors',
    credentials: 'include'
  })
  if (!response.ok) {
    const errorData = await response.json()
    throw new ResponseError(errorData, response.status, response.statusText)
  }
  return response.status === 204 ? undefined : await response.json()
}

export function grecaptchaRequest(action: string, onSuccess?: fn<string | undefined>, onError?: fn) {
  try {
    if (window.grecaptcha) {
      window.grecaptcha.execute({action: action}).then(token => {
        if (onSuccess) onSuccess(token)
      }).catch(err => {
        if (onError) onError(err)
      })
    } else if (onSuccess) onSuccess(undefined)
  } catch (err) {
    if (onError) onError(err)
  }
}

export const useBaseActions = () => {

  const dispatch = useDispatch()

  const baseGet = async (url: string, error: string, action: (payload: any) => RootAction, successCb?: fn, errorCb?: voidFn) => {
    try {
   
      const data = await sendRequest(url, 'GET')
      dispatch(
        action(data)
      )
      if (successCb) successCb(data)
    }
    catch(err) {
      dispatch(
        addError(error, err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }

  const baseUpdate = async (url: string, error: string, action: (id: number | string | undefined, payload: any) => RootAction, data: any, id?: number | string, successCb?: fn, errorCb?: voidFn, headers?: any) => {
    try {
      const isNew = typeof id === "undefined"
      const result = await sendRequest(isNew ? url : `${url}${id}/`, isNew ? 'POST' : 'PATCH', data, headers)
      dispatch(
        action(id, result)
      )
      if (successCb) successCb(result)
    }
    catch(err) {
      dispatch(
        addError(error, err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }

  const baseJSONUpdate = async (url: string, error: string, action: (id: number | string | undefined, payload: any) => RootAction, data: any, id?: number | string, successCb?: fn, errorCb?: voidFn) => {
    baseUpdate(url, error, action, JSON.stringify(data), id, successCb, errorCb, {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    })
  }

  const baseDelete = async (url: string, error: string, action: (id: number | string) => RootAction, id: number | string, successCb?: voidFn, errorCb?: voidFn) => {
    try {
      await sendRequest(`${url}${id}/`, 'DELETE');
      dispatch(
        action(id)
      )
      if (successCb) successCb()
    }
    catch(err) {
      dispatch(
        addError(error, err as ResponseError)
      )
      if (errorCb) errorCb()
    }
  }

  return {
    dispatch: dispatch,
    baseGet: baseGet,
    baseUpdate: baseUpdate,
    baseDelete: baseDelete,
    baseJSONUpdate: baseJSONUpdate
  }
}