import React from 'react';
import { Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { makeUseStyles, useGetAuthState } from './common';
import { useLoginActions } from "../store/user-actions";
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState } from '../store';
import { ResponseError, addError, grecaptchaRequest } from '../store/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertTitle from '@material-ui/lab/AlertTitle';


const useStyles = makeUseStyles((theme: Theme) => (
    {
      root: {
        top: 40,
        position: "relative",
        margin: '0 auto',
        maxWidth: 400,
      },
      form: {
        position: "relative",
        textAlign: "center",
        maxWidth: 300,
        margin: '0 auto',
        padding: 30
      },
      success: {
        padding: 50,
        color: '#00a603',
        backgroundColor: '#dcf7dd',
        border: "2pt solid green",
      },
      successBorder: {
        border: "2pt solid green",
      },
      normalBorder: {
        border: "2pt solid black",
      }
    }
  )
)


export default function Reset() {
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.resetError, shallowEqual)
  const authState = useGetAuthState()
  const [success, setSuccess] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const classes = useStyles()
  let { token } = useParams<{ token: string }>()
  const { dispatch, thunkResetPassword } = useLoginActions()

  const clearResetError = () => {
    dispatch(addError("resetError", undefined))
  }

  const onError = () => {
    setSubmitting(false)
    setPassword("")
    setConfirmPassword("")
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)
    grecaptchaRequest('reset', (ctoken) => {
      thunkResetPassword(password, confirmPassword, token, ctoken, () => {
        setSubmitting(false)
        setSuccess(true)
      }, onError)
    }, (err) => {
      dispatch(addError("resetError", new ResponseError({detail: 'Slaptažodžio pakeisti nepavyko'}, 400)))
      console.log(err)
      onError()
    })
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearResetError()
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearResetError()
    setConfirmPassword(event.target.value)
  }

  const passNotOK = Boolean(password) && password !== confirmPassword

  return (authState.loggedIn ? 
    <Redirect
      to={{
        pathname: "/"
      }}
    /> :
    <div className={classes.root}>
      {success ? 
      <Alert severity="success">
        <AlertTitle>
          Slaptažodis pakeistas sėkmingai!
        </AlertTitle>
        Galite jungtis naudodami naująjį slaptažodį
      </Alert> : 
      <div className={classes.form} >
        {Boolean(error?.response?.detail || error?.response?.token) ?
          <Alert severity="error">{error.response?.detail || error.response?.token}</Alert>
        : null}
        <form id='pass-reset-form' onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            name="password"
            label="Naujas slaptažodis"
            type="password"
            autoComplete="current-password"
            error={Boolean(error?.response?.password)}
            helperText={error?.response?.password?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            name="confirm_password"
            label="Pakartoti slaptažodį"
            type="password"
            autoComplete="current-password"
            error={passNotOK}
            helperText={passNotOK ? 'Must match the password above' : undefined}
          />
          <Button
            fullWidth
            type='submit'
            form='pass-reset-form'
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!Boolean(password) || passNotOK || submitting}
          >
            {submitting ? <CircularProgress size={25}/> : "Pakeisti"}
          </Button>
        </form>
      </div>}
    </div>
  )
}
