import React from 'react';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { EditDialog, ConfirmDialog } from './dialogs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import { Category, voidFn } from '../store/types';
import { Link } from 'react-router-dom';
import { makeUseStyles, useGetAuthState } from './common';
import { AppState } from '../store';
import IconButton from '@material-ui/core/IconButton';
import { useInventoryActions } from '../store/inventory-actions';
import Button from '@material-ui/core/Button';
import { addError, ResponseError } from '../store/common';
import Alert from '@material-ui/lab/Alert';
import { PictureInput } from './inputs';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LazyImage from './image';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useCardStyles = makeUseStyles((theme: Theme) => (
  {
    container: {
      position: "relative"
    },
    card: {
      marginTop: theme.spacing(1),
      textAlign: 'center',
      backgroundColor: theme.palette.background.default,
    },
    action: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: 270
    },
    manageAction: {
      position: "absolute",
      top: 0,
      zIndex: 999,
      cursor: "pointer",
    },
    edit: {
      color: "green",
      backgroundColor: theme.palette.background.default,
      left: 0,
    },
    delete: {
      color: "red",
      backgroundColor: theme.palette.background.default,
      right: 0,
    }
  }
))

interface CardProps { 
  category: Category
  openEditDialog: (title: string, data?: Category | undefined) => void
  openDeleteDialog: (contentText: string, catId: number) => void
}

const CategoryCard = ({category, openEditDialog, openDeleteDialog}: CardProps) =>  {
  const title = "Edit category"
  const authState = useGetAuthState()
  const classes = useCardStyles()

  return (
    <div className={classes.container}>
      <Card 
        className={classes.card} 
        elevation={0}
      >
        <div>
          <CardActionArea className={classes.action} component={Link} to={`/products/${category.id}`}>
            <LazyImage src={category.picture} alt={category.name} maxWidth={250}/>
            <CardContent style={{justifyContent: 'center'}}>
              {authState.isAdmin || authState.isStaff ? <Typography style={{fontSize: 12, fontWeight: 600}}>ID: {category.id}</Typography> : null}
              {(authState.isAdmin || authState.isStaff) && !category.active ? <Typography style={{fontSize: 10, color: "red", fontWeight: 600}}>NEAKTYVUS</Typography> : null}
              <Typography gutterBottom variant="h5" component="h2">
                {category.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {category.info}
              </Typography>
            </CardContent>
          </CardActionArea>
          {authState.isAdmin || authState.isStaff ?
              (<EditIcon
                aria-label="edit"
                className={`${classes.manageAction} ${classes.edit}`}
                onClick={(event) => {
                  event.stopPropagation()
                  openEditDialog(title, {...category})
                }}
              />) : null}
            {authState.isAdmin || authState.isStaff ?
              <DeleteIcon
                aria-label="remove"
                className={`${classes.manageAction} ${classes.delete}`}
                onClick={(event) => {
                  event.stopPropagation()
                  openDeleteDialog(`All products belonging to this category will also be deleted.`, category.id)
                }}
              /> : null}
        </div>
      </Card>
    </div>
  )
}

const useCatGridStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      flexGrow: 1,
    },
    motto1: {
      marginTop: 40,
    },
    motto2: {
      marginBottom: 30,
    },
    container: {
      textAlign: "center",
      width: "100%",
    },
    newIcon: {
      fontSize: "50px"
    },
    formContainer: {
      margin: theme.spacing(4),
      maxWidth: 400,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }
))

const CategoriesGrid = () => {
  const title = `Add new category`
  const { dispatch, thunkUpdateCategory, thunkRemoveCategory } = useInventoryActions()
  const authState = useGetAuthState()
  const classes = useCatGridStyles()
  const categories = useSelector<AppState, Category[] | undefined>(state => state.inventory.categories, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.categoryError, shallowEqual)
  const [activeAction, setActiveAction] = React.useState(false)
  const [editOpen, setEditOpen] = React.useState(false)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  const [dialogTitle, setDialogTitle] = React.useState(title)
  const [activeId, setActiveId] = React.useState<number | undefined>()
  const [picture, setImage] = React.useState<string | undefined>()
  const [name, setName] = React.useState<string>("")
  const [info, setInfo] = React.useState<string>("")
  const [description, setDescription] = React.useState<string>("")
  const [sub, setSub] = React.useState<string>("")
  const [imageFile, setImageFile] = React.useState<File | undefined>()
  const [contentText, setContentText] = React.useState<string | undefined>()
  const [active, setActive] = React.useState(true)
  const [order, setOrder] = React.useState<number | string>("")

  const setEditDialogOpen = (title: string, data?: Category) => {
    setImageFile(undefined)
    setActiveId(data?.id)
    setName(data?.name || "")
    setInfo(data?.info || "")
    setDescription(data?.description || "")
    setSub(data?.sub.join(",") || "")
    setImage(data?.picture)
    setOrder(data?.order || 0)
    setActive(data ? data.active : true)
    setDialogTitle(title)
    setEditOpen(true)
  }

  const setDeleteDialogOpen = (contentText: string, catId: number) => {
    setActiveId(catId)
    setContentText(contentText)
    setDeleteOpen(true)
  }

  const clearError = () => {
    dispatch(addError("categoryError", undefined))
  }

  const setEditDialogClosed = () => {
    clearError()
    setActiveAction(false)
    setEditOpen(false)
  }

  const setDeleteDialogClosed = () => {
    clearError()
    setActiveAction(false)
    setDeleteOpen(false)
  }

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!activeAction) {
      setActiveAction(true)
      const formData = new FormData()
      if (imageFile) formData.append('picture', imageFile)
      formData.append('name', name)
      formData.append('info', info)
      formData.append('description', description)
      formData.append('sub', JSON.stringify(Boolean(sub) ? sub.split(",") : []))
      formData.append('order', String(order))
      formData.append('active', String(active))
      thunkUpdateCategory(formData, activeId, setEditDialogClosed, () => {
        setActiveAction(false)
      })
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setName(event.target.value)
  }

  const handleInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setInfo(event.target.value)
  }

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setDescription(event.target.value)
  }

  const handleSubChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setSub(event.target.value)
  }

  const handlePictureChange = (file: File) => {
    clearError()
    setImageFile(file)
  }

  const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    let num = Number(event.target.value)
    setOrder(isNaN(num) ? "" : num)
  }

  const handleActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActive(event.target.checked)
  }

  const deleteCard = (cb: voidFn) => {
    if (!activeAction && activeId) {
      setActiveAction(true)
      thunkRemoveCategory(activeId, () => {
        cb()
        setDeleteDialogClosed()
      }, () => {
        cb()
        setActiveAction(false)
      })
    }
  }

  return (
    <div>
      {/* <Typography variant="h6" align="center" style={{color: "red", fontWeight: "bold", marginBottom: 30, marginTop: 20}}>
        Balandžio 10&ndash;25 dienomis Canapés.lt komanda atostogauja. Susisiekti galite tik el. paštu gamyba@canapes.lt
      </Typography> */}
      <Typography variant="h4" align="center" className={classes.motto1}>
        Gourmet gali būti ne tik restorane
      </Typography>
      <Typography variant="h5" align="center" className={classes.motto2}>
        Kokybė. Elegancija. Rafinuotumas
      </Typography>
      {typeof categories === 'undefined' && Boolean(error) ? 
        <Alert severity="error">
          Produktai neprieinami. Atsiprašome už nepatogumus
        </Alert> : 
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={4}>
              {categories?.map((value, index) => (
                <Grid key={index} item className={classes.row}>
                  <CategoryCard 
                    category={value} 
                    openEditDialog={setEditDialogOpen} 
                    openDeleteDialog={setDeleteDialogOpen}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>}
      {authState.isAdmin || authState.isStaff ?
      (<div className={classes.container}>
        <IconButton
          onClick={() => {setEditDialogOpen(title)}}
        >
          <AddCircleOutlineOutlinedIcon className={classes.newIcon}/>
        </IconButton>
      </div>) : null}
      <EditDialog 
        title={dialogTitle} 
        open={editOpen}
        containerClass={classes.formContainer}
        handleClose={setEditDialogClosed}
        error={error}
      >
        <form className={classes.form} id='category-form' onSubmit={submit} noValidate>
          <InputLabel htmlFor="category-picture">Category picture</InputLabel>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.picture)}>
            <PictureInput id="category-picture" height={150} maxWidth={270} image={picture} onChange={handlePictureChange}/> 
            <FormHelperText>{error?.response?.picture?.join(' ')}</FormHelperText>
          </FormControl>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={handleNameChange}
            id="category-title"
            label="Pavadinimas"
            name="name"
            value={name}
            autoFocus
            error={Boolean(error?.response?.name)}
            helperText={error?.response?.name}
          />
          <TextField
            variant="outlined"
            margin="normal"
            multiline={true}
            rows={4}
            fullWidth
            onChange={handleInfoChange}
            name="info"
            label="Informacija"
            id="category-info"
            value={info}
            error={Boolean(error?.response?.info)}
            helperText={error?.response?.info?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            multiline={true}
            rows={4}
            fullWidth
            onChange={handleDescriptionChange}
            name="description"
            label="Aprašymas"
            id="category-description"
            value={description}
            error={Boolean(error?.response?.description)}
            helperText={error?.response?.description?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={handleSubChange}
            name="sub"
            label="Grupės"
            id="category-sub"
            value={sub}
            error={Boolean(error?.response?.sub)}
            helperText={error?.response?.sub?.join(' ')}
          />
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.order)}>
            <InputLabel htmlFor="product-quantity-discount">Vieta eilėje</InputLabel>
            <Input
              required
              onChange={handleOrderChange}
              name="order"
              type="number"
              id="category-order"
              value={order}
            />
            <FormHelperText>{error?.response?.order?.join(' ')}</FormHelperText>
          </FormControl>
          <FormControl fullWidth margin='normal' error={Boolean(error?.response?.active)}>
            <FormControlLabel
              control={<Checkbox checked={active} color="primary" onChange={handleActiveChange} name="active" />}
              label="Active"
            />
            <FormHelperText>{error?.response?.active?.join(' ')}</FormHelperText>
          </FormControl>
          <Button
            type="submit"
            form='category-form'
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={activeAction}
          >
            {activeAction ? <CircularProgress size={25} /> : 'Save'}
          </Button>
        </form>
      </EditDialog>
      <ConfirmDialog 
        open={deleteOpen}
        title='Perspėjimas'
        confirmAgainst="delete me"
        handleClose={setDeleteDialogClosed}
        onConfirm={deleteCard}
        error={error}
      >
        {contentText} Type <strong>delete me</strong> and confirm to delete {contentText}
      </ConfirmDialog>
    </div>
  )
}

export default CategoriesGrid