import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { AppConfig, voidFn } from '../store/types';
import { makeUseStyles } from './common';
import { Theme } from '@material-ui/core/styles';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../store';
import Typography from '@material-ui/core/Typography';


const useStyles = makeUseStyles((theme: Theme) => (
  {
    heading: {
      marginTop: 10,
      marginBottom: 5, 
      fontSize: 20,
      fontWeight: 550
    },
    par: {
      marginTop: 10,
      marginBottom: 5, 
      display: "block",
      wordWrap: "break-word"
    },
    subpar: {
      fontWeight: 550,
      marginright: 10
    },
    table: {
      border: '1px solid black',
      borderCollapse: 'collapse',
      width: '100%',
      marginBottom: 20
    },
    row: {
      border: '1px solid black',
      verticalAlign: 'top',
      textAlign: 'left'
    },
    cell: {
      padding: 7,
    }
  }
)
)

export const BusinessRules = () => {
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const classes = useStyles()

  return <React.Fragment>
    <span className={classes.heading}>
      1. Sąvokos
    </span>
    <span className={classes.par}>
      1.1. <strong>Pardavėjas</strong> &ndash; {config.seller.name}.
    </span> 
    <span className={classes.par}>
      1.2. <strong>Pirkėjas</strong> &ndash; fizinis arba juridinis asmuo.
    </span> 
    <span className={classes.par}>
      1.3. <strong>Šalys</strong> &ndash; <strong>Pirkėjas</strong> ir <strong>Pardavėjas</strong> kartu.
    </span> 
    <span className={classes.par}>
      1.4. <strong>Asmens duomenys</strong> &ndash; bet kuri informacija, susijusi su fiziniu asmeniu &ndash; duomenų subjektu, 
      kurio tapatybė yra žinoma arba gali būti tiesiogiai ar netiesiogiai nustatyta pasinaudojant tokiais duomenimis kaip asmens kodas, 
      vienas arba keli asmeniui būdingi fizinio, fiziologinio, psichologinio, ekonominio, kultūrinio ar socialinio pobūdžio požymiai.
    </span>
    <span className={classes.par}>
      1.5. <strong>Taisyklės</strong> &ndash; šios „Paslaugų teikimo taisyklės“.
    </span>
    <span className={classes.par}>
      1.6. <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politika</a> &ndash; <strong>Pardavėjo</strong> patvirtintas dokumentas, 
      kuriame yra numatytos pagrindinės <strong>Asmens duomenų</strong> rinkimo, kaupimo, 
      tvarkymo ir laikymo taisyklės, naudojantis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.heading}>
      2. Bendrosios nuostatos
    </span>
    <span className={classes.par}>
      2.1. <strong>Pirkėjas</strong> patvirtina <strong>Taisykles</strong> su jomis susipažinęs 
      bei pažymėjęs varnelę prie teiginio <em>„Patvirtinu, kad susipažinau ir sutinku su paslaugų teikimo sąlygomis ir privatumo politika”</em>. 
      Tokiu būdu patvirtintos <strong>Taisyklės</strong> yra <strong>Šalims</strong> privalomas teisinis dokumentas, 
      kuriame nustatomos <strong>Pirkėjo</strong> ir <strong>Pardavėjo</strong> teisės bei pareigos, 
      prekių/paslaugų įsigijimo bei apmokėjimo už jas sąlygos, prekių pristatymo, 
      atsimėmimo ir indų gražinimo tvarka, šalių atsakomybė bei kitos su prekių/paslaugų 
      pirkimu&ndash;pardavimu <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> susijusios sąlygos.
    </span> 
    <span className={classes.par}>
      2.2. Pirkti <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> turi teisę tik <strong>Pirkėjai</strong>, kaip jie 
      apibrėžti <strong>Taisyklių 1.2</strong> punkte. <strong>Pirkėjas</strong>, patvirtindamas <strong>Taisykles</strong> ir 
      susipažinęs su <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politika</a> (<strong>Taisyklių 2.4</strong> punktas), 
      patvirtina, kad jis turi teisę pirkti prekes/paslaugas <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      2.3. Iškilus būtinybei ar esant Lietuvos Respublikos teisės aktuose numatytoms 
      aplinkybėms, <strong>Pardavėjas</strong> turi teisę pakeisti, taisyti ar 
      papildyti <strong>Taisykles</strong>. Apie tai <strong>Pirkėjai</strong> bus informuojami prisijungę prie <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span> 
    <span className={classes.par}>
      2.4. <strong>Pirkėjas</strong> privalo susipažinti su <strong>Pardavėjo</strong> patvirtinta 
      ir viešai paskelbta <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politika</a>. Sutikimą arba nesutikimą su 
      konkrečiais <strong>Pirkėjo</strong> <strong>Asmens duomenų</strong> panaudojimo 
      būdais <strong>Pirkėjas</strong> išreiškia <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikoje</a> numatyta tvarka.
    </span> 
    <span className={classes.heading}>
      3. Prekių užsakymas
    </span>
    <span className={classes.par}>
      3.1. Prekių užsakymas yra pirkimo&ndash;pardavimo teisinių santykių sukūrimo momentas.
    </span>
    <span className={classes.par}>
      3.2. <strong>Pirkėjas</strong> gali užsisakyti prekes/paslaugas internetinėje svetainėje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      3.3. <strong>Pirkėjas</strong>, užsisakydamas prekes vienu iš <strong>Taisyklių 3.2</strong>. 
      punkte nurodytų būdų, atitinkamuose <strong>Pardavėjo</strong> pateiktuose informacijos 
      laukuose privalo nurodyti <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikoje</a> numatytus tinkamam prekių užsakymo 
      įvykdymui būtinus savo <strong>Asmens duomenis</strong>.
    </span> 
    <span className={classes.par}>
      3.4. Kai <strong>Pirkėjas</strong>, išsirinkęs perkamą prekę ar paslaugą ir suformavęs prekių krepšelį, 
      įvykdo visus užsakymo žingsnius, kurių paskutinis yra Užsakyti, <strong>Pardavėjas</strong> patvirtinęs 
      užsakymą <strong>Pirkėją</strong> informuos <strong>Pirkėjo</strong> nurodytu 
      el. paštu, kad užsakymas yra vykdomas. Vykdymui patvirtinti <strong>Pirkėjas</strong> per 
      3 kalendorines dienas sumoka avansinį mokėjimą nurodytą patvirtinimo laiške bei išsiunčia mokėjimo patvirtinimą. 
      Įvykdžius mokėjimą bankiniu pavedimu, laikoma, kad tarp <strong>Pardavėjo</strong> ir <strong>Pirkėjo</strong> atsirado 
      pirkimo&ndash;pardavimo teisiniai santykiai ir sudaryta pirkimo&ndash;pardavimo sutartis.
    </span> 
    <span className={classes.par}>
      3.5. <strong>Pirkėjo</strong> užsakymas saugomas <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> duomenų bazėje.
    </span>
    <span className={classes.heading}>
      4. <strong>Pirkėjo</strong> teisės
    </span> 
    <span className={classes.par}>
      4.1. <strong>Pirkėjas</strong> turi teisę pirkti prekes bei užsisakyti paslaugas <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> šių <strong>Taisyklių</strong> nustatyta tvarka.
    </span> 
    <span className={classes.par}>
      4.1.1. Užsakymai priimami likus 3 (trims) dienoms iki renginio. Kitas būdas yra užsakinėti rinkinius, tuomet juos galima pagaminti ir sekančią dieną.
    </span> 
    <span className={classes.par}>
      4.1.2. Užsakymai vykdomi <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> puslapyje susidedant prekių krepšelį ir jį užsakant. 
      Į užsakymo užklausą atsakome kaip įmanoma greičiau. 
      Kitas būdas &ndash; susisiekti el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> arba 
      telefonu <a href={`tel:${config.production.tel}`}>{config.production.tel}</a>.
    </span> 
    <span className={classes.par}>
      4.1.3. Mūsų kainoraščiai pasiekiami puslapyje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      4.1.4. Indams ir kitai <strong>Pirkėjo</strong> atsakomybei kartu su užkandžiais perduodamai įrangai 
      taikomas 50 (penkiasdešimties) eurų nuomos depozitas. Grąžinus indus, jis atiduodamas atgal. 
      Indai nuomojami 4 (keturių) kalendorinių dienų laikotarpiui. 
      Negrąžinus indų per nurodytą laiką, Klientui negrąžinamas depozitas, 
      o už kiekvieną papildomą pradelstą dieną mokamas 25 eurų indų nuomos mokestis.
    </span> 
    <span className={classes.par}>
      4.1.4.1. Klientas taip pat sutinka ir prisiima pilną atsakomybę už tinkamą indų ar kitos perduotos įrangos naudojimą, 
      o ją sugadinęs sutinka padengti visus tiesioginius nuostolius.
    </span> 
    <span className={classes.par}>
      4.1.5. Pasiūlymą renginiui galite gauti susisieke el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> arba 
      telefonu <a href={`tel:${config.production.tel}`}>{config.production.tel}</a>.
    </span>
    <span className={classes.par}>
      4.1.6. Užsakytą produkciją, jei jos <strong>Pardavėjas</strong> nepristato į vietą, 
      galite atsiimti {config.production.address}, {config.production.city}.
    </span> 
    <span className={classes.par}>
      4.1.7. Pristatant produkciją į renginio vietą, serviruojame <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> induose. 
      Visa atsakomybė už tinkamą jų naudojimą tenka <strong>Pirkėjui</strong> ir 
      toliau galioja <strong>Taisyklių 4.1.4.1</strong> punkte numatytos sąlygos.
    </span>
    <span className={classes.par}>
      4.1.8. Maiste esantys alergenai neišvengiami, tad apie kiekvieną produktą teiraukitės <strong>Pardavėjo</strong>. 
      Alergiją ar netoleravimą sukeliančių medžiagų, kurias galima aptikti ir mūsų gaminuose, 
      sąrašą rasite internetiniame puslapyje prie kiekvieno produkto aprašymo.
    </span>
    <span className={classes.heading}>
      5. <strong>Pirkėjo</strong> įsipareigojimai
    </span> 
    <span className={classes.par}>
      5.1. <strong>Pirkėjas</strong>, naudodamasis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>, privalo vykdyti savo įsipareigojimus, 
      laikytis šių <strong>Taisyklių</strong>, <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikos</a>, 
      kitų sąlygų, aiškiai nurodytų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>, bei nepažeisti Lietuvos Respublikos teisės aktų.
    </span>
    <span className={classes.par}>
      5.2. <strong>Pirkėjas</strong> privalo sumokėti už užsisakytas prekes ar paslaugas ir priimti jas šių <strong>Taisyklių</strong> nustatyta tvarka. 
      Pasirinkęs atsiimti prekes Gamybos ceche, <strong>Pirkėjas</strong> privalo jas atsiimti numatytu laiku. 
      Pasikeitus sąlygoms, privalo susisiekti el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> ar 
      telefonu <a href={`tel:${config.production.tel}`}>{config.production.tel}</a>.
    </span> 
    <span className={classes.heading}>
      6. <strong>Pardavėjo</strong> teisės
    </span>
    <span className={classes.par}>
      6.1. <strong>Pardavėjas</strong> turi teisę keisti, sustabdyti ar nutraukti tam tikrų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> funkcijų veikimą ar dalį jų, 
      taip pat keisti <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> esančių elementų išdėstymą.
    </span>
    <span className={classes.par}>
      6.2. <strong>Pardavėjas</strong> turi teisę sustabdyti arba nutraukti <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> veiklą. 
      Tokiu atveju, visi priimti ir patvirtinti <strong>Pirkėjų</strong> užsakymai užbaigiami vykdyti, 
      o nauji užsakymai nėra priimami.
    </span> 
    <span className={classes.par}>
      6.3. Esmingai pasikeitus paslaugų teikimo sąlygoms 
      (pvz., rinkos sąlygų, teisės aktų pasikeitimas ir pan.), <strong>Pardavėjas</strong> turi teisę keisti paslaugų, 
      teikiamų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>, teikimo apimtį ar būdą, 
      sustabdyti, nutraukti paslaugų ar jų dalies teikimą, apmokestinti paslaugas ar 
      paslaugų dalį. <strong>Pirkėjui</strong> išreiškus nesutikimą, jo užsakymas anuliuojamas, avansinis mokėjimas grąžinamas.
    </span>
    <span className={classes.par}>
      6.4. Jei <strong>Pirkėjas</strong> bando pakenkti <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> darbo stabilumui, 
      saugumui ar nevykdo savo įsipareigojimų, <strong>Pardavėjas</strong> turi teisę nedelsiant 
      ir be išankstinio įspėjimo apriboti arba sustabdyti <strong>Pirkėjo</strong> galimybę naudotis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span> 
    <span className={classes.par}>
      6.5. <strong>Pardavėjas</strong> turi teisę iš anksto neįspėjęs <strong>Pirkėjo</strong> anuliuoti jo užsakymą, 
      jeigu <strong>Pirkėjas</strong>, pasirinkęs nevykdo <strong>Taisyklių 3.4</strong> punkto, 
      t.y. nesumoka už prekes per 3 (tris) kalendorines dienas.
    </span>
    <span className={classes.par}>
      6.6. <strong>Pirkėjui</strong> neatlikus veiksmų nurodytų <strong>Taisyklių 3.4</strong> punkte 
      ar iškilus neaiškumams dėl užsakyme pateiktos informacijos, <strong>Pardavėjas</strong> turi 
      teisę susisiekti su <strong>Pirkėju</strong> užsakyme nurodytais rekvizitais.
    </span> 
    <span className={classes.par}>
      6.7. <strong>Pardavėjas</strong> turi teisę keisti su <strong>Pirkėjo</strong> sutikimu jo pasirinktų įsigyti prekių krepšelio turinį, 
      jį pildyti ar koreguoti. Kainai pasikeitus <strong>Pirkėjas</strong> bus informuojamas el. paštu, 
      kuriame bus atnaujintas prekių krepšelis.
    </span>
    <span className={classes.par}>
      6.8. <strong>Pardavėjas</strong> turi kitų teisių, įtvirtintų 
      šiose <strong>Taisyklėse</strong>, <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikoje</a>, 
      kituose <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> dokumentuose 
      ir Lietuvos Respublikos teisės aktuose.
    </span>
    <span className={classes.heading}>
      7. <strong>Pardavėjo</strong> įsipareigojimai
    </span> 
    <span className={classes.par}>
      7.1. <strong>Pardavėjas</strong> įsipareigoja šiose <strong>Taisyklėse</strong> ir 
      kitų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> dokumentų 
      nustatytomis sąlygomis sudaryti <strong>Pirkėjui</strong> galimybę 
      naudotis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> teikiamomis paslaugomis.
    </span> 
    <span className={classes.par}>
      7.2. <strong>Pardavėjas</strong> įsipareigoja aiškiai ir suprantamai <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> suteikti <strong>Pirkėjui</strong> informaciją, 
      įtvirtintą Lietuvos Respublikos Civilinio kodekso 6.2287 straipsnyje.
    </span> 
    <span className={classes.par}>
      7.3. <strong>Pardavėjas</strong> įsipareigoja gerbti <strong>Pirkėjo</strong> privatumą, <strong>Pirkėjo Asmens duomenis</strong> tvarkyti 
      tik <strong>Taisyklių</strong>, <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikos</a> ir Lietuvos Respublikos teisės aktų nustatyta tvarka.
    </span>
    <span className={classes.par}>
      7.4. Iki užsakymo pateikimo informuoti <strong>Pirkėją</strong> apie užsakymo įvykdymui reikšmingų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> funkcijų sustabdymą 
      ar nutraukimą, taip pat <strong>Taisyklių 6.2&ndash;6.3</strong> punktuose nurodytus pakeitimus. 
      Informacijos pateikimas <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> laikomas tinkamu informavimu. <strong>Pardavėjui</strong> jau priėmus 
      vykdyti <strong>Pirkėjo</strong> užsakymą, apie šio užsakymo 
      įvykdymui reikšmingų <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> funkcijų sustabdymą ar nutraukimą <strong>Pirkėjas</strong> informuojamas 
      vienu iš <strong>Pirkėjo</strong> nurodytų kontaktinių duomenų (telefonu, arba el. paštu).
    </span> 
    <span className={classes.par}>
      7.5. Taisyklių numatytomis sąlygomis <strong>Pardavėjas</strong> įsipareigoja 
      pateikti <strong>Pirkėjo</strong> užsakytas ir priimti <strong>Pirkėjo</strong> grąžinamas prekes.
    </span>
    <span className={classes.par}>
      7.5.1. Dėl parduodamų prekių specifikos, jos atgal negrąžinamos ir nepriimamos.
    </span>
    <span className={classes.par}>
      7.6. Jei <strong>Pardavėjas</strong> dėl svarbių aplinkybių negali 
      pristatyti <strong>Pirkėjui</strong> užsakytos prekės ar paslaugos, 
      jis įsipareigoja pasiūlyti <strong>Pirkėjui</strong> analogišką ar 
      kiek įmanoma savo savybėmis panašesnę prekę ar paslaugą. <strong>Pirkėjui</strong> atsisakius priimti 
      prekę ar paslaugą, kuri buvo siūloma kaip analogiška arba panaši, <strong>Pardavėjas</strong> įsipareigoja 
      grąžinti <strong>Pirkėjui</strong> sumokėtus pinigus per 14 (keturiolika) darbo dienų, 
      jeigu buvo atliktas išankstinis apmokėjimas, ir visais atvejais atšaukti užsakymą.
    </span>
    <span className={classes.par}>
      7.7. <strong>Pardavėjas</strong>, nesutikdamas su <strong>Pirkėjo</strong> reikalavimais, 
      privalo ne vėliau kaip per 14 (keturiolika) kalendorinių dienų nuo <strong>Pirkėjo</strong> kreipimosi gavimo dienos, 
      jeigu Lietuvos Respublikos ir Europos Sąjungos teisės aktai nenustato kitaip, pateikti vartotojui išsamų motyvuotą rašytinį atsakymą.
    </span> 
    <span className={classes.par}>
      7.8. <strong>Pardavėjas</strong> įsipareigoja vykdyti 
      kitas <strong>Taisyklėse</strong>, <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politikoje</a> ir 
      Lietuvos Respublikos teisės aktuose <strong>Pardavėjui</strong> keliamas pareigas.
    </span>
    <span className={classes.heading}>
      8. Prekių kainos, apmokėjimo tvarka ir terminai
    </span>
    <span className={classes.par}>
      8.1. Prekių kainos <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> suformuotame užsakyme nurodomos eurais, 
      įskaitant tuo metu pagal teisės aktus galiojantį PVM dydį.
    </span>
    <span className={classes.par}>
      8.2. Už užsisakytas prekes <strong>Pirkėjas</strong> gali atsiskaityti vienu iš šių būdų:
    </span>
    <span className={classes.par}>
      8.2.1. Banko pavedimu.
    </span>
    <span className={classes.par}>
      8.2.1. Elektronine bankininkyste arba kreditine kortele.
    </span> 
    <span className={classes.par}>
      8.2.2. Grynaisiais pinigais pristatymo/atsiėmimo metu.
    </span>
    <span className={classes.par}>
      8.3. Kai <strong>Pardavėjas</strong> gauna apmokėjimą už prekes patvirtinamas prekių ar paslaugų užsakymas 
      ir pradedamas skaičiuoti prekių pristatymo/atsiėmimo terminas.
    </span> 
    <span className={classes.par}>
      8.4. <strong>Pirkėjas</strong>, patvirtindamas <strong>Taisykles</strong>, sutinka, 
      kad prekių pirkimo dokumentai &ndash; sąskaitos už suteiktas paslaugas būtų pateikiamos elektroniniu būdu.
    </span> 
    <span className={classes.par}>
      8.5. Prekių kaina po to, kai <strong>Pardavėjas</strong> patvirtino užsakymą, 
      keistis negali, išskyrus atvejus, kai prekės kaina pakito dėl informacinių sistemų techninės klaidos, 
      akivaizdžių (apsirikimo pobūdžio) klaidų ištaisymo ar kitų nuo <strong>Pardavėjo</strong> nepriklausančių 
      objektyvių esminių priežasčių (esant šias priežastis pagrindžiantiems įrodymams). 
      Jei tokiu atveju <strong>Pirkėjas</strong> nesutinka įsigyti prekę nauja kaina, <strong>Pirkėjas</strong> gali 
      atsisakyti užsakymo informuodamas apie tai <strong>Pardavėją</strong> per 
      2 (dvi) darbo dienas. Anuliavus užsakymą šiame punkte numatyta tvarka, <strong>Pirkėjui</strong> grąžinamos 
      visos jo sumokėtos sumos.
    </span>
    <span className={classes.par}>
      8.6. Prekių krepšeliui, kurį sudarančių visų prekių bendra kainų suma nesiekia {(config.min_order_price / 100).toFixed(2)} eurų, 
      pristatymas negalimas be atskiro šalių sutarimo.
    </span>
    <span className={classes.heading}>
      9. Prekių pristatymas
    </span>
    <span className={classes.par}>
      9.1. Užsakydamas prekes <strong>Pirkėjas</strong> gali pasirinkti vieną iš prekių atsiėmimo ar pristatymo būdų.
    </span>
    <span className={classes.par}>
      9.2. Jei <strong>Pirkėjas</strong> užsakymo metu pasirenka prekių pristatymo ir serviravimo į namus paslaugą:
    </span>
    <span className={classes.par}>
      9.2.1. <strong>Pirkėjas</strong> įsipareigoja nurodyti tikslią prekių pristatymo vietą, datą ir tikslų laiką.
    </span>
    <span className={classes.par}>
      9.2.2. <strong>Pirkėjas</strong> įsipareigoja prekes ar paslaugas priimti pats ar įpareigoti kitą asmenį, 
      prieš tai suderinus jo kontaktus el. paštu ar telefonu. <strong>Pirkėjas</strong> neturi teisės 
      reikšti <strong>Pardavėjui</strong> pretenzijų dėl prekių pristatymo netinkamam asmeniui.
    </span>
    <span className={classes.par}>
      9.2.3. Prekes pristato <strong>Pardavėjas</strong> arba jo įgaliotas atstovas.
    </span>
    <span className={classes.par}>
      9.3. Jei <strong>Pirkėjas</strong> užsakymo metu pasirenka prekių atsiėmimą <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> ceche 
      esančiame {config.production.address}, {config.production.city}:
    </span>
    <span className={classes.par}>
      9.3.1. Užsakytas prekes būtina atsiimti ne vėliau kaip per 1 (vieną) valandą nuo užsakyme pateikto atsiėmimo laiko. 
      Visą informaciją apie užsakymą pateikiame el. paštu po užsakymo patvirtinimo.
    </span>
    <span className={classes.par}>
      9.3.2. Prekes gali atsiimti ne tik užsakyme nurodytas gavėjas bet ir užsakymo pateikimo metu nurodytas kitas asmuo.
    </span>
    <span className={classes.par}>
      9.4. <strong>Pardavėjas</strong> pateikia prekes <strong>Pirkėjui</strong> vadovaudamasis 
      prekių aprašymuose nurodytais terminais. Šie terminai yra preliminarūs.
    </span>
    <span className={classes.par}>
      9.5. <strong>Pardavėjas</strong> atleidžiamas nuo atsakomybės už prekių pateikimo terminų pažeidimą, 
      jeigu prekės <strong>Pirkėjui</strong> nėra pateikiamos arba pateikiamos ne laiku dėl trečiųjų asmenų, 
      nesusijusių ir/arba nepriklausomų nuo <strong>Pardavėjo</strong> kaltės arba dėl 
      nuo <strong>Pirkėjo</strong> priklausančių aplinkybių.
    </span>
    <span className={classes.par}>
      9.6. Prekių pateikimo <strong>Pirkėjui</strong> metu <strong>Pirkėjas</strong> privalo 
      kartu su <strong>Pardavėju</strong> arba jo įgaliotu atstovu patikrinti 
      produkcijos būklę bei ją sutikrinti su užsakymu, kuris buvo išsiųstas el. paštu.
    </span>
    <span className={classes.par}>
      9.7. Prekių atsitiktinio žuvimo ar jų sugedimo rizika pereina <strong>Pirkėjui</strong> nuo to momento, 
      kai prekė perduota <strong>Pirkėjui</strong>.
    </span>
    <span className={classes.par}>
      9.8. Jei <strong>Pirkėjas</strong> neatsiima prekių numatytu laiku ar jų nepavyksta 
      įteikti <strong>Pirkėjui</strong> ir <strong>Pirkėjas</strong> nebuvo sumokėjęs už prekes, 
      tokios prekės grąžinamos <strong>Pardavėjui</strong>, 
      o užsakymas laikomas įvykdytu ir išrašoma pilna sąskaitą faktūra už suteiktas paslaugas.
    </span>
    <span className={classes.heading}>
      10. Prekių bei paslaugų kokybė
    </span>
    <span className={classes.par}>
      10.1. Kiekvienos <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> parduodamos gaminio savybės nurodomos prie kiekvienos prekės esančiame prekės aprašyme.
    </span>
    <span className={classes.par}>
      10.2. <strong>Pardavėjo</strong> siūlomi įsigyti gaminiai yra tinkamos kokybės, 
      t.y. prekių savybės atitinka prekės aprašymą. Prekė atitinka vartojimo pirkimo&ndash;pardavimo sutartį, jei:
    </span>
    <span className={classes.par}>
      10.2.1. Prekė atitinka <strong>Pardavėjo</strong> pateiktą aprašymą ir turi tokias savybes kaip ir prekė, 
      kurią <strong>Pardavėjas</strong> pateikė kaip pavyzdį ar modelį reklamuodamas tą prekę <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      10.2.2. Prekė atitinka kokybės rodiklius, kurie paprastai yra būdingi to paties 
      pobūdžio prekėms ir kurių <strong>Pirkėjas</strong> gali pagrįstai tikėtis pagal prekės pobūdį ir 
      daikto gamintojo, jo atstovo ar <strong>Pardavėjo</strong> viešai 
      paskelbtus pareiškimus, įskaitant reklamą ir daiktų ženklinimą, dėl daikto konkrečių savybių.
    </span>
    <span className={classes.par}>
      10.2.3. <strong>Pardavėjas</strong> neatsako už tai, kad <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> esančios prekės savo dydžiu, 
      forma, spalva, ar kitais parametrais gali neatitikti realaus prekių dydžio, formų, 
      spalvos ar kitų parametrų dėl <strong>Pirkėjo</strong> naudojamo vaizduoklio ypatybių ar 
      kitų techninių priežasčių, nepriklausančių 
      nuo <strong>Pardavėjo</strong> valios. <strong>Pirkėjui</strong> rekomenduojama perskaityti prekės aprašymą.
    </span>
    <span className={classes.par}>
      10.3. <strong>Pardavėjas</strong> skirtingoms prekių rūšims suteikia tam tikrą laiką 
      galiojančią kokybės garantiją, kurios konkretus terminas ir kitos sąlygos nurodomi užsakymo patvirtimo laiške, 
      kurį <strong>Pirkėjas</strong> gavo patvirtinus užsakymą.
    </span>
    <span className={classes.heading}>
      11. Teisė atsisakyti pirkimo&ndash;pardavimo sutarties
    </span>
    <span className={classes.par}>
      11.1. <strong>Pirkėjas</strong> nenurodydamas priežasties per 3 (tris) dienas turi teisę atsisakyti prekių ar 
      paslaugų pirkimo&ndash;pardavimo sutarties pranešdamas apie tai <strong>Pardavėjui</strong>. 
      Jei buvo sumokėtas avansas, šis avansinis mokėjimas negražinamas.
    </span> 
    <span className={classes.par}>
      11.2. Apie pirkimo&ndash;pardavimo sutarties atsisakymą <strong>Pirkėjas</strong> <strong>Pardavėjui</strong> praneša vienu iš šių būdų: 
      Pranešimas dėl sutarties atsisakymo siunčiamas el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a>. 
      Gavęs <strong>Pirkėjo</strong> pranešimą, <strong>Pardavėjas</strong> nedelsdamas išsiunčia patvirtinimą apie pranešimo gavimą.
    </span>
    <span className={classes.par}>
      11.3. Jeigu <strong>Pirkėjas</strong> atsisakė pirkimo&ndash;pardavimo sutarties kol užsakymas dar nebuvo 
      pradėtas vykdyti, <strong>Pardavėjas</strong> tokį <strong>Pirkėjo</strong> atsisakymą įformina kaip užsakymo atmetimą ir 
      atitinkamai informuoja apie tai <strong>Pirkėją</strong> jo nurodytu elektroninio pašto adresu.
    </span>
    <span className={classes.par}>
      11.4. Jeigu <strong>Pirkėjas</strong> atsisakė pirkimo&ndash;pardavimo sutarties po to, 
      kai prekė ar paslauga jau buvo pagaminta, 
      arba jis ją buvo atsiėmęs, taikomos <strong>Taisyklių 9.8</strong> punkte numatytos nuostatos.
    </span>
    <span className={classes.par}>
      11.5. Jei <strong>Pirkėjui</strong> buvo pristatytos ne tos prekės, <strong>Pirkėjas</strong> privalo nedelsdamas, 
      bet ne vėliau nei per 2 (dvi) valandas, informuoti apie tai <strong>Pardavėją</strong> el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> arba paskambinęs 
      telefono numeriu <a href={`tel:${config.production.tel}`}>{config.production.tel}</a>. <strong>Pardavėjas</strong> savo 
      sąskaita įsipareigoja pasiimti tokias prekes ir kompensuoti tiesioginius nuostolius, bet nedaugiau, nei suma už kurią buvo nupirkta prekė.
    </span>
    <span className={classes.heading}>
      12. Atsakomybė
    </span>
    <span className={classes.par}>
      12.1. <strong>Pirkėjas</strong> atsako už veiksmus, atliktus naudojantis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      12.2. <strong>Pardavėjas</strong> atleidžiamas nuo bet kokios atsakomybės tais atvejais, 
      kai nuostoliai ar žala kyla dėl to, jog <strong>Pirkėjas</strong>, neatsižvelgdamas į <strong>Pardavėjo</strong> rekomendacijas 
      ir savo įsipareigojimus, nesusipažino su šiomis Taisyklėmis, <a target="_blank" rel="noreferrer" href={`${window.location.origin.toString()}/privacy`}>Privatumo politika</a>, 
      bei informacija, kuri buvo pateikta el. paštu, nors tokia galimybė jam buvo suteikta.
    </span>
    <span className={classes.par}>
      12.3. Jei <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> pateiktos nuorodos į kitų trečiųjų asmenų tinklapius, 
      tai <strong>Pardavėjas</strong> negarantuoja, kad informacija, kurią galima peržiūrėti 
      paspaudus šias nuorodas, yra teisinga, išsami ar tiksli. 
      Už trečiųjų asmenų pateikiamos informacijos turinį, jos teisingumą, išsamumą ir tikslumą atsakingi tretieji 
      asmenys. <strong>Pardavėjas</strong> nėra įpareigotas patikrinti perduodamą ar saugomą išorinę informaciją arba nustatyti neteisėtus veiksmus.
    </span> 
  </React.Fragment>
}


export const PrivacyPolicy = () => {
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const classes = useStyles()

  return <React.Fragment>
    <span className={classes.par}>
      Asmens duomenų tvarkymo taisyklių (toliau Taisyklės) tikslas: reglamentuoti Asmens duomenų tvarkymą, 
      atliekamą {config.seller.name} elektroninės parduotuvės <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> veikloje, ir numatyti, 
      kad Asmens duomenys būtų tvarkomi sutinkamai su Bendrojo asmens duomenų apsaugos reglamento (BDAR), 
      Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo, taip pat kituose teisės aktuose nustatytų asmens duomenų tvarkymo reikalavimais.
      Taisyklės reglamentuoja {config.seller.name} veiksmus automatiniu ir neautomatiniu būdu tvarkant duomenų subjektų Asmens duomenis, 
      asmens duomenų tvarkymo tikslus, teikimo šaltinius, taip pat nustato Duomenų subjektų (Pirkėjų), 
      kurių duomenis tvarko {config.seller.name}, teises, asmens duomenų apsaugos pažeidimo rizikos veiksnius, 
      asmens duomenų apsaugos įgyvendinimo priemones ir kitus su asmens duomenų tvarkymu susijusius klausimus. {config.seller.name} užtikrina, 
      kad Asmens duomenys tvarkomi teisėtu, sąžiningu ir skaidriu būdu, 
      renkami tik šiose Taisyklėse nustatytais ir aiškiai apibrėžtais tikslais bei toliau nėra tvarkomi su tais tikslais nesuderinamu būdu. 
      Tvarkomi Asmens duomenys yra adekvatūs, tinkami ir tik tokie, kokie reikalingi nustatytiems tikslams pasiekti, taip pat tikslūs, 
      o prireikus atnaujinami ir saugojami šiose Taisyklėse nustatyta tvarka bei terminais. {config.seller.name} taikydamas 
      organizacines ir technines priemones užtikrina tinkamą Asmens duomenų saugumą, 
      įskaitant apsaugą nuo duomenų tvarkymo be leidimo arba neteisėto duomenų tvarkymo ir nuo netyčinio praradimo, sunaikinimo, ar sugadinimo. 
      Tais atvejais, kuomet atliekant Taisyklėse numatytus veiksmus yra tvarkomi duomenys, nesudarantys Asmens duomenų, Taisyklės nėra taikomos. 
      Taisyklės yra privalomos visiems elektroninės 
      parduotuvės <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> Pirkėjams.
      Šios Taisyklės yra skelbiamos viešai elektroninės 
      parduotuvės <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> interneto svetainėje.
    </span>
    <span className={classes.heading}>
      1. Pagrindinės sąvokos
    </span>
    <span className={classes.par}>
      1.1. Elektroninė parduotuvė &ndash; ši elektroninė parduotuvė, esanti adresu <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      1.2. Pardavėjas &ndash; {config.seller.name}. 
      Buveinės adresas: {config.seller.address}, {config.seller.zip}, {config.seller.city}, juridinio asmens kodas {config.seller.code}.
    </span>
    <span className={classes.par}>
      1.3. www.canapes.lt &ndash; elektroninė parduotuvė, esanti adresu <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      1.4. Pirkėjas &ndash; 1) veiksnus fizinis asmuo, t.y. asmuo, sulaukęs pilnametystės, kurio veiksnumas nėra apribotas teismo tvarka; 
      2) nepilnametis nuo keturiolikos iki aštuoniolikos metų amžiaus, kuris turi tėvų arba rūpintojų sutikimą, išskyrus tuos atvejus, 
      kai jis yra emancipuotas; 3) juridinis asmuo; 4) visų aukščiau nurodytų asmenų tinkamai įgalioti atstovai. 
      Asmuo, kuris įsigyja arba užsisako prekių Elektroninėje parduotuvėje ir yra galutinis jų vartotojas. 
      Šių Taisyklių apimtyje Pirkėjas, kurio asmens duomenis tvarko Pardavėjas laikomas, ne tik tas, kuris užsiregistravo elektroninėje 
      parduotuvėje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> ir/ar įsigijo prekę, 
      bet ir tas, kuris perka prekę neužsiregistravęs, taip pat, tas, kuris nesukūręs paskyros užsisakė Naujienlaiškį Tiesioginės rinkodaros tikslu.
    </span>
    <span className={classes.par}>
      1.5. Asmens duomenys &ndash; Pirkėjo asmens duomenys, kuriuos Pirkėjas pateikia registruodamasis arba užsakydamas prekes Elektroninėje parduotuvėje, 
      ir Pirkėjo įsigytų prekių istorija, jei tokiems duomenims tvarkyti taikomi  
      Bendrojo asmens duomenų apsaugos reglamento (BDAR), Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo, 
      taip pat kituose teisės aktuose nustatytų asmens duomenų tvarkymo reikalavimai.
    </span>
    <span className={classes.par}>
      1.6. Duomenų tvarkymas &ndash; bet kuris su Asmens duomenimis atliekamas veiksmas: rinkimas, užrašymas, kaupimas, saugojimas, 
      klasifikavimas, grupavimas, jungimas, keitimas (papildymas ar taisymas), teikimas, paskelbimas, naudojimas, loginės ir (arba) 
      aritmetinės operacijos, paieška, skleidimas, naikinimas ar kitoks veiksmas arba veiksmų rinkinys.
    </span>
    <span className={classes.par}>
      1.7. Asmens duomenų valdytojas &ndash; {config.seller.name}, buveinės 
      adresas: {config.seller.address}, {config.seller.zip}, {config.seller.city}, juridinio asmens 
      kodas {config.seller.code}.
    </span>
    <span className={classes.par}>
      1.8. Slapukas &ndash; tai nedidelis failas, kuris siunčiamas į įrenginį, bet kuriam asmeniui besilankant <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>. 
      Ši sąvoka apima ne tik slapukus, bet ir panašių priemonių naudojimą.
    </span>
    <span className={classes.par}>
      1.9. Tiesioginė rinkodara &ndash; veikla, skirta paštu, telefonu arba kitokiu tiesioginiu būdu siūlyti asmenims prekes ar paslaugas, 
      siūlyti progines nuolaidas ir (arba) teirautis jų nuomonės dėl siūlomų prekių ar paslaugų.
    </span>
    <span className={classes.par}>
      1.10. Taisyklės &ndash; šios Asmens duomenų tvarkymo taisyklės patalpintos elektroninės 
      parduotuvės <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> internetiniame puslapyje.
    </span>
    <span className={classes.par}>
      1.11. Pardavėjo partneris &ndash; juridinis asmuo, pasitelkiamas norint įvykdyti Pirkėjo užsakymą (transporto paslaugų teikėjai (kurjeriai), 
      serverių, svetainės talpinimo, mokėjimo/aptarnavimo paslaugas teikiančios įmonės).
    </span>
    <span className={classes.par}>
      1.12. Paskyra &ndash; Pirkėjo prisiregistravimo prie <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> rezultatas, 
      dėl kurio sukuriama jo asmeninius duomenis ir užsakymų istoriją sauganti sąskaita.
    </span>
    <span className={classes.heading}>
      2. Bendrosios nuostatos
    </span>
    <span className={classes.par}>
      2.1. Taisyklėse numatytos pagrindinės Asmens duomenų rinkimo, kaupimo ir jų tvarkymo nuostatos Pirkėjui 
      naudojantis <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> teikiamomis paslaugomis.
    </span>
    <span className={classes.par}>
      2.2. Taisyklės skirtos apsaugoti ir ginti elektroninės 
      parduotuvės <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> Pirkėjų Asmens duomenis nuo neteisėto naudojimo.
    </span>
    <span className={classes.par}>
      2.3. Pirkėjas laikomas susipažinusiu su šiomis Taisyklėmis ir jas perskaitęs, kai išreiškia sutikimą tvarkyti jo Asmens duomenis.
    </span>
    <span className={classes.par}>
      2.4. Su Taisyklėmis galima susipažinti ir jas atsispausdinti bet kuriuo 
      metu <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> interneto svetainėje. 
      Apie visus būsimus Taisyklių pakeitimus ir/ar papildymus Pirkėjai visuomet bus informuoti. 
      Taisyklės bus įkeliamos į <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> interneto svetainę.
    </span>
    <span className={classes.heading}>
      3. Asmens duomenų rinkimo, saugojimo ir naudojimo tvarka
    </span>
    <span className={classes.par}>
      3.1. Pirkėjas sutinka, kad elektroninės prekybos tikslu, siekiant tinkamai suteikti Pirkėjui paslaugą, Pardavėjas tvarkys tokius jo asmens duomenis:
      <ul style={{listStyleType: "circle"}}>
        <li>vardą, pavardę</li>
        <li>telefono numerį</li>
        <li>adresą</li>
        <li>elektroninio pašto adresą</li>
        <li>prekės pristatymo adresą</li>
        <li>įsigytos prekės istorija (prekės kaina, užsakymo istorija ir pan.)</li>
        <li>prekės/paslaugos apmokėjimo duomenys (apmokėjimo būdas ir pan.)</li>  
      </ul>  
    </span>
    <span className={classes.par}>
      3.2. Pirkėjas yra atsakingas už aukščiau nurodytų duomenų teisingumą. 
      Pirkėjui suteikiamas Vartotojo ID. Pirkėjas gali bet kuriuo metu:  
    </span>
    <span className={classes.par}>
      3.2.1. Koreguoti ir/ar pildyti Asmens duomenis Paskyroje. 
      Už pakoreguotų ir/ar papildytų duomenų teisingumą atsakingas Pirkėjas.
    </span>
    <span className={classes.par}>
      3.2.2. Kreiptis į Pardavėją elektroninio pašto adresu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> dėl Paskyros 
      panaikinimo/ištrynimo arba ištrinti ją pats per paskyros valdymo pultą.
    </span>
    <span className={classes.par}>
      3.3. Pardavėjas, Asmens duomenis naudotinus elektroninės prekybos tikslu nustato saugojimo trukmę &ndash; 5 kalendoriniai 
      metai nuo paskutinio prisijungimo prie elektroninės parduotuvės.
    </span>
    <span className={classes.par}>
      3.4.  Pardavėjas suteikia Pirkėjui teisę registruojantis arba lankantis Elektroninėje 
      parduotuvėje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> išreikšti savo sutikimą, 
      kad Pardavėjas tiesioginės rinkodaros tikslu tvarkytų šiuos jo asmens duomenis:
      <ul style={{listStyleType: "circle"}}>
        <li>elektroninio pašto adresą</li>  
      </ul>  
    </span>
    {/* <span className={classes.par}>
      3.5. Jei Pirkėjas po prekių ir/ar paslaugų įsigijimo nepageidauja, kad Taisyklių 3.4 punkte nurodyti Asmens duomenys būtų naudojami 
      Tiesioginės rinkodaros tikslu, Pirkėjas turi prisijungti prie savo Paskyros, 
      esančios <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> ir Paskyros lange „Profilis“ nuimti žymėjimą „Noriu gauti Naujienlaiškius“ arba 
      elektroninio laiško apačioje paspausti nuorodą „atsisakyti pranešimų“.
    </span> */}
    <span className={classes.par}>
      3.5. Tais atvejais, kuomet neprisiregistravęs Pirkėjas, kuris buvo davęs 
      Sutikimą dėl asmens duomenų tvarkymo Tiesioginės rinkodaros tikslu 
      „Pažymėjęs varnelę, jog pageidauja gauti Naujienlaiškius“ savo Sutikimą gali bet kada atšaukti 
      elektroninio laiško apačioje paspaudus nuorodą „atsisakyti pranešimų“ arba pateikti 
      prašymą el. paštu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a>.
    </span>
    <span className={classes.par}>
      3.6. Pardavėjas, Asmens duomenų naudotinų Tiesioginės rinkodaros tikslu nustato saugojimo 
      trukmę &ndash; 5 kalendoriniai metai nuo duomenų pateikimo dienos.
    </span>
    <span className={classes.par}>
      3.7. Pardavėjas įsipareigoja neatskleisti tvarkomų Asmens duomenų tretiesiems asmenims, išskyrus šiuos atvejus:  
    </span>
    <span className={classes.par}>
      3.7.1. Jei yra Pirkėjo sutikimas Asmens duomenų atskleidimui.
    </span>
    <span className={classes.par}>
      3.7.2. Vykdant užsakymą ar teikiant kitas paslaugas &ndash; Pardavėjo partneriams, teikiantiems prekių pristatymo ar kitas Pirkėjo užsisakytas paslaugas.
    </span>
    <span className={classes.par}>
      3.7.3. Teisėsaugos institucijoms pagal Lietuvos Respublikos teisės aktų numatytą tvarką.
    </span>
    <span className={classes.par}>
      3.7.4. jeigu būtina užkirsti kelią nusikalstamoms veikoms arba būtina jas tirti.
    </span>
    <span className={classes.par}>
      3.8. Pirkėjas turi šias teises:
    </span>
    <span className={classes.par}>
      3.8.1. Taisyklių 4.3 punkte numatyta tvarka, pateikęs užklausą Pardavėjui raštu (paštu, el. paštu) susipažinti su savo asmens duomenimis, kuriuos tvarko Pardavėjas.
    </span>
    <span className={classes.par}>
      3.8.2. Pateikęs užklausą Pardavėjui raštu gauti informaciją, iš kokių šaltinių ir kokie jo Asmens duomenys surinkti, 
      kokiu tikslu jie tvarkomi, kokiems duomenų gavėjams teikiami ir buvo teikti bent per paskutinius 1 (vienerius) metus.
      </span>
    <span className={classes.par}>
      3.8.3. Pateikęs prašymą Pardavėjui raštu, elektroninio pašto adresu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> reikalauti panaikinti/ištrinti 
      Paskyrą arba sustabdyti Asmens duomenų tvarkymo veiksmus, kai Asmens duomenys tvarkomi nesilaikant Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo, 
      Lietuvos Respublikos elektroninių ryšių įstatymo, Europos Sąjungos teisės aktų, ar kitų įstatymų nuostatų, išskyrus Taisyklėse numatytus atvejus.
      </span>
    <span className={classes.par}>
      3.8.4. Pateikęs prašymą Pardavėjui raštu ir tinkamai save identifikavęs kaip tai numatyta Taisyklių 4.3
      punkte, reikalauti, kad jo duomenys būtų perkelti kitam Duomenų valdytojui.
    </span>
    <span className={classes.par}>
      3.8.5. Nesutikdamas su Taisyklėmis nesutikti, kad būtų tvarkomi jo Asmens duomenys. 
      Tokiu atveju Pirkėjas neturės galimybės įsigyti prekių ir/ar paslaugų 
      elektroninėje parduotuvėje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.par}>
      3.8.6. Tiek iš karto išreikšdamas savo nesutikimą, Taisyklių 3.5 punkte 
      numatyta tvarka pateikdamas vėlesnį pranešimą nesutikti, kad jo Asmens duomenys būtų tvarkomi Tiesioginės rinkodaros tikslais.
    </span>
    <span className={classes.par}>
      3.8.7. Manydamas, kad jo duomenys tvarkomi nesilaikant Lietuvos Respublikos asmens duomenų teisinės apsaugos įstatymo, 
      Lietuvos Respublikos elektroninių ryšių įstatymo, Europos Sąjungos teisės aktų, 
      ar kitų įstatymų nuostatų, kreiptis į Valstybinę duomenų apsaugos inspekciją.
    </span>
    <span className={classes.par}>
      3.9. Prekių atsiėmimo metu Pardavėjui, kurjerių tarnybos darbuotojui pateiktas susipažinti Pirkėjo asmens 
      tapatybę patvirtinantis dokumentas ir jame esantys Pirkėjo duomenys naudojami tik tinkamam asmens tapatybės identifikavimui.
    </span>
    <span className={classes.par}>
      3.10. Pirkėjas sutikdamas su šiomis Taisyklėmis, sutinka, kad jo Asmens duomenys būtų naudojami siekiant 
      įvykdyti jo užsakymą, įskaitant sutinka, kad jo Asmens duomenys bus perduoti Pardavėjo partneriams, kaip jie apibrėžti Taisyklių 1.11 punkte. 
      Duomenų valdytojo nurodymu Pardavėjo partneriai perduotus duomenis saugiai sunaikina kai jie tampa nereikalingi vykdyti tam tikslui, dėl kurio buvo perduoti.
    </span>
    <span className={classes.par}>
      3.11. Pardavėjas Asmens duomenis saugo šių Taisyklių 3.3 ir 3.6 punktuose numatytą laiko tarpą. 
      Po Paskyros panaikinimo siekiant įgyvendinti Taisyklių 3.12 punkte numatytus reikalavimus, 
      Pirkėjo Asmens duomenys gali būti saugomi tiek kiek būtina pagal Taisyklių 3.12 punkte nurodytus 
      atvejus bei teisėtus teisėsaugos institucijų reikalavimus juos saugoti.
    </span>
    <span className={classes.par}>
      3.12. Pirkėjas iš anksto sutinka, kad jo Asmens duomenis Pardavėjas gautus elektroninės prekybos tikslu turi teisę laikyti serveryje tiek, 
      kiek tai būtina pagal Pardavėjo vykdomos veiklos ypatumus, jeigu Pirkėjo pateiktais duomenimis buvo:
      <ol style={{listStyleType: "lower-alpha"}}>
        <li>pasinaudota vykdant neteisėtą veiką ar</li>
        <li>buvo įtariama, jog įvykdyta tapatybės vagystė ar kitas pažeidimas, dėl kurio buvo ar bus atliekamas atitinkamų teisėsaugos institucijų tyrimas,</li>
        <li>jeigu Pardavėjas yra gavęs skundų, susijusių su atitinkamu Pirkėju, ar jeigu Pardavėjas yra pastebėjęs atitinkamo Pirkėjo padarytus Taisyklių pažeidimus,</li>
        <li>ar (ir) esant kitiems teisėtiems tikslams saugoti Asmens duomenis.</li>
      </ol>
    </span>
    <span className={classes.par}>
      3.13. Asmens duomenys dėl Taisyklių 3.12 punkte nurodytų veiksmų sunaikinami 
      neautomatiniu būdu nedelsiant gavus teisėtus teisėsaugos ar kitų įgaliotų institucijų nurodymus. 
      Tokių duomenų sunaikinimą neautomatiniu būdu privalo sankcionuoti vadovas arba įgaliotas asmuo.
    </span>
    <span className={classes.par}>
      3.14. Pirkėjas, tinkamai save identifikavęs, bet kokį prašymą ar nurodymą, 
      susijusį su Asmens duomenų tvarkymu, turi pateikti Pardavėjui raštu vienu iš šių būdų:
      <ol style={{listStyleType: "decimal"}}>
        <li>įteikdamas tokį prašymą ar nurodymą Pardavėjui tiesiogiai adresu {config.seller.address}, {config.seller.zip}, {config.seller.city};</li>
        <li>
          išsiųsdamas tokį prašymą ar nurodymą elektroninio pašto adresu <a href={`mailto:${config.seller.email}`}>{config.seller.email}</a> iš 
          Taisyklių 3.1 punkte nurodyto savo elektroninio pašto adreso. 
          Pardavėjas, gavęs tokį Pirkėjo prašymą ar nurodymą, ne vėliau kaip per 30 (trisdešimt) kalendorinių dienų pateikia Pirkėjui atsakymą 
          bei atlieka prašyme nurodytus veiksmus ar motyvuotai atsisako juos ar dalį jų atlikti. Atsakymas Pirkėjui paruošiamas raštu ir 
          pateikimas tokiu būdu, kokiu buvo gautas iš Pirkėjo, nebent Pirkėjas prašyme nurodė, jog pageidauja atsakymą gauti kitu būdu.
        </li>
      </ol>
    </span>
    <span className={classes.heading}>
      4. Asmens duomenų koregavimas, papildymas ir ištrynimas
    </span>
    <span className={classes.par}>
      4.1. Pirkėjas suteikia teisę Pardavėjui rinkti, valdyti, tvarkyti ir saugoti Pirkėjo Asmens duomenis 
      tokia apimtimi ir tokiais tikslais, kaip numatyta šiose Taisyklėse.
    </span>
    <span className={classes.par}>
      4.2. Sutikimo rinkti, valdyti, tvarkyti ir saugoti Pirkėjo Asmens duomenis davimas ar atšaukimas 
      galioja tik į priekį. Pardavėjas iš Pirkėjo gavęs tokį pranešimą elektroniniu paštu ar kitais Taisyklėse 
      numatytais būdais nedelsiant sustabdo Pirkėjo Asmens duomenų tvarkymą ir panaikina jo Paskyrą, tačiau Pardavėjas 
      turi teisę netrinti Asmens duomenų iš serverio, jeigu turi teisėtą pagrindą juos saugoti, ypač kai reikia užtikrinti 
      valstybės saugumą ir gynybą, viešąją tvarką, nusikaltimų prevenciją, tyrimą, nustatymą ar baudžiamąjį persekiojimą, 
      ginti svarbius valstybės ekonominius ar finansinius interesus, kitų asmenų teisių ir laisvių apsaugą.
    </span>
    <span className={classes.par}>
      4.3. Pirkėjas turi teisę susipažinti su savo Asmens duomenimis tik tinkamai save identifikavęs, tai yra, 
      pateikęs Pardavėjui tapatybę patvirtinantį dokumentą (tapatybei patvirtinti turi būti pateiktas 
      Lietuvos Respublikos pasas, asmens tapatybės kortelė, vairuotojo pažymėjimas). Jeigu su Pirkėjo 
      duomenimis nori susipažinti kitas asmuo, jis privalo pateikti notaro patvirtintą įgaliojimą, 
      o advokatui duomenys teikiami tik pateikus atstovavimo sutartį bei nurodžius duomenų naudojimo tikslą. 
      Siekdami sužinoti, kokius Asmens duomenis Pardavėjas surinko ar gavo ir iš kokių šaltinių, kokiais tikslais 
      jie tvarkomi, kokiems gavėjams jie teikiami ir buvo teikti, arba ar visi Pirkėjo Asmens duomenys yra išsamūs ir teisingi, 
      Pirkėjas pateikia Pardavėjui raštu (paštu, el. paštu).
    </span>
    <span className={classes.par}>
      4.4. Pardavėjas gavęs Pirkėjo 4.3. punkte nurodytą paklausimą, jam atsako, ar su Pirkėju susiję Asmens duomenys yra tvarkomi 
      ir pateikia Pirkėjo prašomus duomenis ne vėliau kaip per 30 (trisdešimt) kalendorinių dienų nuo Pirkėjo kreipimosi gavimo dienos. 
      Duomenys neatlygintinai teikiami vieną kartą per kalendorinius metus.
    </span>
    <span className={classes.par}>
      4.5. Jeigu susipažinęs su savo Asmens duomenimis Pirkėjas nustato, kad Asmens duomenys yra surinkti ar gauti iš neteisėtų šaltinių, 
      ar kad Asmens duomenys tvarkomi ne tais tikslais, kokiems jie teikiami, Pirkėjas turi teisę kreiptis į Pardavėją elektroniniu 
      pašto adresu prašydamas sustabdyti tokių Asmens duomenų tvarkymo veiksmus. Pirkėjas, Asmens duomenis taiso pats prisijungęs prie savo Paskyros. 
      Pardavėjas turi teisę koreguoti, papildyti, naikinti Asmens duomenis arba stabdyti 
      Asmens duomenų tvarkymo veiksmus tik identifikavus Pirkėjo, kuris kreipėsi, asmenybę.
    </span>
    <span className={classes.par}>
      4.6. Jeigu Pardavėjas abejoja Pirkėjo pateiktų Asmens duomenų teisingumu, 
      jis gali sustabdyti šių Pirkėjų Asmens duomenų tvarkymo veiksmus, šiuos duomenis patikrinti ir patikslinti. 
      Tokie Asmens duomenys naudojami tik jų teisingumui patikrinti.
    </span>
    <span className={classes.heading}>
      5. Asmens duomenų apsaugos pažeidimo rizikos veiksniai ir jų sprendimas
    </span>
    <span className={classes.par}>
      5.1. Asmens duomenų apsaugos pažeidimas &ndash; tai veiksmai ar neveikimas, kuriais pažeidžiamas 
      Lietuvos Respublikos Asmens duomenų teisinės apsaugos įstatymas, Europos Sąjungos teisės aktai, 
      kiti, asmens duomenų apsaugą reglamentuojantys teisės aktai, įmonės vidinės procedūros, nustatančios 
      įmonės Asmens duomenų tvarkymo principus bei taisykles ir Pardavėjo prisiimtus įsipareigojimus dėl Asmens duomenų tvarkymo.
    </span>
    <span className={classes.par}>
      5.2. Pardavėjas, siekdamas užtikrinti tinkamą Asmens duomenų apsaugą, 
      vadovaudamasis nustatytu II asmens duomenų saugumo lygmeniu, 
      įgyvendina šias organizacines ir technines Asmens duomenų apsaugos priemones:<br/>
      <span className={classes.par}>&ndash; Organizacinės:</span>
      <span className={classes.par}>
        5.2.1. Pardavėjas darbo tvarką organizuoja taip, kad būtų užtikrintas saugus kompiuterinių duomenų 
        ir/ar dokumentų bei jų archyvų tvarkymas ir (esant) perdavimas. 
      </span>
      <span className={classes.par}>
        5.2.2. Kai dėl Asmens duomenų saugumo pažeidimo gali kilti didelis pavojus Pirkėjų teisėms ir laisvėms, 
        Pardavėjas pagrįstai nedelsdamas informuoja Pirkėjus apie Asmens duomenų saugumo pažeidimą.
      </span>
      <span className={classes.par}>
        5.2.3. Pardavėjas, siekdamas užtikrinti, kad Pirkėjo asmens duomenys būtų tvarkomi ne ilgiau nei tai yra būtina, 
        duomenų peržiūrą atlieką ne rečiau kaip kartą per 6 mėnesius ir saugiai automatiniu/neautomatiniu būdu sunaikina 
        tų Pirkėjų duomenis, kurių duomenų saugojimo terminas pasibaigė.
      </span>
      <span className={classes.par}>&ndash; Techninės:</span>
      <span className={classes.par}>
        5.2.4. Pardavėjo paskirti duomenų tvarkytojai (paslaugų tiekėjai), su kuriais visais atvejais yra sudaromos 
        rašytinės sutartys ir kurie pagal jas teikia techninio aptarnavimo, IT sprendimų paslaugas, 
        užtikrina įmonės duomenų bazių apsaugą nuo neteisėtų prisijungimų (tiek vidinių, tiek išorinių), 
        žalingų programų, veiksmų, kuriais galima būtų pažeisti Asmens duomenų teisinę apsaugą.
      </span>
      <span className={classes.par}>
        5.2.5. Asmens duomenys yra saugomi nuo praradimo, neleistino naudojimo ir pakeitimų. 
        Visi Pirkėjų Slaptažodžiai yra šifruojami, prie serverių, kurie susiję su Asmens duomenimis gali prisijungti 
        tik Asmens duomenų valdytojo įgalioti asmenys ir tik per saugų sertifikatą, kuris papildomai apsaugotas slaptažodžiu, 
        taip pat puslapis yra vykdomas per https:// protokolą.
      </span>
      <span className={classes.par}>
        5.2.6. Užtikrinama kompiuterinės įrangos apsauga nuo kenksmingos programinės įrangos 
        (pvz., antivirusinių programų įdiegimas, atnaujinimas).
      </span>
      <span className={classes.par}>
        5.3. Pirkėjas įsipareigoja ir privalo saugoti savo prisijungimo prie <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> slaptažodį ir 
        prisijungimo vardą, bei kitus duomenis. Pirkėjas įsipareigoja ir privalo 
        jokiems kitiems tretiesiems asmenims neatskleisti Asmens duomenų nei apie save, nei apie trečiuosius asmenis, 
        jeigu tokie trečiųjų asmenų Asmens duomenys jam tapo prieinami, ir iš karto apie matomus pažeidimus informuoti Pardavėją.
      </span>
    </span>
    <span className={classes.heading}>
      6. Captcha
    </span>
    <span className={classes.par}>
      Ši interneto svetainė ypatingais atvejais naudoja Google reCAPTCHA v3, 
      kad neleistų automatinėms programoms/paieškos robotams laisvo priėjimo prie teksto laukelių. 
      Tai padeda užtikrinti mūsų interneto svetainės saugumą ir išvengti brukalo siuntimo vartotojams. 
      Tai taip pat yra mūsų teisėtas interesas ir teisinis įsipareigojimas. Renkami duomenys &ndash; tai informacija apie techninę 
      ir programinę įrangą, pavyzdžiui, įrenginio ir programos duomenys bei vientisumo patikrinimo rezultatai. 
      Šie duomenys siunčiami „Google International Limited“, Gordon House, Barrow Street, Dublinas 4, Airija. 
      „Google“ duomenų nenaudoja suasmenintoms reklamoms. Daugiau informacijos galima rasti jų privatumo 
      politikoje: <a target="_blank" rel="noreferrer" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>. 
      Daugiau dokumentų galite rasti: <a target="_blank" rel="noreferrer" href="https://developers.google.com/recaptcha/">https://developers.google.com/recaptcha/</a>.
    </span>
    <span className={classes.heading}>
      7. Social Login
    </span>
    <span className={classes.par}>
      Vartotojas, norėdamas registruotis ir prisijungti prie kliento paskyros, 
      gali patvirtinti tapatybę per savo profilį, pasinaudodamas vienu iš socialinių tinklų: „Facebook“ ar „Google“, 
      ir registruotis arba prisijungti.
      Tam tikslui registracijos ar prisijungimo puslapyje pateikiami mūsų svetainės aptarnaujamų 
      konkrečių socialinių tinklų tiekėjų simboliai. Prieš užmezgant ryšį su tiekėju, vartotojas privalo 
      tiesiogiai sutikti dėl toliau nurodytų duomenų tvarkymo ir perdavimo:
      Paspaudus atitinkamą simbolį, atsiveria naujas dialogo langas (vad. taikomoji programa), 
      kuriame būtina prisijungti naudojant prisijungimo prie socialinio tinklo duomenis. 
      Sėkmingai prisijungus, tinklas praneša vartotojui, kokie duomenys perduodami mums, 
      kad būtų nustatyta tapatybė registruojantis ar jungiantis. 
      Jeigu vartotojas sutinka, kad duomenys būtų perduodami, mūsų reikalaujami registracijos 
      laukai užpildomi perduotais duomenimis. Registruojantis ar jungiantis 
      reikalaujami duomenys: vartotojo vardas ir el. pašto adresas.
      Tik gavus aiškų, vienareikšmį vartotojo sutikimą naudoti perduotus ir būtinus duomenis, 
      duomenis saugome ir naudojame (šioje) privatumo politikoje nurodytiems tikslams. 
      Mūsų sukurta kliento paskyra ir atitinkamo socialinio tinklo paskyra niekaip nėra siejamos, išskyrus siekiant patvirtinti tapatybę.
      Siekiant patvirtinti tapatybę, kad būtų galima registruotis ir prisijungti, 
      vartotojo IP adresas perduodamas atitinkamam socialinių tinklų tiekėjui. 
      Neturime jokios įtakos, kokiam tikslui ir kokia apimtimi kaupiami duomenys, 
      ir kaip juos toliau tvarko atitinkamas socialinio tinklo tiekėjas.<br/>
      a) „Facebook Inc.“, 1601 S California Ave, Palo Alto, California 94304,
      JAV; <a target="_blank" rel="noreferrer" href="http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</a>; 
      plačiau apie duomenų kaupimą: <a target="_blank" rel="noreferrer" href="http://www.facebook.com/about/privacy/your-info-on-other#applications">http://www.facebook.com/about/privacy/your-info-on-other#applications</a>, 
      taip pat <a target="_blank" rel="noreferrer" href="http://www.facebook.com/about/privacy/your-info#everyoneinfo">http://www.facebook.com/about/privacy/your-info#everyoneinfo</a>.<br/>
      b) „Google Inc.“, 1600 Amphitheater Parkway, Mountainview, California 94043, 
      JAV; <a target="_blank" rel="noreferrer" href="https://www.google.com/policies/privacy/partners/?hl=lt">https://www.google.com/policies/privacy/partners/?hl=lt</a>.
    </span>
    <span className={classes.par}>
      <span className={classes.subpar}>Duomenų, surinktų iš socialinių tinklų, pašalinimas</span> Visi duomenys, surinkti iš „Facebook“ ir „Google“ yra
      automatiškai pašalinami ištrynus savo paskyrą tinklalapyje <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a>.
    </span>
    <span className={classes.heading}>
      8. Slapukų naudojimas
    </span>
    <span className={classes.par}>
      <span className={classes.subpar}>Kas yra slapukai?</span> Slapukas (angl. cookie) &ndash; tai mažas tekstinis failas, 
      kurį interneto svetainė įrašo į Jūsų kompiuterio arba mobilaus prietaiso naršyklę, 
      kai Jūs apsilankote svetainėje. Sekantį kartą apsilankius svetainėje šis failas gali būti nuskaitytas, 
      kad svetainė galėtų atpažinti Jūsų kompiuterį 
      ar mobilųjį prietaisą.<br/>
      <span className={classes.subpar}>Kam mes naudojame slapukus?</span>
      Slapukų surinkta informacija leidžia mums užtikrinti Jūsų galimybę naršyti patogiau, 
      teikti Jums patrauklius pasiūlymus bei daugiau sužinoti apie svetainės naudotojų elgesį, 
      analizuoti tendencijas ir tobulinti svetainę, klientų aptarnavimą ir <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> teikiamas 
      paslaugas. <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetainėje slapukai naudojami šiais tikslais:
      <span className={classes.par}>
        <span className={classes.subpar}>Techniniai slapukai:</span> savo lankytojams stengiamės pasiūlyti pažangią, paprastą naudoti svetainę, 
        kuri automatiškai prisitaiko prie jų poreikių ir norų. Norėdami tai pasiekti naudojame techninius slapukus, 
        kurie padeda rodyti jums mūsų svetainę, padeda užtikrinti jos funkcionalumą, 
        sukurti vartotojo paskyrą, prisijungti ir tvarkyti jūsų užsakymus. 
        Šie techniniai slapukai yra būtini tinkamam mūsų svetainės funkcionavimui.
      </span>
      {/* <span className={classes.par}>
        <span className={classes.subpar}>Funkciniai slapukai:</span> taip pat naudojame funkcinius slapukus prisiminti jūsų pageidavimus ir 
        padėti naudoti mūsų svetainę ir programėles veiksmingai bei efektyviai. 
        Pavyzdžiui, šie slapukai prisimena jūsų pageidaujamą kalbą, paieškas ir anksčiau peržiūrėtas prekes. 
        Šiuos slapukus galime naudoti ir jūsų registracijos informacijai prisiminti, 
        kad kaskart apsilankius mūsų svetainėje jums nereikėtų iš naujo įvesti prisijungimo duomenų. 
        Šie funkciniai slapukai nėra būtini svetainei funkcionuoti, 
        tačiau prideda funkcionalumo ir pagerina jūsų naudojimosi <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetaine patirtį.
      </span>
      <span className={classes.par}>
        <span className={classes.subpar}>Analitiniai slapukai:</span> šiuos slapukus naudojame įgyti įžvalgų apie tai, kaip mūsų lankytojai 
        naudoja <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetainę. 
        Tokiu būdu galime optimizuoti ir pagerinti savo svetainę, suprasti reklamų ir 
        komunikacijos efektyvumą bei užtikrinti, kad vis dar esame įdomūs ir aktualūs. Galime rinkti duomenis 
        apie jūsų peržiūrėtus internetinius puslapius, iš kurių puslapių atėjote, kokius el. laiškus atvėrėte ir į 
        kuriuos sureagavote bei informaciją apie datą ir laiką. Tai taip pat reiškia, kad galime naudoti 
        informaciją apie Jus ir tai, kaip naudojate šią svetainę, pavyzdžiui, apsilankymo dažnumą, paspaudimų 
        skaičių tam tikrame puslapyje, naudotus paieškos žodžius ir kt. Kaip mūsų reklaminių kampanijų dalį, 
        galime panaudoti analitinius slapukus, kad sužinotume, kaip vartotojai naršo mūsų svetainėje po to, 
        kai jiems parodoma reklama internete. Tai gali apimti ir reklamas trečiųjų šalių svetainėse.
      </span>
      <span className={classes.par}>
        <span className={classes.subpar}>Komerciniai slapukai:</span> naudojame savo ir trečiųjų šalių slapukus, kad savo bei kitose svetainėse 
        rodytume suasmenintą reklamą. Tai vadinama „pakartotine rinkodara“, 
        kuri pagrįsta naršymo veiksmais, pavyzdžiui, jūsų ieškotomis, peržiūrėtomis prekėmis.
      </span> */}

      {/* <span className={classes.par}>
        Slapukai taip pat naudojami tam, kad būtų užregistruota, ar sutinkate, kad <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> 
        svetainėje būtų naudojami slapukai, kad šis klausimas nebūtų užduodamas kiekvieną kartą apsilankant svetainėje.
        Prieigą prie statistinių duomenų apie <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetainės lankytojus turi <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> darbuotojai, 
        kurie yra atsakingi už šių duomenų analizę ir interneto svetainės tobulinimą, 
        taip pat įmonės partneriai, kurie teikia interneto svetainės turinio valdymo įrankius.
        „Google Analytics“ įrankį suteikia JAV įmonė „Google Inc.“, tad ji taip pat turi prieigą prie šiuo įrankiu surinktų statistinių duomenų. 
        „Google Inc.“ yra įsipareigojusi taikyti ES&ndash;JAV Privatumo skydo principus, kurie užtikrina, 
        kad paslaugų teikėjas atitinka ES privatumo standartų reikalavimus. Šiam teikėjui taip pat taikomi 
        sutartiniai įpareigojimai užtikrinti privatumą. 
        Apie tai galite paskaityti https://www.google.com/analytics/terms/dpa/dataprocessingamendment_20160909.html.
      </span> */}
      
      <span className={classes.subpar}>Naudojami slapukai</span>
      <div style={{overflowX: "auto"}}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.row}>
              <th className={classes.cell}>Pavadinimas</th>
              <th className={classes.cell}>Tikslas</th>
              <th className={classes.cell}>Informacija</th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.cell}>__rt__</td>
              <td className={classes.cell}>Naudojamas suteikti prisijungusiam vartotojui autentifikavimo raktą</td>
              <td className={classes.cell}>Slapuke saugomi užkoduoti vartotojo duomenys. Galiojimo laikas: sesija arba 14 dienų (priklausomai nuo vartotojo pasirinkimo)</td>
            </tr>
          </tbody>
        </table>
      </div>

      <span className={classes.par}>
        <span className={classes.subpar}>Ką galite pasirinkti?</span> Slapukus galite valdyti ir (arba) ištrinti per savo naudojamos naršyklės nustatymus. 
        „Internet Explorer“, „Safari“, „Firefox“, „Chrome“ ar kitos naršyklės nustatymuose galite pasirinkti, 
        kuriuos slapukus norite priimti ir kuriuos atmesti. Galite ištrinti visus jūsų kompiuteryje jau esančius slapukus, 
        o daugumoje naršyklių galite nustatyti, kad slapukai nebūtų įrašomi. 
        Šių nustatymų buvimo vieta priklauso nuo jūsų naudojamos naršyklės.
        Daugumoje naršyklių galima:
        
        <ul style={{listStyleType: "circle"}}>
          <li>patikrinti, kokie slapukai yra įrašyti, ir ištrinti atskirus slapukus;</li>
          <li>blokuoti trečiųjų šalių slapukus;</li> 
          <li>blokuoti slapukus iš konkrečių interneto svetainių;</li> 
          <li>blokuoti visų slapukų siuntimą;</li> 
          <li>uždarant naršyklę ištrinti visus slapukus.</li> 
        </ul>
        
        Jei nesutinkate, kad į Jūsų kompiuterį ar kitą įrenginį būtų įrašomi slapukai, 
        galite bet kada atšaukti sutikimą juos naudoti pakeitę nustatymus ir ištrynę įrašytus slapukus. 
        Jei pasirinkote ištrinti slapukus, nepamirškite, kad bus pašalintos ir visos nustatytos parinktys. 
        Be to, visiškai užblokavus slapukus, daug interneto svetainių 
        (įskaitant <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetainę) neveiks tinkamai. 
        Dėl šių priežasčių nerekomenduojame išjungti slapukų, kai naudojatės 
        internetine <a target="_blank" rel="noreferrer" href={window.location.origin.toString()}>{config.seller.webpage}</a> svetaine.
        Norėdami daugiau sužinoti apie slapukus ir kaip juos tvarkyti ar pašalinti, tiesiog apsilankykite 
        puslapyje <a target="_blank" rel="noreferrer" href="www.allaboutcookies.org">www.allaboutcookies.org</a> ir jūsų naršyklės pagalbos puslapyje.
      </span>
    </span>
  </React.Fragment>
}


interface BaseProps {
  open: boolean
  title: React.ReactNode
  children: React.ReactNode
  onClose: voidFn
}

const BaseInfo = ({ open, onClose, title, children }: BaseProps) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        scroll="paper"
        fullScreen
        aria-labelledby="conditions-dialog-title"
        aria-describedby="conditions-dialog-description"
        style={{maxWidth: 1000, margin: "auto"}}
      >
        <DialogTitle id="conditions-dialog-title">{title}</DialogTitle>
        <DialogContent dividers={true}>
          <Typography component="div" style={{ marginBottom: 30 }}>
            {children}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Uždaryti
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}


interface InfoProps {
  open: boolean
  onClose: voidFn
}

export const Conditions = React.memo(({ open, onClose }: InfoProps) => {
  return ( 
    <BaseInfo open={open} onClose={onClose} title={<span>Paslaugų teikimo taisyklės</span>}>
      <BusinessRules />
    </BaseInfo>)
})


export const Privacy = React.memo(({ open, onClose }: InfoProps) => {
  return ( 
    <BaseInfo open={open} onClose={onClose} title={<span>Privatumo politika
    </span>}>
      <PrivacyPolicy />
    </BaseInfo>)
})