import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeUseStyles, useGetProductMap, BANK_MAP, getCompanyCity, useDeliveryData } from './common';
import { useParams } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';
import { AppConfig, OrderData, voidFn } from '../store/types';
import { AppState } from '../store';
import Alert from '@material-ui/lab/Alert';
import { addError, grecaptchaRequest, ResponseError } from '../store/common';
import Button from '@material-ui/core/Button';
import { useManagementActions } from '../store/management-actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CenteredCircularProgress from './progress';
import LazyImage from './image';
import { DialogContent } from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { useLoginActions } from '../store/user-actions';
import CircularProgress from '@material-ui/core/CircularProgress';


const useOrderStyles = makeUseStyles((theme) => ({
  root: {
    maxWidth: 700, 
    margin: '0 auto'
  },
  item: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexFlow: 'row wrap',
  },
  subtitle: {
    fontWeight: 700
  },
  line: {
    height: 5, 
    backgroundColor: '#408753'
  },
  explanation: {
    padding: 12
  },
  bankLabel: {
    marginTop: 10,
    marginBottom: 0
  },
  buttons: {
    display: "flex",
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
  payment: {
    marginTop: 12,
    marginBottom: 20,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
  },
  paymentLogo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 12,
    marginBottom: 12
  },
  center: {
    marginTop: 12,
    display: "flex",
    justifyContent: "center"
  },
  button: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}))


interface OrderViewProps {
  order: OrderData
}


export const OrderView = ({order}: OrderViewProps) => {
  const classes = useOrderStyles()
  const [active, setActive] = React.useState(false)
  const [redirecting, setRedirecting] = React.useState(false)
  const { dispatch, thunkGetPaymentInfo } = useLoginActions()
  const config = useSelector<AppState, AppConfig>(state => state.user.config, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.paymentError, shallowEqual)
  const productMap = useGetProductMap(order?.orders.map(o => o.item) || [])
  const deliveryData = useDeliveryData(order?.city)

  const totalPrice = () => {
    let total = deliveryData.fee
    let discountedTotal = Math.ceil(deliveryData.fee * (1 - order.discount / 100))
    order.orders.forEach(o => {
      if (o.price) {
        total += o.price * o.quantity
        discountedTotal += o.quantity * Math.ceil(o.price * (1 - order.discount / 100))
      }
    })
    return {
      discount: ((discountedTotal - total) / 100).toFixed(2),
      price: (discountedTotal / 100).toFixed(2)
    }
  }

  const pay = () => {
    setActive(true)
    grecaptchaRequest('order', (ctoken) => {
      thunkGetPaymentInfo(order.id, ctoken, (result) => {
        if (result.pay_status || result.pay_ack_status) {
          setActive(false)
        } else {
          setRedirecting(true)
          window.location.href = `https://www.paysera.com/pay/?data=${result.data}&sign=${result.sign}`
        }
      }, () => {
        setActive(false)
      })
    }, (err) => {
      dispatch(addError("paymentError", new ResponseError({detail: 'Nepavyko gauti apmokėjimo duomenų'}, 400)))
      console.log(err)
      setActive(false)
    })
  }

  const paymentInfo = () => {
    switch (order.pay_method) {
      case 1:
        return (
          <Typography variant="body1" align="center" className={classes.explanation} gutterBottom>
            Mokėjimas grynaisiais pinigais užsakymo atsiėmimo metu
          </Typography>
        )
      case 2:
        return (
          <Typography variant="body1" align="center" className={classes.explanation} gutterBottom>
            Mokėjimas bankiniu pavedimu
          </Typography>
        )
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        return (
          <div className={classes.payment}>
            <Typography variant="body1" align="center">
              {BANK_MAP[order.pay_method].alt}
            </Typography>
            <img
              className={classes.paymentLogo} 
              src={`https://bank.paysera.com/assets/image/payment_types/${BANK_MAP[order.pay_method].type}.png`}
              alt={BANK_MAP[order.pay_method].alt}
            />
            {order.pay_status === 0 && !Boolean(order.pay_ack_status) && order.status >= 2 ? 
              <Button 
                color="primary" 
                variant="contained" 
                onClick={pay}
                disabled={active}
              >
                  {active ? <CircularProgress size={25} /> : 'Apmokėti'}
                </Button> : null}
          </div>
        )
      default:
        break
    }
  }

  const payStatusView = () => {
    let label: string
    let color: string
    let status = order.pay_ack_status || order.pay_status
    switch (status) {
      case 0:
        label = 'NEAPMOKĖTA'
        color = "#ff5c33"
        break
      case 1:
      case 2:
        label = 'APMOKĖTA'
        color = "#00b300"
        break
      case 3:
        label = 'NEBAIGTA'
        color = "#99ccff"
        break
      default:
        return null;
    }
    return (
      <div style={{display: "flex", justifyItems: "center"}}>
        <Chip size="small" label={label} style={{color: "white", margin: "0 auto", backgroundColor: color}}/>
      </div>
    )
  }

  const deliveryInfo = () => {
    switch (order.delivery_method) {
      case 0:
        return (
          <Typography variant="body1" gutterBottom>
            Užsakymą atsiimsite adresu:<br/><strong>{config.production.address}, {config.production.city}</strong>
          </Typography>
        )
      case 1:
        return (
          <Typography variant="body1" gutterBottom>
            Pristatymo adresas:<br/>
            <strong>{order.address}, {deliveryData.city}</strong>
          </Typography>
        )
      default:
        break;
    }
  }

  const companyInfo = () => {
    return <React.Fragment>
      <Typography variant="body2">
        <b>Įmonė:</b> {order.company.name}
      </Typography>
      <Typography variant="body2">
        <b>Kodas:</b> {order.company.code}
      </Typography>
      {Boolean(order.company.pvm_code) ?
        <Typography variant="body2">
          <b>PVM kodas:</b> {order.company.pvm_code}
        </Typography> : null}
      <Typography variant="body2">
        <b>Adresas:</b> {order.company.address}
      </Typography>
      {Boolean(order.company.zip_code) ?
        <Typography variant="body2">
          <b>Pašto kodas:</b> {order.company.zip_code}
        </Typography> : null}
      <Typography variant="body2">
        <b>Miestas:</b> {getCompanyCity(order.company.city)}
      </Typography>
    </React.Fragment>
  }

  const choiceString = (choice: string) => {
    return choice.split("|").map(s => s.split(";")[0]).join(", ")
  }

  const deliveryDateView = () => {
    if (order.delivery_date) {
      let hour = order.delivery_date.getHours() * 60 + order.delivery_date.getMinutes()
      let q = Math.floor(hour / 60)
      let m = hour % 60
      let eq = Math.floor((hour + order.interval) / 60)
      let em = (hour + order.interval) % 60
      let month = order.delivery_date.getMonth() + 1
      let day = order.delivery_date.getDate()
      let dateString = `${order.delivery_date.getFullYear()}-${month > 9 ? "" : "0"}${month}-${day > 9 ? "" : "0"}${day}` 
      return <span>{dateString} {`${q > 9 ? "" : '0'}${q}:${m > 9 ? "" : '0'}${m}`}&ndash;{`${eq > 9 ? "" : '0'}${eq}:${em > 9 ? "" : '0'}${em}`}</span> 
    }
    return null;
  }

  return (
    <div className={classes.root}>
      {redirecting ? 
      <Alert severity="info" style={{alignSelf: "center", marginTop: 50}}>
        <AlertTitle>Neuždarykite šio puslapio!</AlertTitle>
        <span style={{display: "flex", alignItems: "center"}}>
        <CircularProgress style={{marginRight: 10}} size={15} />Vyksta nukreipimas į apmokėjimo sistemą.
        </span>
      </Alert> : 
      <React.Fragment>
        {error ? 
        <Alert severity="error" style={{alignSelf: "center", marginTop: 20}}>
          <AlertTitle>Apmokėjimo klaida!</AlertTitle>
          {error.response.detail}
        </Alert> : null}
        {order.id ? 
          <React.Fragment>
            <hr className={classes.line}/>
            <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
              Nr.: {order.id.substr(0, 6)}
            </Typography>
          </React.Fragment> : null}
        {order.order_date ? 
          <React.Fragment>
            <hr className={classes.line}/>
            <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
              Užsakymo data
            </Typography>
            <Typography variant="body1">
              <b>{order.order_date.toLocaleString('lt-LT')}</b>
            </Typography>
          </React.Fragment> : null}
        
        <hr className={classes.line}/>
        <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
          Pageidaujamas laikas
        </Typography>
        <Typography variant="body1">
          <b>{deliveryDateView()}</b>
        </Typography>
        {order.firstname && order.lastname && order.email && order.tel ? 
          <React.Fragment>
            <hr className={classes.line}/>
            <Typography variant="subtitle1" className={classes.subtitle} gutterBottom>
              Užsakovas
            </Typography>
            <Typography variant="body1">
              {order.firstname?.toUpperCase()} {order.lastname?.toUpperCase()}
            </Typography>
            <Typography variant="body2">
              <b>El. paštas:</b> {order.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <b>Tel. nr.:</b> +{order.tel}
            </Typography>
          </React.Fragment> : null}
        {Boolean(order.company) ? 
          <React.Fragment>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Juridinio asmens informacija
            </Typography>
            {companyInfo()}
          </React.Fragment>
        : null}
        <React.Fragment>
          <hr className={classes.line}/>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Pristatymas
          </Typography>
          {deliveryInfo()}
        </React.Fragment>
        {order.comment ? 
          <React.Fragment>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Komentaras
            </Typography>
            <Typography variant="body1" gutterBottom>
              {order.comment}
            </Typography>
          </React.Fragment> : null}
        <hr className={classes.line}/>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Apmokėjimas
        </Typography>
        {payStatusView()}
        {paymentInfo()}
        {order.invoice ? 
          <Typography variant="subtitle1" className={classes.subtitle}>
            <Link href={order.invoice} variant="button" target="_blank" rel="noreferer">
              Sąskaita faktūra
            </Link>
          </Typography> : null}
        <hr className={classes.line}/>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Užsakymas
        </Typography>
        <List disablePadding>
          {order.orders.map((o, key) => {
            let item = productMap.get(o.item)
            return <ListItem key={key} className={classes.item} disableGutters>
              <div style={{display: "flex", flexDirection: "column"}}>
                {item ? 
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <LazyImage
                      alt={item.name} 
                      maxWidth={120} 
                      src={item.picture}
                      style={{
                        marginTop: 15
                      }}
                    />
                    <div>
                      {o.choice ? <Chip size="small" color="primary" label={choiceString(o.choice)}/> : null}
                    </div>
                  </div>: null}
                <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                  <span>{item ? item.name : <span style={{color: "grey", fontStyle: "italic"}}>Šio produkto nebėra meniu</span>} × {o.quantity}</span>
                </div>} style={{
                  marginRight: 15
                }}/>
              </div>
              
              <Typography variant="body1" color="primary" component="div" style={{padding: 5}}>
                {(o.quantity * o.price / 100).toFixed(2)}&euro;
              </Typography>
            </ListItem>
          })}
          {deliveryData.fee ?
            <ListItem style={{
              display: "flex",
              alignItems: "center",
              flexFlow: 'row wrap',
            }} disableGutters>
              <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <span>Pristatymas</span>
              </div>} style={{
                marginRight: 15
              }}/>
              <Typography variant="body1" color="primary" component="div" style={{padding: 5}}>
                {(deliveryData.fee / 100).toFixed(2)}&euro; 
              </Typography>
            </ListItem> : null}
          {order.discount ?
            <ListItem style={{
              display: "flex",
              alignItems: "center",
              flexFlow: 'row wrap',
            }} disableGutters>
              <ListItemText primary={<div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <span>Nuolaida ({order.discount}%)</span>
              </div>} style={{
                marginRight: 15
              }}/>
              <Typography variant="body1" color="primary" component="div" style={{padding: 5}}>
              {totalPrice().discount}&euro;
              </Typography>
            </ListItem> : null}
          <hr />
          <ListItem style={{
            display: "flex",
            alignItems: "center",
            flexFlow: 'row wrap',
          }} disableGutters>
            <ListItemText style={{
              marginRight: 15
            }} primary={`Suma`}/>
            <Typography variant="body1" color="primary" component="span" style={{fontWeight: 800}}>
              {totalPrice().price}&euro; 
            </Typography>
          </ListItem>
        </List>
        <hr style={{height: 10, backgroundColor: "grey"}}/>
      </React.Fragment>}
    </div>
  )
}

interface OrderDialogProps extends DialogProps {
  order: OrderData
  close: voidFn
}

export const OrderDialog = ({order, close, ...props}: OrderDialogProps)  => {

  return (
    <Dialog
      fullScreen
      fullWidth
      onClose={close}
      {...props}
    >
      <DialogContent>
        {order ? <OrderView order={order}/> : null}
        <div style={{
          maxWidth: 700,
          paddingTop: 10,
          margin: '0 auto',
          display: "flex",
          justifyContent: "flex-end",
        }}>
          <Button
            variant="contained"
            color="default"
            onClick={close}
          >
            Uždaryti
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const OrderDetails = () => {
  let { id } = useParams<{ id: string }>()
  const { thunkGetOrder } = useManagementActions()
  const order = useSelector<AppState, OrderData | undefined>(state => state.management.order_map.get(id), shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.orderError, shallowEqual)

  useEffect(() => {
    if (typeof order === "undefined") {
      thunkGetOrder(id)
    }
  // eslint-disable-next-line
  }, [order])

  return (
    <div style={{
      maxWidth: 700,
      margin: "0 auto"
    }}>
      {Boolean(error) ? 
      <Alert severity="error" style={{maxWidth: 600, alignSelf: "center", marginTop: 50}}>
        <AlertTitle>Klaida!</AlertTitle>
        Užsakymas neegzistuoja arba jo gauti nepavyko.
      </Alert> : Boolean(order) ?
      <OrderView order={order} /> : <CenteredCircularProgress />}
    </div>
  )
}


export default OrderDetails
