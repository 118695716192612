import { SET_ERROR } from './common'
import { ErrorState, ErrorAction } from './types'


const initialErrorState: ErrorState = {
}


export function errorReducer(
  state = initialErrorState,
  action: ErrorAction
): ErrorState {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...state,
        [action.key]: action.payload 
      }
    }
    default:
      return state
  }
}
