import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import { Theme } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import { makeUseStyles } from './common';
import { AppState } from "../store";
import { SocialAppConfig, voidFn } from "../store/types";
import { SocialLoginComponent } from "./social";
import { EditDialog, SignInDialogContent, SignUpDialogContent, ResetPasswordDialogContent, AlertDialogContent } from './dialogs';


const useStyles = makeUseStyles((theme: Theme) => (
  {
    container: {
      margin: theme.spacing(1),
      maxWidth: 400,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    control: {
      marginTop: 10,
    },
    link: {
      cursor: "pointer"
    },
    center: {
      margin: '0 auto',
      display: 'table',
      marginTop: '10pt',
      marginBottom: '10pt'
    }
  })
)

interface Props {
  open: boolean
  handleLoginClose: voidFn
}

 
const SignIn = ({ open, handleLoginClose }: Props) => {
  const socapps = useSelector<AppState, SocialAppConfig[]>(state => state.user.socapps, shallowEqual)
  const classes = useStyles()
  const [emailSentMsg, setEmailSentMsg] = React.useState<string | undefined>()
  const [signup, setSignUp] = React.useState(false)
  const [reset, setReset] = React.useState(false)

  const renderSocLoginButtons = () => socapps.map(({provider, client_id}, index) => 
    // @ts-ignore
    <SocialLoginComponent key={index} provider={provider} client_id={client_id}/>
  )

  const onContentClose = (message?: string) => { 
    if (message) setEmailSentMsg(message)
    else close()
  }

  const close = () => {
    handleLoginClose()
    setEmailSentMsg(undefined)
    setReset(false)
    setSignUp(false)
  }

  return (
    <EditDialog
      title={reset ? 'Pakeisti slaptažodį' : signup ?  'Registracija' : 'Prisijungimas'} 
      open={open}
      containerClass={classes.container}
      handleClose={close}
    >
      {emailSentMsg ? 
        <AlertDialogContent handleClose={close}>
          <span>{emailSentMsg}</span>
        </AlertDialogContent> :
        reset ? 
        <ResetPasswordDialogContent handleClose={onContentClose} /> : 
        <div>
          {signup ? 
          <SignUpDialogContent handleClose={onContentClose}/> : 
          <SignInDialogContent handleClose={onContentClose}/>}
          <DialogContent>
          {renderSocLoginButtons()}
          <Grid container alignItems="flex-start" spacing={1} className={classes.control}>
            <Grid item sm>
            <Link variant="body2" className={classes.link} onClick={() => {
              // cleanUp()
              setReset(true)
            }}>
              Pamiršote slaptažodį?
            </Link>
            </Grid>
            <Grid item sm>
            <Link variant="body2" className={classes.link} onClick={() => {
              setSignUp(!signup)
            }}>
              {signup ? "Jau turite paskyrą? Prisijunkite" : "Neturite paskyros? Užsiregistruokite"}
            </Link>
            </Grid>
          </Grid>
          </DialogContent>
        </div>
      }
    </EditDialog>
  )
}

export default SignIn