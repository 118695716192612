import {
  IUser,
  fn,
  voidFn,
  ISocialApp,
  OrderData,
  ITimedConfig,
  IDiscountCode,
} from './types';
import { URLS, useBaseActions } from './common';

export const SET_USERS = "SET_USERS"
export const ADD_USER = "ADD_USER"
export const RM_USER = "RM_USER"
export const SET_SOC_APPS = "SET_SOC_APPS"
export const ADD_SOC_APP = "ADD_SOC_APP"
export const RM_SOC_APP = "RM_SOC_APP"
export const SET_TIMED_CONF = "SET_TIMED_CONF"
export const ADD_TIMED_CONF = "ADD_TIMED_CONF"
export const RM_TIMED_CONF = "RM_TIMED_CONF"
export const SET_DISCOUNT_CODE = "SET_DISCOUNT_CODE"
export const ADD_DISCOUNT_CODE = "ADD_DISCOUNT_CODE"
export const RM_DISCOUNT_CODE = "RM_DISCOUNT_CODE"
export const SET_ORDERS = "SET_ORDERS"
export const SET_ORDER = "SET_ORDER"
export const CLEAN_ORDERS = "CLEAN_ORDERS"
export const UPDATE_ORDER = "UPDATE_ORDER"
export const RM_ORDER = "RM_ORDER"


/*
 * Users
 */

interface SetUsersAction {
  type: typeof SET_USERS
  payload: IUser[]
}

interface AddUserAction {
  type: typeof ADD_USER
  id: number | string | undefined
  payload: IUser
}

interface RemoveUserAction {
  type: typeof RM_USER
  id: number | string
}

function setUsers(
  users: IUser[]
): ManagementAction {
  return {
    type: SET_USERS,
    payload: users
  }
}

function addUser(
  id: number | string | undefined,
  user: IUser
): ManagementAction {
  return {
    type: ADD_USER,
    id: id,
    payload: user
  }
}

function rmUser(
  id: number | string
): ManagementAction {
  return {
    type: RM_USER,
    id: id
  }
}

/*
 * Social applications
 */

interface SetSocAppsAction {
  type: typeof SET_SOC_APPS
  payload: ISocialApp[]
}

interface AddSocAppAction {
  type: typeof ADD_SOC_APP
  id: number | string | undefined
  payload: ISocialApp
}

interface RemoveSocAppAction {
  type: typeof RM_SOC_APP
  id: number | string
}

function setSocApps(
  socapps: ISocialApp[]
): ManagementAction {
  return {
    type: SET_SOC_APPS,
    payload: socapps
  }
}

function addSocApp(
  id: number | string | undefined,
  socapp: ISocialApp
): ManagementAction {
  return {
    type: ADD_SOC_APP,
    id: id,
    payload: socapp
  }
}

function rmSocApp(
  id: number | string
): ManagementAction {
  return {
    type: RM_SOC_APP,
    id: id
  }
}

/*
 * Timed configuration
 */

interface SetTimedConfAction {
  type: typeof SET_TIMED_CONF
  payload: ITimedConfig[]
}

interface AddTimedConfAction {
  type: typeof ADD_TIMED_CONF
  id: number | string | undefined
  payload: ITimedConfig
}

interface RemoveTimedConfAction {
  type: typeof RM_TIMED_CONF
  id: number | string
}

function setTimedConf(
  timedconf: ITimedConfig[]
): ManagementAction {
  return {
    type: SET_TIMED_CONF,
    payload: timedconf
  }
}

function addTimedConf(
  id: number | string | undefined,
  timedconf: ITimedConfig
): ManagementAction {
  return {
    type: ADD_TIMED_CONF,
    id: id,
    payload: timedconf
  }
}

function rmTimedConf(
  id: number | string
): ManagementAction {
  return {
    type: RM_TIMED_CONF,
    id: id
  }
}

/*
 * Discount code
 */

interface SetDiscountCodeAction {
  type: typeof SET_DISCOUNT_CODE
  payload: IDiscountCode[]
}

interface AddDiscountCodeAction {
  type: typeof ADD_DISCOUNT_CODE
  id: number | string | undefined
  payload: IDiscountCode
}

interface RemoveDiscountCodeAction {
  type: typeof RM_DISCOUNT_CODE
  id: number | string
}

function setDiscountCode(
  discount_codes: IDiscountCode[]
): ManagementAction {
  return {
    type: SET_DISCOUNT_CODE,
    payload: discount_codes
  }
}

function addDiscountCode(
  id: number | string | undefined,
  discount_code: IDiscountCode
): ManagementAction {
  return {
    type: ADD_DISCOUNT_CODE,
    id: id,
    payload: discount_code
  }
}

function rmDiscountCode(
  id: number | string
): ManagementAction {
  return {
    type: RM_DISCOUNT_CODE,
    id: id
  }
}

/*
 * Orders
 */

interface SetOrderAction {
  type: typeof SET_ORDER
  payload: OrderData
}

interface SetOrdersAction {
  type: typeof SET_ORDERS
  status: number
  payload: {
    next: number | undefined
    count: number
    orders: OrderData[]
  }
}

interface CleanOrdersAction {
  type: typeof CLEAN_ORDERS
  status: number
}

interface UpdateOrderAction {
  type: typeof UPDATE_ORDER
  status: number
  id: number | string | undefined
  payload: OrderData
}

interface RemoveOrderAction {
  type: typeof RM_ORDER
  status: number
  id: number | string
}

function setOrder(
  order: OrderData
): ManagementAction {
  return {
    type: SET_ORDER,
    payload: order
  }
}

function setOrders(
  status: number,
  next: number | undefined,
  count: number,
  orders: OrderData[]
): ManagementAction {
  return {
    type: SET_ORDERS,
    status,
    payload: {
      next,
      count,
      orders
    }
  }
}

function updateOrder(
  status: number,
  id: number | string | undefined,
  order: OrderData
): ManagementAction {
  return {
    type: UPDATE_ORDER,
    status,
    id,
    payload: order
  }
}

function rmOrder(
  status: number,
  id: number | string
): ManagementAction {
  return {
    type: RM_ORDER,
    status,
    id
  }
}

export const useManagementActions = () => {
  const { dispatch, baseGet, baseJSONUpdate, baseDelete } = useBaseActions()

  const thunkGetUsers = async (cb?: fn<IUser[]>, errorCb?: voidFn) => {
    baseGet(URLS.USERS, "usersError", setUsers, cb, errorCb)
  }

  const thunkUpdateUser = async (data: any, id?: number, cb?: fn<IUser>, errorCb?: voidFn) => {
    baseJSONUpdate(URLS.USERS, "usersError", addUser, data, id, cb, errorCb)
  }

  const thunkRemoveUser = async (index: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.USERS, "usersError", rmUser, index, cb, errorCb)
  }

  const thunkGetSocialApps = async (cb?: fn<ISocialApp[]>, errorCb?: voidFn) => {
    baseGet(URLS.SOCIAL_APPS, "socappError", setSocApps, cb, errorCb)
  }

  const thunkUpdateSocialApp = async (data: any, id?: number, cb?: fn<ISocialApp>, errorCb?: voidFn) => {
    baseJSONUpdate(URLS.SOCIAL_APPS, "socappError", addSocApp, data, id, cb, errorCb)
  }

  const thunkRemoveSocialApp = async (index: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.SOCIAL_APPS, "socappError", rmSocApp, index, cb, errorCb)
  }

  const thunkGetTimedConfig = async (cb?: fn<ITimedConfig[]>, errorCb?: voidFn) => {
    baseGet(URLS.TIMEDCONFIG, "configError", setTimedConf, cb, errorCb)
  }

  const thunkUpdateTimedConfig = async (data: any, id?: number, cb?: fn<ITimedConfig>, errorCb?: voidFn) => {
    baseJSONUpdate(URLS.TIMEDCONFIG, "configError", addTimedConf, data, id, cb, errorCb)
  }

  const thunkRemoveTimedConfig = async (index: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.TIMEDCONFIG, "configError", rmTimedConf, index, cb, errorCb)
  }

  const thunkGetDiscountCodes = async (cb?: fn<IDiscountCode[]>, errorCb?: voidFn) => {
    baseGet(URLS.DISCOUNT_CODES, "discountCodeError", setDiscountCode, cb, errorCb)
  }

  const thunkCreateDiscountCodes = async (data: any, cb?: fn<IDiscountCode>, errorCb?: voidFn) => {
    baseJSONUpdate(URLS.DISCOUNT_CODES, "discountCodeError", addDiscountCode, data, undefined, cb, errorCb)
  }

  const thunkRemoveDiscountCodes = async (index: number, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.DISCOUNT_CODES, "discountCodeError", rmDiscountCode, index, cb, errorCb)
  }

  const thunkGetOrders = async (page: number, status?: number, descending?: boolean, cb?: fn<{next: string | null, count: number, results: OrderData[]}>, errorCb?: voidFn) => {
    baseGet(`${URLS.ORDERS}?page=${page}${status ? `&status=${status}`: ''}${descending ? `&descending=`: ''}`, "orderError", 
      (data) => setOrders(
        status || 0, 
        data.next ? page + 1 : undefined,
        data.count,
        data.results
      ), cb, errorCb)
  }

  const thunkGetOrder = async (orderId: string, cb?: fn<OrderData>, errorCb?: voidFn) => {
    baseGet(`${URLS.ORDERS}${orderId}/`, "orderError", 
      (data) => setOrder(data), cb, errorCb)
  }

  const thunkUpdateOrder = async (status: number, orderId: string, data: any, cb?: fn<OrderData>, errorCb?: voidFn) => {
    baseJSONUpdate(URLS.ORDERS, "orderError", (idx, data) => updateOrder(status, idx, data), data, orderId, cb, errorCb)
  }

  const thunkRemoveOrder = async (status: number, orderId: string, cb?: voidFn, errorCb?: voidFn) => {
    baseDelete(URLS.ORDERS, "orderError", (id) => rmOrder(status, id), orderId, cb, errorCb)
  }

  return {
    dispatch: dispatch,
    thunkGetUsers: thunkGetUsers,
    thunkUpdateUser: thunkUpdateUser,
    thunkRemoveUser: thunkRemoveUser,
    thunkGetSocialApps: thunkGetSocialApps,
    thunkUpdateSocialApp: thunkUpdateSocialApp,
    thunkRemoveSocialApp: thunkRemoveSocialApp,
    thunkGetTimedConfig: thunkGetTimedConfig,
    thunkUpdateTimedConfig: thunkUpdateTimedConfig,
    thunkRemoveTimedConfig: thunkRemoveTimedConfig,
    thunkGetDiscountCodes: thunkGetDiscountCodes,
    thunkCreateDiscountCodes: thunkCreateDiscountCodes,
    thunkRemoveDiscountCodes: thunkRemoveDiscountCodes,
    thunkGetOrders: thunkGetOrders,
    thunkUpdateOrder: thunkUpdateOrder,
    thunkRemoveOrder: thunkRemoveOrder,
    thunkGetOrder: thunkGetOrder
  }
}

export type ManagementAction = SetUsersAction | AddUserAction | RemoveUserAction |
                               SetSocAppsAction | AddSocAppAction | RemoveSocAppAction |
                               SetTimedConfAction | AddTimedConfAction | RemoveTimedConfAction |
                               SetDiscountCodeAction | AddDiscountCodeAction | RemoveDiscountCodeAction |
                               SetOrderAction | SetOrdersAction | UpdateOrderAction | 
                               RemoveOrderAction | CleanOrdersAction

 