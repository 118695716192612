import { 
  InventoryAction, 
  SET_PRODUCTS,
  SET_CATEGORIES,
  ADD_CATEGORY,
  RM_CATEGORY,
  RM_PRODUCT,
  ADD_PRODUCT,
} from './inventory-actions'
import { Category, InventoryState, Product } from './types'


const initialInventoryState: InventoryState = {
}


export function inventoryReducer(
  state = initialInventoryState,
  action: InventoryAction
): InventoryState {
  switch (action.type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      }
    }
    case ADD_CATEGORY: {
      let pushed = false
      let newCat: Category[] = []
      state.categories?.forEach((cat) => {
        if (cat.id === action.payload.id) {
          newCat.push({...cat, ...action.payload})
          pushed = true
        } else {
          newCat.push({...cat})
        }
      })
      if (!pushed) newCat.push(action.payload)
      return {
        ...state,
        categories: [...newCat.sort((e1, e2) => e2.order - e1.order)]
      }
    }
    case RM_CATEGORY: {
      return {
        ...state,
        categories: state.categories?.filter(e => e.id !== action.index)
      }
    }
    case ADD_PRODUCT: {
      return {
        ...state,
        categories: state.categories?.map(cat => {
          if (cat.id === action.payload.catId) {
            let changed = false
            let newProd: Product[] = []
            cat.products?.forEach(product => {
              if (product.id === action.payload.id) { 
                newProd.push({...product, ...action.payload.product})
                changed = true
              } else
                newProd.push({...product})
            })
            if (!changed) {
              newProd.push(action.payload.product)
            }
            cat = {...cat, products: newProd.sort((e1, e2) => e2.order - e1.order)}
          } else {
            cat = {...cat, products: cat.products?.filter(e => e.id !== action.payload.id)}
          }
          return cat
        })
      }
    }
    case SET_PRODUCTS: {
      return {
        ...state,
        categories: state.categories?.map(cat => {
          if (cat.id === action.payload.catId) {
            cat.products = action.payload.products
          }
          return {...cat}
        })
      }
    }
    case RM_PRODUCT: {
      return {
        ...state,
        categories: state.categories?.map(cat => {
          if (cat.id === action.payload.catId) {
            cat.products = cat.products?.filter(e => e.id !== action.payload.id)
          }
          return {...cat}
        })
      }
    }
    default:
      return state
  }
}
