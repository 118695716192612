import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { Theme, useTheme } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import { voidFn, IUser, LoginData } from '../store/types';
import { AppState } from '../store';
import IconButton from '@material-ui/core/IconButton';
import { useMediaQuery } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeUseStyles } from './common';
import { ResponseError, grecaptchaRequest, addError } from '../store/common';
import { SET_REMEMBER_ME, useLoginActions, addProfile, setSigningIn, addCartData } from '../store/user-actions';
import { useManagementActions } from '../store/management-actions';


const useTitleStyles = makeUseStyles((theme: Theme) => (
  {
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    title: {
      marginLeft: 20,
      marginRight: 50,
      maxWidth: 350
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      marginRight: 5,
    },
  })
)

interface DialogTitleProps  {
  id: string
  children: React.ReactNode
  onClose: voidFn
}
  
const CustomDialogTitle = (props: DialogTitleProps) => {
  const classes = useTitleStyles()
  const { children, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.title}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

const useStyles = makeUseStyles((theme: Theme) => (
  {
    dialog: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  })
)

interface IDialogProps extends DialogProps {
    title?: string
    open: boolean
    error?: ResponseError
    containerClass?: string
    handleClose: voidFn
  }
  
export const EditDialog = ({title, open, containerClass, error, handleClose, ...props}: IDialogProps)  => {
  const classes = useStyles()
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      fullScreen={fullscreen}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }} 
      className={classes.dialog}
      aria-labelledby="dialog-title"
      {...props}
    >
      {title ? 
        <CustomDialogTitle id="dialog-title" onClose={handleClose}>
          {title}
        </CustomDialogTitle> 
      : null}
      <div className={containerClass}>
        <Collapse in={Boolean(Boolean(error?.response?.detail))}>
          <Alert severity="error">{error?.response?.detail}</Alert>
        </Collapse>
        {props.children}
      </div>
    </Dialog>
  )
}


interface ConfirmDialogProps extends DialogProps {
  open: boolean
  title?: string
  confirmAgainst: string | undefined
  handleClose: voidFn
  onConfirm: (cb: voidFn) => void
  error?: ResponseError
}

export const ConfirmDialog = ({open, title, confirmAgainst, error, handleClose, onConfirm, ...props}: ConfirmDialogProps)  => {
  const [activeAction, setActiveAction] = React.useState(false)
  const [confirmText, setConfirmText] = React.useState<string>()

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmText(event.currentTarget.value)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!activeAction) {
      setActiveAction(true)
      onConfirm(() => {
        setActiveAction(false)
        setConfirmText(undefined)
      })
    }
  }

  const onClose = () => {
    setActiveAction(false)
    setConfirmText(undefined)
    handleClose()
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" {...props}>
      <MuiDialogTitle id="confirm-dialog-title">{title}</MuiDialogTitle>
      <DialogContent>
        <Collapse in={Boolean(error?.response?.detail)}>
          <Alert severity="error">{error?.response?.detail}</Alert>
        </Collapse>
        <DialogContentText>
          {props.children}
        </DialogContentText>
        <form id="confirmation-form" onSubmit={onSubmit} noValidate>
          <TextField
            autoFocus
            margin="dense"
            id="confirmation-text"
            fullWidth
            onChange={onTextChange}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form="confirmation-form" color="primary" disabled={activeAction || confirmAgainst !== confirmText}>
          Patvirtinti
        </Button>
        <Button onClick={onClose} color="secondary">
          Atšaukti
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface SimpleConfirmDialogProps extends DialogProps {
  open: boolean
  title?: string
  handleClose: voidFn
  onConfirm: voidFn
}

export const SimpleConfirmDialog = ({open, title, handleClose, onConfirm, ...props}: SimpleConfirmDialogProps)  => {
  
  const onConfirmClick = () => {
    handleClose()
    onConfirm()
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="simple-confirm-dialog-title" {...props}>
      <MuiDialogTitle id="simple-confirm-dialog-title">{title}</MuiDialogTitle>
      <DialogContent>
        <DialogContentText>
          {props.children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Atšaukti
        </Button>
        <Button color="primary" onClick={onConfirmClick}>
          Patvirtinti
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export interface ILoginState {
  email?: string
  password?: string
  username?: string
  first_name?: string
  last_name?: string
  confirm_password?: string
}


interface DialogContentProps {
  handleClose: (message?: string) => void
}


const useLoginStyles = makeUseStyles((theme: Theme) => (
  {
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    control: {
      marginTop: 10,
    },
    link: {
      cursor: 'pointer'
    }
  })
)


export const SignInDialogContent = ({ handleClose }: DialogContentProps)  => {
  const classes = useLoginStyles()
  const [activationEmail, setActivationEmail] = React.useState<string | undefined>()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const remember_me = useSelector<AppState, boolean>(state => state.user.remember_me, shallowEqual)
  const signingIn = useSelector<AppState, boolean>(state => state.user.signingIn, shallowEqual)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.authError, shallowEqual)
  const { dispatch, thunkLogin, thunkSendValidationEmail } = useLoginActions()

  const clearError = () => {
    dispatch(addError("authError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPassword(event.target.value)
  }

  const handleRemeberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: SET_REMEMBER_ME, payload: event.target.checked })
  }

  const onLoginError = () => {
    setPassword("")
    dispatch(setSigningIn(false))
  }

  const onLoginSuccess = (data: LoginData) => {
    clearError()
    dispatch(setSigningIn(false))
    if (data.user.is_activated) {
      window.token_data = data.token
      dispatch(addProfile(data.user))
      dispatch(addCartData({
        firstname: data.user.first_name,
        lastname: data.user.last_name,
        email: data.user.email
      }, false))
      handleClose()
    } else {
      setActivationEmail(data.user.email)
      setPassword("")
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(setSigningIn(true))
    grecaptchaRequest('signin', (ctoken) => {
      thunkLogin({
        email: email, 
        password: password,
        remember_me: remember_me,
        ctoken: ctoken
      }, onLoginSuccess, onLoginError)
    }, (err) => {
      dispatch(addError("authError", new ResponseError({detail: 'Prisijungti nepavyko'}, 400)))
      console.log(err)
      onLoginError()
    })
  }
  
  const resendActivation = () => {
    dispatch(setSigningIn(true))
    if (activationEmail) {
      setActivationEmail(undefined)
      grecaptchaRequest('send', (sendToken) => {
        thunkSendValidationEmail(activationEmail, sendToken, "authError", () => {
          dispatch(setSigningIn(false))
          handleClose("Aktyvavimo nuoroda išsiųsta sėkmingai.")
        }, onLoginError)
      }, (err) => {
        dispatch(addError("authError", new ResponseError({detail: 'Aktyvavimo nuorodos išsiųsti nepavyko'}, 400)))
        console.log(err)
        onLoginError()
      })
    }
  }

  return (
    <DialogContent>
      {Boolean(activationEmail) ?
        <Alert severity="warning">
          Vartotojas yra neaktyvus.&nbsp;
          <Link className={classes.link} onClick={resendActivation}>Siųsti aktyvavimo nuorodą</Link>
        </Alert> : null}
      {Boolean(error?.response?.detail) ?
        <Alert severity="error">
          {error.response.detail}
        </Alert> : null}
      <form id='sign-in-form' onSubmit={onSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={email}
          onChange={handleEmailChange}
          label="El. paštas"
          name="email"
          autoComplete="email"
          autoFocus
          error={Boolean(error?.response?.email?.join(' '))}
          helperText={error?.response?.email?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          name="password"
          label="Slaptažodis"
          type="password"
          autoComplete="current-password"
          error={Boolean(error?.response?.password?.join(' '))}
          helperText={error?.response?.password?.join(' ')}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" onChange={handleRemeberMe}/>}
          label="Prisiminti mane"
        />
        <Button
          fullWidth
          type="submit"
          form="sign-in-form"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={signingIn || !email || !password}
        >
          {signingIn ? <CircularProgress size={25} /> : 'Prisijungti'}
        </Button>
      </form>
    </DialogContent>
  )
}


export const SignUpDialogContent = ({ handleClose }: DialogContentProps)  => {

  const classes = useLoginStyles()
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.authError, shallowEqual)
  const signingIn = useSelector<AppState, boolean>(state => state.user.signingIn, shallowEqual)
  const [username, setUsername] = React.useState("")
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const { dispatch, thunkCreateProfile, thunkSendValidationEmail } = useLoginActions()

  const clearError = () => {
    dispatch(addError("authError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const onLoginError = () => {
    setPassword("")
    setConfirmPassword("")
    dispatch(setSigningIn(false))
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(setSigningIn(true))
    grecaptchaRequest('signup', (signupToken) => {
      thunkCreateProfile({
        username: username,
        first_name: firstName,
        last_name: lastName,
        email: email, 
        password: password,
        confirm_password: confirmPassword,
        ctoken: signupToken
      }, (data) => {
        grecaptchaRequest('send', (sendToken) => {
          thunkSendValidationEmail(data.email, sendToken, "authError", () => {
            dispatch(setSigningIn(false))
            handleClose("Aktyvavimo nuoroda sėkmingai išsiųsta.")
          }, onLoginError)
        }, (err) => {
          dispatch(addError("authError", new ResponseError({detail: 'Aktyvavimo nuorodos išsiųsti nepavyko'}, 400)))
          console.log(err)
          onLoginError()
        })
      }, onLoginError)
    }, (err) => {
      dispatch(addError("authError", new ResponseError({detail: 'Nepavyko prisijungti'}, 400)))
      console.log(err)
      onLoginError()
    })
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setUsername(event.target.value)
  }

  const handleFNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setFirstName(event.target.value)
  }

  const handleLNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setLastName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setConfirmPassword(event.target.value)
  }

  const passNotOK = Boolean(password) && password !== confirmPassword

  return (
    <DialogContent>
      <Collapse in={Boolean(error?.response?.detail)}>
        <Alert severity="error">{error?.response?.detail}</Alert>
      </Collapse>
      <form id='sign-up-form' onSubmit={onSubmit} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={username}
          onChange={handleUsernameChange}
          id="username"
          label="Vartotojo vardas"
          name="username"
          autoComplete="username"
          autoFocus
          error={Boolean(error?.response?.username)}
          helperText={error?.response?.username?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          value={firstName}
          onChange={handleFNameChange}
          id="firstname"
          label="Vardas"
          name="first_name"
          error={Boolean(error?.response?.first_name)}
          helperText={error?.response?.first_name?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          value={lastName}
          onChange={handleLNameChange}
          id="lastname"
          label="Pavardė"
          name="last_name"
          error={Boolean(error?.response?.last_name)}
          helperText={error?.response?.last_name?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={email}
          onChange={handleEmailChange}
          label="El. paštas"
          name="email"
          autoComplete="email"
          error={Boolean(error?.response?.email)}
          helperText={error?.response?.email?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          name="password"
          label="Slaptažodis"
          type="password"
          autoComplete="current-password"
          error={Boolean(error?.response?.password)}
          helperText={error?.response?.password?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          name="confirm_password"
          label="Pakartoti slaptažodį"
          type="password"
          autoComplete="current-password"
          error={passNotOK}
          helperText={passNotOK ? 'Privalo sutapti su slaptažodžiu viršuje' : undefined}
        />
        <Button
          fullWidth
          type="submit"
          form="sign-up-form"
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={signingIn || !username || !email || !password || password !== confirmPassword}
        >
          {signingIn ? <CircularProgress size={25} /> : 'Registruoti'}
        </Button>
      </form>
    </DialogContent>
  )
}


export const ResetPasswordDialogContent = ({ handleClose }: DialogContentProps)  => {
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.authError, shallowEqual)
  const [submitting, setSubmitting] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const { dispatch, thunkSendResetEmail } = useLoginActions()

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const clearError = () => {
    dispatch(addError("authError", undefined))
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail(event.target.value)
  }

  const onError = () => {
    setSubmitting(false)
  }

  const onSuccess = () => {
    setSubmitting(false)
    handleClose("Slaptažodžio pakeitimo nuoroda išsiųsta.")
  }

  const onSend = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)
    grecaptchaRequest('send', (ctoken) => {
      thunkSendResetEmail(email, ctoken, onSuccess, onError)
    }, (err) => {
      dispatch(addError("authError", new ResponseError({detail: 'Nepavyko išsiųsti el. pašto'}, 400)))
      console.log(err)
      onError()
    })
  }

  return (
    <div>
      <DialogContent>
        <Collapse in={Boolean(error?.response?.detail)}>
          <Alert severity="error">{error?.response?.detail}</Alert>
        </Collapse>
        <DialogContentText>
          Įveskite el. paštą kuriuo bus išsiųsta slaptažodžio pakeitimo nuoroda.
        </DialogContentText>
        <form id="send-reset" onSubmit={onSend} noValidate>
          <TextField
            autoFocus
            margin="dense"
            required
            fullWidth
            value={email}
            onChange={handleEmailChange}
            label="El. paštas"
            name="email"
            autoComplete="email"
            error={Boolean(error?.response?.email)}
            helperText={error?.response?.email?.join(' ')}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button 
          type="submit" 
          form="send-reset"
          color="primary" 
          disabled={!Boolean(email) || submitting}
        >
          {submitting ? <CircularProgress size={25} /> : 'Siųsti'}
        </Button>
        <Button onClick={() => {handleClose()}} color="secondary" disabled={submitting}>
          Atšaukti
        </Button>
      </DialogActions>
    </div>
  )
}


interface AlertDialogContentProps {
  children: React.ReactNode
  handleClose: voidFn
}

export const AlertDialogContent = ({children, handleClose }: AlertDialogContentProps)  => {
  
  return (
    <div>
      <DialogContent>
        <DialogContentText>
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Uždaryti
        </Button>
      </DialogActions>
    </div>
  )
}


interface IUserControlForm {
  user?: IUser
  onSuccess: voidFn
}


const useFormStyles = makeUseStyles((theme: Theme) => (
  {
    submit: {
      margin: theme.spacing(3, 0, 2),
    }
  })
)


export const UserControlForm = ({ user, onSuccess }: IUserControlForm) => {
  
  const classes = useFormStyles()
  const btn = React.useRef(null)
  const error = useSelector<AppState, ResponseError | undefined>(state => state.error.usersError, shallowEqual)
  const [username, setUsername] = React.useState(user?.username || "")
  const [firstName, setFirstName] = React.useState(user?.first_name || "")
  const [lastName, setLastName] = React.useState(user?.last_name || "")
  const [email, setEmail] = React.useState(user?.email || "")
  const [password, setPassword] = React.useState<string>("")
  const [confirmPassword, setConfirmPassword] = React.useState<string>("")
  const [isStaff, setIsStaff] = React.useState(Boolean(user?.is_staff))
  const [active, setActive] = React.useState(typeof user !== "undefined" ? user.is_active : true)
  const [actionActive, setActionActive] = React.useState(false)
  const { dispatch, thunkUpdateUser } = useManagementActions()

  const clearError = () => {
    dispatch(addError("usersError", undefined))
  }

  useEffect(() => {
    return clearError
  // eslint-disable-next-line
  }, [])

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setUsername(event.target.value)
  }

  const handleFNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setFirstName(event.target.value)
  }

  const handleLNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setLastName(event.target.value)
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setPassword(event.target.value)
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setConfirmPassword(event.target.value)
  }

  const handleIsStaff = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setIsStaff(event.target.checked)
  }

  const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError()
    setActive(event.target.checked)
  }

  const passNotOK = Boolean(password) && password !== confirmPassword

  const submit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!actionActive) {
      let data: any
      if (!user) {
        data = {
          username: username,
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          confirm_password: confirmPassword,
          is_staff: isStaff,
          is_active: active,
        }
      } else {
        data = {
          is_staff: isStaff,
          is_active: active
        }
        if (password)
          data.password = password
        if (confirmPassword)
          data.confirm_password = confirmPassword
      }
      setActionActive(true)
      thunkUpdateUser(data, user?.id, () => {
        onSuccess()
        setActionActive(false)
      }, () => {
        setPassword("")
        setConfirmPassword("")
        setActionActive(false)
      })
    }
  }

  return (
    <form id='user-control-form' onSubmit={submit} noValidate>
      {!user ?
        <div>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={username}
            onChange={handleUsernameChange}
            label="Vartotojo vardas"
            name="username"
            autoComplete="username"
            autoFocus
            error={Boolean(error?.response?.username)}
            helperText={error?.response?.username?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            value={firstName}
            onChange={handleFNameChange}
            label="Vardas"
            name="first_name"
            error={Boolean(error?.response?.first_name)}
            helperText={error?.response?.first_name?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            value={lastName}
            onChange={handleLNameChange}
            label="Pavardė"
            name="last_name"
            error={Boolean(error?.response?.last_name)}
            helperText={error?.response?.last_name?.join(' ')}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            onChange={handleEmailChange}
            label="El. paštas"
            name="email"
            autoComplete="email"
            error={Boolean(error?.response?.email)}
            helperText={error?.response?.email?.join(' ')}
          />
        </div> : null}
        
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          name="password"
          label="Password"
          type="password"
          autoComplete="current-password"
          error={Boolean(error?.response?.password)}
          helperText={error?.response?.password?.join(' ')}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          name="confirm_password"
          label="Slaptažodis"
          type="password"
          autoComplete="current-password"
          error={passNotOK}
          helperText={passNotOK ? 'Must match the password above' : undefined}
        />
      <FormControlLabel
        control={<Checkbox checked={isStaff} color="primary" onChange={handleIsStaff}/>}
        label="Darbuotojas"
      />
      <FormControlLabel
        control={<Checkbox checked={active} color="primary" onChange={handleActive}/>}
        label="Aktyvus"
      />
      <Button
        ref={btn}
        fullWidth
        type="submit"
        form="user-control-form"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={passNotOK || actionActive}
      >
        {actionActive ? <CircularProgress size={25} /> : 'Submit'}
      </Button>
    </form>
  )
}
