import React from 'react';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import { Theme } from '@material-ui/core/styles';
import { makeUseStyles } from './common';

interface StyleContext {
  height: number
  maxWidth: number
}

const useStyle = (context: StyleContext) => makeUseStyles((theme: Theme, context: StyleContext) => (
  {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center"
    },
    imageContainer: {
      position: "relative",
      height: context.height + 2,
      border: '1px solid gray',
      backgroundColor: '#dadae0',
      maxWidth: context.maxWidth
    },
    image: {
      objectFit: "contain",
      height: context.height,
      maxWidth: context.maxWidth,
      top: 1,
      left: 1,
    },
    button: {
      position: "absolute",
      top: "50%",
      right: "50%",   
      transform: "translate(50%,-50%)",
      zIndex: 999
    }
  }
), context)

interface PictureImageProps {
  height: number
  maxWidth: number
  id?: string
  onChange?: (file: File) => void
  image?: string
}

export const PictureInput = ({id, image, height, maxWidth, onChange}: PictureImageProps) => {
  const classes = useStyle({height: height, maxWidth: maxWidth})()
  const [source, setSource] = React.useState<string | undefined>(image)

  const getFileFromInput = (file: File): Promise<any> => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = function () { resolve(reader.result); };
        reader.readAsDataURL(file);
    })
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files) {
        Array.from(event.target.files).forEach(file => {
            getFileFromInput(file)
            .then((binary) => {
                setSource(binary)
                if (onChange) onChange(file)
            }).catch(function (reason) {
                console.log(`Error during upload ${reason}`);
                event.target.value = ''; // to allow upload of same file if error occurs
            });
        });
    }
  }

  return (
    <div id={id} className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={source ? source : `${process.env.PUBLIC_URL}/img/logo.png`} className={classes.image} alt="" style={{opacity: source ? 1 : 0.2}} />
        <Input
          inputProps={{
            accept: "image/*",
          }}
          fullWidth
          id="picture-input-file"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <div>
          <label htmlFor="picture-input-file" className={classes.button}>
            <Tooltip title="Ieškoti paveiksliuko įrenginyje">
              <IconButton component="span">
                <AddCircleRoundedIcon style={{color: "white"}}/>
              </IconButton>
            </Tooltip>
          </label>
        </div>
      </div>
    </div> 
  )
}